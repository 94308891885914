import { ChangeDetectorRef, Component, ViewChild } from "@angular/core";
import { MobileNavbarService } from "../../../services/mobile.navbar.service";
import { ContactsOuvertComponent } from "../../contacts/commun/contacts-ouvert/contacts.ouvert.component";
import { ContactsComponentsService } from "../../../services/contacts/contacts.service";
import { ContactModel } from "../../../models/commun/contacts/contact.model";

@Component({
	selector: 'mobile-navbar',
	templateUrl: './mobile.navbar.template.html',
	styleUrls: ['./mobile.navbar.style.less']
})
export class MobileNavbarComponent  {

	navbarOpen: boolean;
	isContactOuvert: boolean;

	private contactsOuvertComponent: ContactsOuvertComponent;

	@ViewChild(ContactsOuvertComponent) set content(content: ContactsOuvertComponent) {
		this.contactsOuvertComponent = content;
		this.cdRef.detectChanges();
	}

	constructor(private navbarService: MobileNavbarService,
				private cdRef: ChangeDetectorRef,
				private contactsComponentService: ContactsComponentsService) {

	}

	ngOnInit() {
		this.navbarService.getStateSubject().subscribe((value: boolean) => {
			this.navbarOpen = value;
		});
		this.navbarService.getStateSubject().next(false);
		this.contactsComponentService.getContactHeaderSource().subscribe((contactOpened: ContactModel) => {
			setTimeout(() => {
				this.isContactOuvert = !!contactOpened;
			}, 0);
		});
	}

	closeNavbar(e?: Event) {
		if (!e || (<HTMLElement> e.target).classList.contains('mobile-navbar')) {
			this.navbarService.toggleNavbar();
		}
	}

	toggleListeContacts() {
		this.contactsOuvertComponent.toggleOpen();
		this.cdRef.detectChanges();
	}

}
