import { NgModule } from "@angular/core";
import { BandeauRisqueLabComponent } from "./bandeau-risque.component";
import { SPSCommonChildModule } from '../../../../../../app.common.child.module';

@NgModule({
	imports: [
		SPSCommonChildModule
	],
	declarations: [
		BandeauRisqueLabComponent,
	],
	exports: [
		BandeauRisqueLabComponent,
	]
})
/* Widget Module */
export class LabRisqueModule {
}
