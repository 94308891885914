/**
 * Created by mcalvez on 07/06/17.
 */

import { Routes } from '@angular/router';
import { CguComponent } from './cgu.component';
import { AuthGuard } from '../../guards/auth.guard';

export const cguRoutes: Routes = <Routes>[
	{
		path: 'cgu',
		canActivate: [AuthGuard],
		component: CguComponent,
	}
];
