import { IdLibelleModel } from "../referentiels/id.libelle.model";


export class PortefeuilleUtilisateur extends IdLibelleModel {

	entiteJuridique: IdLibelleModel;
	type: string;
	portefeuilleMaster: IdLibelleModel;
	poleTransformation: IdLibelleModel;
	adresseExpediteurMail: String;
}

export class PortefeuilleUtilisateurSandbox extends PortefeuilleUtilisateur {
	idAccesDonnees: number;
}
