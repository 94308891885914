import {Injectable} from "@angular/core";
import {Appel, AppelEntrant} from "../models/telephonie/appel.entrant";
import {
	LocalStorageService,
	LS_AUTHENTIFICATION_TOKEN,
	LS_TELEPHONIE_APPEL_ENTRANT,
	LS_TELEPHONIE_APPEL_SORTANT,
	LS_TELEPHONIE_PARAMETRAGE,
	LS_TELEPHONIE_SERVICE_ACTIF
} from "./LocalStorage.service";
import {Observable, Subject, Subscription} from "rxjs";
import {StompService} from "./stomp.service";
import {StatutAppel} from "../enums/statut.appel";
import {ParametrageTelephonieResource} from "../resources/telephonie/parametrage.telephonie.ressource";
import {ParametrageTelephonie} from "../models/telephonie/parametrage.telephonie";
import {ContactResource} from "../resources/contact/contact.resource";
import {ContactCoordonneeTelephoneResource} from "../resources/coordonnees/contact.coordonnees.telephone.resource";
import {CorrespondantResource} from "../resources/correspondants.resource";
import {ContactTelephonie} from "../models/commun/telephonie/contact.telephonie.model";
import {ContactModel} from "../models/commun/contacts/contact.model";
import {TypeContact} from "../enums/contact.type.model.enum";
import {TelephoneModel} from "../models/commun/contacts/telephone.model";
import {CorrespondantModel} from "../models/entreprise/contacts/correspondant.model";
import {GlobalMessageService} from "./global.message.service";
import {TranslateService} from "@ngx-translate/core";
import {EvenementModel} from "../models/commun/evenement/evenement.model";

/**
 * Created by "Raphaël Marnier <raphael.marnier@scub.net>" on 13/02/17.
 */
@Injectable({
	providedIn: 'root'
})
export class TelephonieService {

	private appelEntrant = new Subject<AppelEntrant>();
	public appelEntrantObs = this.appelEntrant.asObservable();

	private appelSortant = new Subject<Appel>();
	public appelSortantObs = this.appelSortant.asObservable();

	private traitementAppelEntrant: Subject<AppelEntrant> = new Subject<AppelEntrant>();
	public traitementAppelEntrantObs: Observable<AppelEntrant> = this.traitementAppelEntrant.asObservable();

	private telephonieActive: Subject<boolean> = new Subject<boolean>();
	public telephonieActiveObs: Observable<boolean> = this.telephonieActive.asObservable();

	private subscriptions: Subscription[] = [];

	constructor(private storage: LocalStorageService,
				private parametrageTelephonieRessource: ParametrageTelephonieResource,
				private contactResource: ContactResource,
				private stompService: StompService,
				private telephoneResource: ContactCoordonneeTelephoneResource,
				private correspondantRessouce: CorrespondantResource,
				private messageService: GlobalMessageService,
				private translate: TranslateService) {
	}

	/**
	 * Lance une connexion au serveur de téléphonie par WebSocket.
	 */
	public connexionTelephonie() {

		console.debug("Entrée dans le service connexionTelephonie");
		// Si un utilisateur est connecté mains qu'on n'a pas réucpérer son paramétrage
		if (this.storage.exists(LS_AUTHENTIFICATION_TOKEN) && !this.storage.exists(LS_TELEPHONIE_PARAMETRAGE)) {
			console.debug("Récupération des données de paramétrage de la téléphonie puis tentative de connexion");
			// on récupère son paramétrage  et on le stocke en session
			this.parametrageTelephonieRessource.get().subscribe((parametres: ParametrageTelephonie) => {
				if (parametres) {
					this.storage.persist(parametres, LS_TELEPHONIE_PARAMETRAGE);
					this.demanderConnexion();
				}
			});
		} else if (this.storage.exists(LS_AUTHENTIFICATION_TOKEN) && this.storage.exists(LS_TELEPHONIE_PARAMETRAGE)) {

			console.debug("Données de paramétrage connues, tentative de connexion");
			this.demanderConnexion();
		}

	}

	public isActive(): boolean {
		return this.stompService.isConnected();
	}

	public resetConnexionTelephonie() {
		//supprime les informations stockées sur la téléphonie
		this.storage.remove(LS_TELEPHONIE_PARAMETRAGE);
		this.storage.remove(LS_TELEPHONIE_SERVICE_ACTIF);
		//nettoie les endpoints défini et disconnect la socket
		this.subscriptions.forEach(sub => {
			sub.unsubscribe();
		});
		this.subscriptions = [];

		let subscription = this.stompService.disconnect().subscribe((ok: boolean) => {
			this.connexionTelephonie();
			subscription.unsubscribe();
		});
	}

	/**
	 * Initialisation et lancement d'une connexion.
	 */
	private demanderConnexion() {


		// Lors de la connexion, le service de téléphonie n'est pas actif donc on notifie l'application
		this.storage.persist(false, LS_TELEPHONIE_SERVICE_ACTIF);
		this.telephonieActive.next(false);


		// On regarde si l'utilisateur a accès à la téléphonie, si oui, on établit la connexion
		if (this.storage.exists(LS_TELEPHONIE_PARAMETRAGE)) {

			let parametres: ParametrageTelephonie = this.storage.load(LS_TELEPHONIE_PARAMETRAGE);
			console.debug("demanderConnexion : données de paramétrage présentes en session", parametres);

			if (parametres && parametres.active) {
				let souscription = this.stompService.connect(parametres).subscribe(() => {
					this.connexionTelephonieReussie()
				}, () => {
					this.connexionTelephonieEchoue()
				}, () => {
					souscription.unsubscribe();
				});
			}
		}
	}

	/**
	 * Déclenche un appel sortant sur le contact courant. Si celui-ci dispose de plusieurs numéros de téléphones valides,
	 * affiche à la place une popup de sélection du numéro de téléphone.
	 * @param contact le contact à appeler
	 */
	effectuerAppelSortant(contact: ContactModel, evenement: EvenementModel, fil: number) {
		// L'appel ne peut être lancé que si aucun autre appel (entraant ou sortant) est en cours
		if (contact && contact.id) {
			if (contact.type === TypeContact[TypeContact.PERSONNE_PHYSIQUE]) {
				// Cas des contacts particuliers

				// Récupération des numéros de téléphones du contact
				let telephoneCriteria = {
					typeContact: ContactModel.getContactType(contact),
					personneId: contact.id
				};
				this.telephoneResource.query(telephoneCriteria).subscribe(telephones => {
					if (telephones && telephones.length > 0) {
						// On ne considère que les téléphones valides
						let telephonesValides: Array<TelephoneModel> = telephones.filter(telephone => !telephone.invalide);
						if (telephonesValides && telephonesValides.length > 0) {
							this.contactTelephonie = new ContactTelephonie();
							this.contactTelephonie.id = contact.id;
							this.contactTelephonie.type = contact.type;
							this.contactTelephonie.telephones = telephonesValides;

							this.evenementTelephonie = evenement;
							this.filActiviteTelephonie = fil;
							// Affichage de la popup de sélection du numéro de téléphone à appeler
							jQuery("#appel-sortant-modal").modal("show");
						} else {
							// Aucun téléphone valide
							this.erreurAppelSortantAucunNumero();
						}
					} else {
						// Aucun téléphone
						this.erreurAppelSortantAucunNumero();
					}
				});
			} else {
				// Cas des contacts entreprises et courtiers

				// Récupération des correspondants du contact
				let correspondantsCriteria = {
					typeContact: ContactModel.getContactType(contact),
					idContact: contact.id
				};
				this.correspondantRessouce.query(correspondantsCriteria).subscribe(correspondants => {
					if (correspondants && correspondants.length > 0) {
						// On ne considère que les téléphones valides
						let telephonesValides: Array<TelephoneModel> = [];
						let correspondantsValides: Array<CorrespondantModel> = [];
						for (let correspondant of correspondants) {
							let aTelephoneValide: boolean = false;
							if (correspondant.telephone1 && !correspondant.telephone1.invalide) {
								telephonesValides.push(correspondant.telephone1);
								aTelephoneValide = true;
							}
							if (correspondant.telephone2 && !correspondant.telephone2.invalide) {
								telephonesValides.push(correspondant.telephone2);
								aTelephoneValide = true;
							}
							if (aTelephoneValide) {
								correspondantsValides.push(correspondant);
							}
						}
						if (telephonesValides && telephonesValides.length > 0) {
							this.contactTelephonie = new ContactTelephonie();
							this.contactTelephonie.id = contact.id;
							this.contactTelephonie.type = contact.type;
							this.contactTelephonie.correspondants = correspondantsValides;

							this.evenementTelephonie = evenement;
							this.filActiviteTelephonie = fil;
							// Affichage de la popup de sélection du numéro de téléphone à appeler
							jQuery("#appel-sortant-modal").modal("show");
						} else {
							// Aucun téléphone valide
							this.erreurAppelSortantAucunNumero();
						}
					} else {
						// Aucun correspondant
						this.erreurAppelSortantAucunNumero();
					}
				});
			}
		} else {
			// Contact invalide
			this.erreurAppelSortantAucunNumero();
		}
	}

	private connexionTelephonieEchoue() {
		// Notification à l'application que le service de téléphonie est actif.
		this.storage.persist(false, LS_TELEPHONIE_SERVICE_ACTIF);
		this.telephonieActive.next(false);

		//this.stompService.tentativeConnexion(environment.TELEPHONIE_URL, callback, errorCallback);
	}

	public appelSortantAnnule() {
		this.appelSortant.next(null);
	}

	public appelSortantValide(appelSorant) {
		jQuery("#appel-sortant-modal").modal("hide");
		this.storage.persist(appelSorant, LS_TELEPHONIE_APPEL_SORTANT);
		this.appelSortant.next(appelSorant);
	}


	/**
	 * Notifie l'application qu'un appel entrant est en cours.
	 * @param appelEntrant les informations concernant l'appel entrant
	 */
	private notifieDebutAppelEntrant(appelEntrant: AppelEntrant) {
		this.storage.persist(appelEntrant, LS_TELEPHONIE_APPEL_ENTRANT);
		this.appelEntrant.next(appelEntrant);
	}

	/**
	 * Notifie l'application qu'un appel vient de se terminer.
	 */
	private notifieFinAppel(appel: Appel) {

		// Si l'appel n'est pas null, on nettoie la session et on notifie l'application
		if (appel) {
			if (this.storage.exists(LS_TELEPHONIE_APPEL_ENTRANT)) {
				let appelEntrant = this.storage.load(LS_TELEPHONIE_APPEL_ENTRANT);
				if (appelEntrant && appelEntrant.idAppel == appel.idAppel) {
					this.storage.remove(LS_TELEPHONIE_APPEL_ENTRANT);
					this.appelEntrant.next(null);
				}
			}
			if (this.storage.exists(LS_TELEPHONIE_APPEL_SORTANT)) {
				let appelSortant = this.storage.load(LS_TELEPHONIE_APPEL_SORTANT);
				if (appelSortant && appelSortant.idAppel == appel.idAppel) {

					this.storage.remove(LS_TELEPHONIE_APPEL_SORTANT);
					this.appelSortant.next(null);
				}
			}
		}

	}

	/**
	 * Notifie le traitement d'un appel par l'utilisateur.
	 * @param appelEntrant l'appel traité par l'utilisateur
	 */
	public notifieTraitementAppelEntrant(appelEntrant: AppelEntrant) {
		this.traitementAppelEntrant.next(appelEntrant);
	}


	public contactTelephonie: ContactTelephonie = null;
	public evenementTelephonie: EvenementModel = null;
	public filActiviteTelephonie: number = null;

	private connexionTelephonieReussie() {
		// Notification à l'application que le service de téléphonie est actif.
		this.storage.persist(true, LS_TELEPHONIE_SERVICE_ACTIF);
		this.telephonieActive.next(true);

		//On empéche de faire plusieurs fois les souscriptions.
		if (this.subscriptions.length > 0)
			return;

		// Souscription au channel pour les appels entrants
		this.subscriptions.push(this.stompService.watch('/user/topic/appel-entrant').subscribe(stompResponse => {
			let appelEntrant: AppelEntrant = JSON.parse(stompResponse.body);
			// Recherche contact particulier par numéro de téléphone
			let criteres: any = {
				typeContact: "physiques",
				telephone: appelEntrant.numeroTelephone,
				telephoneInvalide: false
			};
			this.subscriptions.push(this.contactResource.query(criteres).subscribe(contacts => {

				// Complète les infos de l'appel entrant
				appelEntrant.contactsTrouves = !!contacts && contacts.length > 0;
				if (!!contacts && contacts.length == 1) {
					appelEntrant.idContact = contacts[0].id;
				}

				// On notifie l'utilisateur qu'un appel entrant est arrivé
				this.notifieDebutAppelEntrant(appelEntrant);
			}, () => {
				// En cas d'erreur, on notifie quand même l'utilisateur qu'un appel entrant est arrivé
				this.notifieDebutAppelEntrant(appelEntrant);
			}));
		}));

		// Souscription au channel pour les états d'appels
		this.subscriptions.push(this.stompService.watch('/user/topic/etat-appel').subscribe(stompResponse => {
			let appel: Appel = JSON.parse(stompResponse.body);
			if (appel.statut == StatutAppel[StatutAppel.RACCROCHE]) {
				this.notifieFinAppel(appel);
			}
		}));

		// Souscription au channel pour les appels sortants
		this.subscriptions.push(this.stompService.watch('/user/topic/appel-sortant').subscribe(stompResponse => {
			let appel: Appel = JSON.parse(stompResponse.body);
			if (this.storage.exists(LS_TELEPHONIE_APPEL_SORTANT)) {
				let appelStocke = this.storage.load(LS_TELEPHONIE_APPEL_SORTANT);
				// On regarde si le numéro de téléphone stocké correspond à celui reçu, pour mettre à jour les
				// informations en session
				if (appel && appel.numeroTelephone == appelStocke.numeroTelephone) {
					this.storage.persist(appel, LS_TELEPHONIE_APPEL_SORTANT);
				}
			}
		}));

		// Souscription au channel pour l'état de la téléphonie
		this.subscriptions.push(this.stompService.watch('/topic/etat-telephonie').subscribe(stompResponse => {
			let etat: boolean = JSON.parse(stompResponse.body);

			this.storage.persist(etat, LS_TELEPHONIE_SERVICE_ACTIF);
			this.telephonieActive.next(etat);
		}));
	}

	/**
	 * Erreur dans le cas ou aucun numéro de téléphone n'est renseigné dans le cas d'un appel sortant.
	 */
	private erreurAppelSortantAucunNumero() {
		this.messageService.showError(this.translate.instant("TELEPHONIE.APPEL_SORTANT.AUCUN_NUMERO_RENSEIGNE"));
	}
}
