import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Resolve } from "@angular/router";
import { Observable } from "rxjs";
import { TranslateService } from "@ngx-translate/core";

/**
 * Ce resolver permet de s'assurer que le dictionnaire est chargé d'init les autres composants
 */
@Injectable({
  providedIn: 'root'
})
export class TranslationResolve implements Resolve<void> {
	constructor(private translate: TranslateService) {
	}

	resolve(route: ActivatedRouteSnapshot): Observable<any> | any {
		//on traduit une clé pour être sur que le dictionnaire est chargé et dispo
		//la fonction get retourne un observable
		return this.translate.get('app');

	}
}
