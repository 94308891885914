import { Injectable } from "@angular/core";
import { UtilisateurService } from "./utilisateur.service";
@Injectable({
	providedIn: 'root'
})
export class FeatureFlippingService {

	public constructor(private userService: UtilisateurService) {
	}

	public hasFeatureRight(featureKey: string): boolean {
		const featFlippKeys: Array<String> = this.userService.getUtilisateurConnecte() ? this.userService.getUtilisateurConnecte().featureFlippingKeys : null;
		if (!featFlippKeys || !featureKey) {
			return false;
		}
		return featFlippKeys.some(feature => feature === featureKey);
	}
}
