/**
 * Created by mcalvez on 06/06/17.
 */
import { Injectable } from "@angular/core";
import { ResourceParams } from "@ngx-resource/core";
import { SPSResource } from "../sps-resource";

@Injectable({
  providedIn: 'root'
})
@ResourceParams({
	// API Path
	path: '/utilisateur/cgu'
})
export class CguResource extends SPSResource<any, any, any, any> {

}
