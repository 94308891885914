<span *ngIf="enTete && enTete!= ''">
	{{ 'AFFECTATION.ENTETE.' + enTete.toUpperCase() | translate }}
</span>
<span *ngIf="dateString && dateString != ''">
	{{ dateString | stringToDate }} {{ 'AFFECTATION.ENTETE.PIPE_PAR' | translate }}
</span>
<span class="ressource-libelle" *ngIf="libelleFinal && libelleFinal != ''"
	  [tooltip]="libelleTooltip" container="body" containerClass="tooltip-affectation">
	{{libelleFinal}}
</span>
