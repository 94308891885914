/**
 * Created by mcalvez on 29/03/17.
 */
import { Injectable } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { ConfirmPopupModel } from "../models/confirm.popup.model";
import { Subject } from "rxjs";

@Injectable({
	providedIn: 'root'
})
export class ConfirmPopupService {

	public confirmPopupModel: ConfirmPopupModel;
	public subject: Subject<boolean> = new Subject<boolean>();

	constructor(private translate: TranslateService) {
	}

	popup(confirmPopupModel: ConfirmPopupModel = null) {
		this.confirmPopupModel = new ConfirmPopupModel();
		this.confirmPopupModel.modalId = "sps-confirm-popup-id-" + Math.floor(Math.random() * 10000);
		this.confirmPopupModel.modalTitle = this.translate.instant('MODAL.DEFAULT.TITLE');
		this.confirmPopupModel.modalContent = this.translate.instant('MODAL.DEFAULT.CONTENT');
		this.confirmPopupModel.modalConfirmBtn = this.translate.instant('MODAL.DEFAULT.CONFIRM_BTN');
		this.confirmPopupModel.modalCancelBtn = this.translate.instant('MODAL.DEFAULT.CANCEL_BTN');

		if (confirmPopupModel) {
			for (let key in confirmPopupModel) {
				if (confirmPopupModel[key]) {
					this.confirmPopupModel[key] = confirmPopupModel[key];
				}
			}
		}
		this.subject.next(true);
	}

	getSubject(): Subject<boolean> {
		return this.subject;
	}

	setSubject(subject: Subject<boolean>) {
		this.subject = subject;
	}

	onConfirm(event: any) {
		if (this.confirmPopupModel.onConfirmCallback) {
			this.confirmPopupModel.onConfirmCallback(event);
		}
	}

	onCancel(event: any) {
		if (this.confirmPopupModel.onCancelCallback) {
			this.confirmPopupModel.onCancelCallback(event);
		}
	}
}
