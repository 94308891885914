/**
 * Created by "Juanito Goncalves <juanito.goncalves@scub.net>" on 16/07/16.
 */
import { Component } from "@angular/core";
import { Router } from "@angular/router";


@Component({
	selector: 'errors',
	templateUrl: './errors.template.html',
	styleUrls: ['./errors.style.less'],
})
export class ErrorsComponent  {

	constructor(private router: Router) {

	}
}
