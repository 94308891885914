/**
 * Ressource Backend pour les entreprises.
 * Created by "Juanito Goncalves <juanito.goncalves@scub.net>" on 27/07/16.
 */
import { ResourceParams } from "@ngx-resource/core";
import { Injectable } from "@angular/core";
import { SPSResource } from "../sps-resource";


@Injectable({
  providedIn: 'root'
})
@ResourceParams({
	// Api path
	path: '/personnes/{typeContact}/{id}',
})
export class ContactResource extends SPSResource<any, any, any, any> {
}
