import { ChangeDetectorRef, Component } from "@angular/core";
import { ContactModel } from "../../../../models/commun/contacts/contact.model";
import {
	ContactsComponentsService,
	MAX_OPENED_CONTACT
} from "../../../../services/contacts/contacts.service";
import { FullScreenModalService } from "../../../../services/modal.fullscreen.service";
import { GlobalMessageService } from "../../../../services/global.message.service";
import { TranslateService } from "@ngx-translate/core";
import { Subscription } from "rxjs";

@Component({
	selector: 'sps-contacts-ouvert',
	templateUrl: './contacts.ouvert.template.html',
	styleUrls: ['./contacts.ouvert.style.less']
})
export class ContactsOuvertComponent  {
	contactsOpened: Array<ContactModel> = [];
	contactOpened: ContactModel = null;
	bandeauContactOuvert: boolean = false;
	subscriptions: Subscription[] = [];

	constructor(public contactsComponentService: ContactsComponentsService,
				private contactService: ContactsComponentsService,
				private cdRef: ChangeDetectorRef,
				private translate: TranslateService,
				private messageService: GlobalMessageService,
				private fullScreenModalService: FullScreenModalService
	) {

	}

	ngOnInit() {
		this.subscriptions.push(this.contactsComponentService.auResetContacts().subscribe((reset: boolean) => {
			if (reset) {
				this.bandeauContactOuvert = false;
				this.cdRef.detectChanges();
			}
		}));

		// this.contactsOpened = this.contactsComponentService.getContactsOpenedSubject().getValue();
		this.subscriptions.push(this.contactsComponentService.getContactsOpenedSubject().subscribe((contactsOpened: Array<ContactModel>) => {
			this.contactsOpened = contactsOpened;
		}));

		// this.contactOpened = this.contactsComponentService.getContactHeaderSource().getValue();
		this.subscriptions.push(this.contactsComponentService.contactOpened$.subscribe((contactOpened: ContactModel) => {
			this.contactOpened = contactOpened;
		}));

		this.contactsOpened = this.contactsComponentService.getContacts();

	}

	ouvrirContact(contact: ContactModel) {
		this.contactsComponentService.openContact(contact);
	}

	fermerContact(contact: ContactModel) {
		this.contactsComponentService.closeContact(contact, false, false);
	}

	toggleOpen(bool?: boolean) {
		this.bandeauContactOuvert = (bool != null ? bool : !this.bandeauContactOuvert) && this.contactsOpened.length > 0;
		this.cdRef.detectChanges();
	}

	creerContact() {
		if (this.contactsComponentService.getContacts().length < MAX_OPENED_CONTACT) {
			let contactType = 'particuliers';

			if (this.contactOpened) {
				contactType = ContactModel.getContactTypeFront(this.contactOpened);
			}


			this.fullScreenModalService.openFullModal("ajout-contact",
				{
					contactType: contactType,
					callback: (contact) => {

						this.messageService.showSuccess(this.translate.instant("CONTACT.CREATION.OK"));
						this.contactService.openContact(contact);
					}
				});
			this.toggleOpen(false);
		} else {
			this.contactsComponentService.showTooManyContactOpenError();
		}
	}

	ngOnDestroy() {
		this.subscriptions.forEach(subscription => {
			subscription.unsubscribe();
		});
	}
}
