import { HttpClient, HttpErrorResponse, HttpResponse } from "@angular/common/http";
import { IResourceRequest, IResourceResponse } from "@ngx-resource/core";
import { ResourceHandlerHttpClient } from "@ngx-resource/handler-ngx-http";

export class SPSResourceHandler extends ResourceHandlerHttpClient {

	constructor(http: HttpClient) {
		super(http);
	}

	handleResponse(req: IResourceRequest, response: HttpResponse<any> | HttpErrorResponse): IResourceResponse {
		return {
			status: response.status,
			body: (response as HttpResponse<any>).body || (response as HttpErrorResponse).error,
			headers: response.headers
		};
	}
}
