<div class="full-modal">
	<div class="modal-title">
		<div class="title">Client{{ contacts.length > 1 ? 's' : '' }} en attente d'intégration</div>
		<div class="back-button" (click)="closeIntegration()">
			<span class="btn-icon-circle btn-circle-md" title="Fermer">
				<i class="icon-delete"></i>
			</span>
		</div>
	</div>
	<div class="modal-body">
		<!-- Bandeau résultats / integration -->
		<div class="row-eq-height header-search-results">
			<!-- Nombre résultats -->
			<div class="col-md-10 col-md-offset-1">
				<section class="result-label">
					Séléctionnez les clients à intégrer dans votre portefeuille
				</section>
			</div>
			<!-- Intégration contacts -->
			<div class="hidden-xs col-md-1" [hidden]="contactsLoading">
				<div class="btn-labeled label-under"
					 [class.disabled]="integrationDesactivee"
					 (click)="integrerContact()">
					<div class="picto">
						<span class="icon-stack">
							<i class="icon-circle icon-stack-2x"></i>
							<i class="icon-link icon-stack-1x"></i>
						</span>
					</div>
					<div class="label">Intégrer<br>la selection</div>
				</div>
				<!-- NOTE: CàC cachée, possible évo -->
				<div class="text-center check-all">
					<input type="checkbox" id="check-all"
						   #checkall
						   tooltip="Sélectionner tous les contacts"
						   [checked]="isCheckedAllSelection"
						   (change)="changeAllSelection(checkall.checked)"/>
				</div>
			</div>
		</div>
		<!-- Chargement des contacts -->
		<div class="" *ngIf="contactsLoading">
			<sps-loader [message]=" 'INTEGRATION.CHARGEMENT' | translate "></sps-loader>
		</div>
		<!-- Liste des contacts -->
		<div class="contacts-a-integrer" *ngIf="!contactsLoading">
			<div class="row-eq-height contact-a-integrer"
				 *ngFor="let contact of contacts | paginate: {
							 itemsPerPage: taillePage,
							 currentPage: pageCourante,
							 totalItems: nbTotalElement,
							 id: 'integration' };
							 index as i">
				<!-- Bloc contact -->
				<div class="col-md-10 col-md-offset-1">
					<div class="card result">
						<div class="row">
							<!-- Partie gauche - Information contact -->
							<div class="col-md-6">
								<div class="nom">
									<span *ngIf="contact.raisonSociale">{{ contact.raisonSociale }}</span>
									<span *ngIf="contact.nom"><span
											*ngIf="contact.civilite">{{ "CIVILITE_CORRESPONDANT." + contact.civilite | translate }}</span> {{contact.prenom}} {{contact.nom}}
									</span>
								</div>
								<div class="adresse"><label>{{ contact.type | translate }}</label></div>
								<div class="adresse">
									<span *ngIf="contact.adressePostale; else noAdresse"
										  [innerHTML]="formaterAdresse(contact.adressePostale)"></span>
									<ng-template #noAdresse>Adresse non renseignée</ng-template>
								</div>
							</div>
							<!-- Partie droite - Information contrat -->
							<div class="col-md-6">
								<div class="separator"></div>
								<div class="informations-client" *ngIf="contact.contrat === null">
									Aucun contrat
								</div>
								<div class="informations-client" *ngIf="contact.contrat">
									<div *ngIf="contact.dateCreation">
										<label>Date de demande :</label>
										<span>{{ contact.dateCreation }}</span>
									</div>
									<div *ngIf="contact.contrat.risque">
										<label>Type de risque :</label>
										<span>{{ contact.contrat.risque | translate }}</span>
									</div>
									<div *ngIf="contact.contrat.segment">
										<label>Segment :</label>
										<span>{{ contact.contrat.segment | translate }}</span>
									</div>
									<div *ngIf="contact.contrat.garantie">
										<label>Nom de la garantie :</label>
										<span>{{ contact.contrat.garantie }}</span>
									</div>
									<div *ngIf="!contact.contrat.libelleGestionnaire && contact.contrat.gestionnaire">
										<label>Organisation source :</label>
										<sps-res-affectation
												en-tete="VIDE"
												[ressource]="contact.contrat.gestionnaire.ressource"
												[mode-affichage]="'POLE'">
										</sps-res-affectation>
									</div>
									<div *ngIf="contact.contrat.libelleGestionnaire">
										<label>Organisation source :</label>
										{{contact.contrat.libelleGestionnaire}}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<!-- Selection Integration -->
				<div class="col-md-1 integration-checkbox-column">
					<div class="integration-checkbox-container">
						<div class="separator"></div>
						<div class="checkbox-container">
							<input id="integration-checkbox" type="checkbox" class="integration-checkbox"
								   #checkboxcontact
								   [checked]="isCheckedSelection(contact.id)"
								   (change)="changeSelectionContacts(checkboxcontact.checked, contact)"/>
						</div>
					</div>
				</div>
			</div>
		</div>

		<pagination-template id="integration"
							 #pagination="paginationApi"
							 *ngIf="contacts.length && !contactsLoading"
							 (pageChange)="changePage($event)">
			<div class="search-result-pagination">
				<div class="row" *ngIf="pagination?.pages?.length > 1">
					<div class="col-md-3 previous">
						<a *ngIf="!pagination.isFirstPage()" (click)="pagination.previous()">
							<i class="icon-chevron-left"></i> Précédent
						</a>
						<span *ngIf="pagination.isFirstPage()"><i class="icon-chevron-left"></i> Précédent</span>
					</div>
					<div class="col-md-offset-2 col-md-1 pages">
						<div class="dropup">
							<span>Page:</span>
							<button type="button" id="pageMenu"
									class="btn btn-default dropdown-toggle"
									data-toggle="dropdown" aria-haspopup="true" aria-expanded="true">
								{{pagination.getCurrent()}}
								<span class="caret"></span>
							</button>
							<ul class="dropdown-menu" aria-labelledby="pageMenu">
								<li *ngFor="let page of pagination.pages"
									[class.active]="pagination.getCurrent() === page.value"
									(click)="pagination.setCurrent(page.value)">
									{{ page.label }}
								</li>
							</ul>
						</div>
					</div>
					<div class="col-md-offset-2 col-md-3 text-right next">
						<a *ngIf="!pagination.isLastPage()" (click)="pagination.next()">
							Suivant <i class="icon-chevron-right"></i>
						</a>
						<span *ngIf="pagination.isLastPage()">Suivant <i class="icon-chevron-right"></i></span>
					</div>
				</div>
			</div>
		</pagination-template>

		<div class="btn-integration btn btn-success sm centered "
			 [class.disabled]="integrationDesactivee"
			 [hidden]="contactsLoading"
			 (click)="integrerContact()">
			<span><i [class]="integrationLoading ? 'icon-gear icon-spin' : 'icon-user'"></i></span>
			<span>Intégrer les {{ isCheckedAllSelection ? nbTotalElement : selectionContacts.length }} clients</span>
		</div>
	</div>

</div>
