import { InjectableRxStompConfig } from '@stomp/ng2-stompjs';
import { environment } from '../../../../../environments/environment';

export const myRxStompConfig: InjectableRxStompConfig = {
	// How often to heartbeat?
	// Interval in milliseconds, set to 0 to disable
	heartbeatIncoming: 0, // Typical value 0 - disabled
	heartbeatOutgoing: 0, // Typical value 20000 - every 20 seconds

	// Wait in milliseconds before attempting auto reconnect
	// Set to 0 to disable
	// Typical value 5000 (5 seconds)

	//NOTE: En dev, pour éviter des logs toutes les 5secs, on ne fait pas les reconnexions dans le stomp.service.ts
	reconnectDelay: 5000,

	// Will log diagnostics on console
	// It can be quite verbose, not recommended in production
	// Skip this key to stop logging to console
	debug: (msg: string): void => {
		//on arrete d'afficher le token dans la console...
		msg = msg.replace(/^Authorization:.*\n?/gm, '');
		console.log("TelephonieService: ", msg);
	}
};
