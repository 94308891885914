import { AdresseModel } from "./adresse.model";
import { AdresseInternet } from "./adresse.internet.model";
import { EmailModel } from "./email.model";
import { TelephoneModel } from "./telephone.model";
import { ContratModel } from "../../particulier/contrats/contrat.model";
import { TypeContact } from "../../../enums/contact.type.model.enum";
import { RessourceModel } from "../../utilisateur/ressource.model";
import { OrganisationModel } from "./organisationModel";
import {OptionModel} from "../../particulier/projet/option/option.model";

export class ContactModelBase {
	id: number;
	dateCreation: string;
	dateSuppression: string;
	dateModification: string;
	modifiePar: RessourceModel;
	supprime: boolean;
	codeClient: string;
	type: string;
	dateNaissance: string;
	adressePostale: AdresseModel = new AdresseModel();
	adressesPostalesHeritees: Array<AdresseModel> = [];
	siteInternet: AdresseInternet = new AdresseInternet();
	email: EmailModel;
	telephone: TelephoneModel;
	telephones: Array<TelephoneModel> = [];
	contrats: Array<ContratModel>;

	typeContact: string;

	relationPrincipale: string;
	relationPrincipaleCibleUnique: boolean = false;
	identifiantRelationPrincipale: number;

	contratSanteEnCours: boolean;
	contratSanteAVenir: boolean;
	contratSanteResilie: boolean;

	contratPrevoyanceEnCours: boolean;
	contratPrevoyanceAVenir: boolean;
	contratPrevoyanceResilie: boolean;

	modifieParLibelle: string;

	open: boolean;
	selectionne: boolean;
	chargementEnCours: boolean;

	raisonSociale: string; // FIXME: Déplacer depuis ContactEntreprise car utilisé dans relations.type.template.html sans vérification

	// NOTE: Si le contact est à intégrer ou non (Integration par Batch)
	aValider: boolean;

	// NOTE: liste d'ids des doublons du contact
	doublons: Array<number>;

	static getContactType(contact: ContactModel): string {
		return this.getContactTypeFromString(contact.type);
	}

	static getContactTypeFromString(type: string): string {
		return (type == TypeContact[TypeContact.PERSONNE_MORALE]) ? "morales" : (type == TypeContact[TypeContact.PERSONNE_PHYSIQUE]) ? "physiques" : "courtiers";
	}

	static getContactTypeFront(contact: ContactModel): string {
		return this.getContactTypeFrontFromString(contact.type);
	}

	static getContactTypeFrontFromString(type: string): string {
		return (type == TypeContact[TypeContact.PERSONNE_MORALE]) ? "entreprises" : (type == TypeContact[TypeContact.PERSONNE_PHYSIQUE]) ? "particuliers" : "courtiers";
	}

	static getContactTypeFromFront(type: string): string {
		return (type == "entreprises") ? TypeContact[TypeContact.PERSONNE_MORALE] : (type == "particuliers") ? TypeContact[TypeContact.PERSONNE_PHYSIQUE] : TypeContact[TypeContact.COURTIER];
	}

}

export class ContactModel extends ContactModelBase {
	numeroSPS: number;
	tel1: TelephoneModel;
	tel2: TelephoneModel;
	organisations: Array<OrganisationModel>;
	redirectionSuiteFusion: boolean;
	consentementsAbsent: Array<OptionModel>;
	consentementsRefuse: Array<OptionModel>;
	consentementsAccepte: Array<OptionModel>;
	consentementsExpire: Array<OptionModel>;
	nom: String;
	prenom: String;
	numeroAffiche: string;
	libelleAffiche: string;
}

