import { Injectable } from "@angular/core";
import {
	IResourceMethodObservable,
	ResourceAction,
	ResourceParams,
	ResourceRequestMethod
} from "@ngx-resource/core";
import { SPSResource } from "../sps-resource";

@Injectable({
  providedIn: 'root'
})
//TODO Doublon de contact ressource
@ResourceParams({
	// Api path
	path: '/personnes/courtiers/{id}',
})
export class CourtierResource extends SPSResource<any, any, any, any> {
	@ResourceAction({
		method: ResourceRequestMethod.Get,
		path: '/personnes/courtiers/{id}/actualiser',
	})
	actualiser: IResourceMethodObservable<any, any>;

	@ResourceAction({
		method: ResourceRequestMethod.Put,
		path: '/personnes/courtiers/{id}/rafraichir',
	})
	rafraichir: IResourceMethodObservable<any, any>;
}
