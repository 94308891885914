import { Component } from "@angular/core";
import { ContactsComponentsService } from "../../../../services/contacts/contacts.service";
import { ContactModel } from "../../../../models/commun/contacts/contact.model";

@Component({
	selector: 'bouton-fermer-contact',
	templateUrl: './bouton.fermer.contact.template.html',
	styleUrls: ['./bouton.fermer.contact.style.less']
})
export class BoutonFermerContactComponent  {
	private contactOpened: ContactModel;

	constructor(private contactsComponentService: ContactsComponentsService) {

	}

	ngOnInit() {
		this.contactOpened = this.contactsComponentService.getContactHeaderSource().getValue();
		this.contactsComponentService.getContactHeaderSource().subscribe((contactOpened: ContactModel)=>{
			this.contactOpened = contactOpened;
		});
	}

	fermerContact() {
		this.contactsComponentService.closeContact(this.contactOpened);
	}
}
