import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class MobileNavbarService {
	private stateSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

	constructor() {

	}

	toggleNavbar(bool?: boolean) : void {
		this.stateSubject.next(bool != null ? bool : !this.stateSubject.getValue());
	}

	getStateSubject() : BehaviorSubject<boolean>{
		return this.stateSubject;
	}
}
