import { IResourceMethodObservable, ResourceAction } from "@ngx-resource/core";
import { PaginateResponseModel } from "../../models/paginate.response.model";
import { SPSResource } from "./sps.resource";

/**
 * Paginate resource.
 * @returns {{data: Array<T>, count: number, code: number}}
 */
export abstract class PaginateResource<T> extends SPSResource<any, any, PaginateResponseModel<T>, any> {

	@ResourceAction({
		expectJsonArray: true,
		isPaginated: true
	})
	query: IResourceMethodObservable<any, any>;
}
