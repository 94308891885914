/**
 * Created by dcalo on 22/05/17.
 */
import { Injectable } from "@angular/core";
import { ResourceParams } from "@ngx-resource/core";
import { ContactModel } from "../../models/commun/contacts/contact.model";
import { PaginateResource } from "../sps-resource";

@Injectable({
  providedIn: 'root'
})
@ResourceParams({
	path: '/personnes/validation'
})
export class ContactIntegrationResource extends PaginateResource<ContactModel | any> {
}
