import { Component, EventEmitter, HostBinding, HostListener, Input, Output } from "@angular/core";

@Component({
	selector: 'sps-bouton',
	templateUrl: './bouton.template.html'
})
export class BoutonComponent  {
	@Output('action') action: EventEmitter<void> = new EventEmitter<void>();
	@Output('click-action-en-cours') clickActionEnCours: EventEmitter<void> = new EventEmitter<void>();
	@HostBinding('class.disabled') @Input('disabled') disabled: boolean = false;
	@HostBinding('class.working') @Input('working') working: boolean = false;

	constructor() {

	}

	@HostListener('click', ['$event'])
	onClick(e: any) {
		if (!this.working && !this.disabled) {
			this.action.emit();
		} else if (this.working) {
			this.clickActionEnCours.emit();
		}
	}
}
