<!-- Modal de confirmation -->
<div class="modal fade" id="sps-confirm-modal" tabindex="-1" role="dialog" data-backdrop="static">
	<div class="modal-dialog" role="document">
		<div class="modal-content">
			<div class="modal-header">
				<div class="close-btn">
					<span class="icon-stack" (click)="onCancelClick()">
						<i class="icon-circle icon-stack-2x"></i>
						<i class="icon-delete icon-stack-1x"></i>
					</span>
				</div>
				<h4 class="modal-title" [class.two-lines]="modalTitleTwoLines">{{modalTitle}}</h4>
			</div>
			<div class="modal-body">
				<h4 class="modal-title">{{modalContent}}</h4>
				<ng-container *ngIf="modalDetails && modalDetails.length">
					<ng-container *ngFor="let detail of modalDetails">
						<div class="modal-details">{{detail}}</div>
					</ng-container>
				</ng-container>
			</div>
			<div class="modal-footer">
				<button *ngIf="hasCancelBtn" type="button" class="btn btn-{{modalCancelBtnClass}}"
						(click)="onCancelClick()">
					<span><i class="icon-delete" data-e2e="delete"></i></span>
					<span>{{modalCancelBtn}}</span>
				</button>
				<button *ngIf="hasConfirmBtn" type="button" class="btn btn-{{modalConfirmBtnClass}}"
						(click)="onConfirmClick()">
					<span><i class="icon-check" data-e2e="check"></i></span>
					<span>{{modalConfirmBtn}}</span>
				</button>
			</div>
		</div>
	</div>
</div>