/**
 * Created by "Juanito Goncalves <juanito.goncalves@scub.net>" on 27/07/16.
 */
import {ResourceParams} from "@ngx-resource/core";
import {Injectable} from "@angular/core";
import {SPSResource} from "../sps-resource";


@Injectable({
	providedIn: 'root'
})
@ResourceParams({
	// Api path
	path: '/personnes/{typeContact}/{idPersonne}/contrats/{risque}'
})
export class ContratResource extends SPSResource<any, any, any, any> {
}
