/**
 * Enum correspondnt au statut d'un appel (entrant ou sortant).
 */
export enum StatutAppel {
	/**
	 * Le numéro contacté vient de décrocher.
	 */
	DECROCHE,

		/**
		 * Appel en cours.
		 */
	EN_COURS,

		/**
		 * Le numéro contacté vient de raccrocher.
		 */
	RACCROCHE,

		/**
		 * Le numéro contacté vient de raccrocher.
		 */
	ECHEC
}