<div class="application-container" id="application-container" data-e2e="application-container">
	<nav class="hidden-xs navbar navbar-top" data-e2e="barre-de-navigation-haute">
		<!-- Brand and toggle get grouped for better mobile display -->
		<div class="navbar-header">
			<img src="" alt="" class="navbar-toggle collapsed" data-toggle="collapse" data-target="#sps-nav"
				 aria-expanded="false">
			<a id="lien-logo" class="navbar-brand" [routerLink]="['/']"><img src="../assets/logo.png"></a>
			<div class="box-ribbon hidden-xs" *ngIf="envName">
				<div class="ribbon {{envName}}"><span>{{envName}}</span></div>
			</div>
		</div>

		<!-- Collect the nav links, forms, and other content for toggling -->
		<div class="navbar-container navbar-collapse" [class.open]="menuHautDeplie" id="sps-nav" data-e2e="menu-du-haut">
			<div class="left-navbar-part">
				<ul class="nav navbar-nav">
					<!-- <li [routerLinkActive]="['active']">
						<a id="lien-tableau-de-bord" [routerLink]="['/home']">Tableau de bord</a>
					</li> -->
					<li [routerLinkActive]="['active']">
						<a [routerLink]="['/contacts']" data-e2e="contacts" id="lien-contacts">Contacts</a>
					</li>
					<li [routerLinkActive]="['active']" data-e2e="input-evenement">
						<a data-e2e="input_lien-evenements" id="lien-evenements" [routerLink]="['/evenements']">Evenements</a>
					</li>
					<li *ngIf='!featureQualificationMasquerMenu'  [routerLinkActive]="['active']" class="hidden-sm">
						<a [routerLink]="['/qualification']" data-e2e="lien-qualification" id="lien-qualif">Qualification</a>
					</li>
					<li *ngIf='roleCentreDocumentaire' [routerLinkActive]="['active']" class="hidden-md hidden-sm"
						data-e2e="centre-documentaire">
						<a id="lien-centre-documentaire" [routerLink]="['/centre-documentaire']">Centre
							documentaire</a>
					</li>
					<!-- <li [routerLinkActive]="['active']"><a id="lien-agenda">Agenda</a></li> -->


				</ul>
				<ul class="nav navbar-nav">
					<li class="open-menu-controls" data-e2e="ouvrire-menu-controles">
						<ul class="nav navbar-nav collapsed-menu" *ngIf="menuHautDeplie" (click)="fermerMenuMobile()">
							<li *ngIf='!featureQualificationMasquerMenu' [routerLinkActive]="['active']" class="hidden-xs hidden-lg hidden-md">
								<a id="lien-qualif-2" [routerLink]="['/qualification']">Qualification</a>
							</li>
							<li *ngIf='roleCentreDocumentaire' [routerLinkActive]="['active']"
								class="hidden-xs hidden-lg">
								<a id="lien-centre-documentaire-2" [routerLink]="['/centre-documentaire']">Centre
									documentaire</a>
							</li>
							<li [routerLinkActive]="['active']" class="collapsed-menu-item"
								data-e2e="utilisateur-menu-haut">
								<a id="lien-utilisateurs" [routerLink]="['/utilisateurs']">Utilisateurs</a>
							</li>

							<li *ngIf='!featureCampagneMasquerMenu' [routerLinkActive]="['active']" class="hidden-xs collapsed-menu-item"
								data-e2e="campagnes-menu-haut">
								<a id="lien-campagnes" [routerLink]="['/campagnes']">Campagnes</a>
							</li>
							<li *ngIf='!featureSaisiesMasquerMenu' [routerLinkActive]="['active']" class="hidden-xs collapsed-menu-item">
								<a id="lien-saisie" [routerLink]="['/saisies']">Saisies</a>
							</li>
							<li *ngIf='roleBatch'
								class="hidden-xs collapsed-menu-item">
								<a href="{{batchFront}}/administration/batch/import/CP" id="lien-batch">Batch</a>
							</li>
							<li *ngIf='roleAdministrationPortefeuille' [routerLinkActive]="['active']"
								data-e2e="administration"
								class="hidden-xs collapsed-menu-item">
								<a id="lien-administration" [routerLink]="['/administration/portefeuille']">Administration</a>
							</li>
						</ul>
						<span class="icon-stack" (click)="toggleMenuHautDeplie()">
							<i class="icon-circle icon-stack-2x"></i>
							<i class="icon-ellipsis icon-stack-1x"></i>
						</span>
					</li>
				</ul>
			</div>
			<ul class="nav navbar-nav navbar-right">
				<li>
					<sps-connected-user></sps-connected-user>
				</li>

			</ul>
		</div><!-- /.navbar-collapse -->
	</nav>
	<mobile-navbar class="hidden-sm hidden-md hidden-lg"></mobile-navbar>
	<div class="backdrop-top-menu page" *ngIf="menuHautDeplie" (click)="toggleMenuHautDeplie()"></div>
	<!-- Le menu du haut est en position:static et le menu en position:aboslute => on a besoin de 2 backdrop-->
	<div class="backdrop-top-menu menu" *ngIf="menuHautDeplie" (click)="toggleMenuHautDeplie()"></div>

	<div class="message-container">
		<!-- Bandeau appel entrant-->
		<div class="hidden-print global-message incoming-call"
			 [class.on]="appelEntrant?.afficherBandeau">
			<div class="row-centered row-justify-center incoming-info">
				<i class="icon-phone-call"></i>
				<span class="appel-entrant">{{'TELEPHONIE.APPEL_ENTRANT.LIBELLE' | translate}}</span>
				<span class="phone-number">{{appelEntrant?.numeroTelephone}}</span>
				<div class="btn btn-error compact" (click)="traiterAppel()">
					<span><i class="icon-user"></i></span>
					<span *ngIf="!appelEntrant?.contactsTrouves">
						{{'TELEPHONIE.APPEL_ENTRANT.AUCUN_CP' | translate}}
					</span>
					<span *ngIf="appelEntrant?.contactsTrouves && !!appelEntrant?.idContact">
						{{'TELEPHONIE.APPEL_ENTRANT.UN_CP' | translate}}
					</span>
					<span *ngIf="appelEntrant?.contactsTrouves && !appelEntrant?.idContact">
						{{'TELEPHONIE.APPEL_ENTRANT.N_CP' | translate}}
					</span>
				</div>

				<span class="close-btn icon-stack" (click)="masquerBandeauAppelEntrant()">
					<i class="icon-circle icon-stack-2x"></i>
					<i class="icon-delete icon-stack-1x"></i>
				</span>
			</div>

			<div class="progression-attente-appel" [class.on]="appelEntrant?.lancerProgression">
				<div class="progression" [ngStyle]="{'animation-duration': dureeAttenteAppel + 's'}"></div>
			</div>
		</div>

		<!-- Bandeau integration client -->
		<div class="hidden-print global-message integration-tag type-3"
			 [class.on]="bandeauIntegrationAffiche">
			<span class="icon-stack">
			  <i class="icon-circle icon-stack-2x"></i>
			  <i class="icon-stack-1x icon-exclamation"></i>
			</span>
			<span>{{ "INTEGRATION.BANDEAU_CLIENT.MESSAGE" | translate: {nombre: nombreIntegration} }}</span>
			<span class="btn btn-warning inline very-compact"
				  (click)="lancerIntegration()">{{ "INTEGRATION.BANDEAU_CLIENT.BOUTON" | translate }}</span>
			<span class="close-btn icon-stack" (click)="masquerBandeauIntegration()">
			  <i class="icon-circle icon-stack-2x"></i>
			  <i class="icon-delete icon-stack-1x"></i>
			</span>
		</div>

		<!-- Bandeaux GlobalMessageService -->
		<div class="hidden-print global-message type-{{message.type}} on" *ngFor="let message of messages"
			 (click)="clickMessage(message)" data-e2e="bandeau">
			<span class="icon-stack">
				<i class="icon-circle icon-stack-2x"></i>
				<i [class.icon-check]="message.type == 1"
				   [class.icon-delete]="message.type == 0"
				   [class.icon-exclamation]="message.type == 3"
				   [class.icon-information]="message.type == 2"
				   class="icon-stack-1x"
				></i>
			</span>
			<span>
				{{message.message}}
			</span>
			<span class="close-btn icon-stack" (click)="closeMessage(message)">
				<i class="icon-circle icon-stack-2x" data-e2e="fermer-bandeau-2"></i>
				<i class="icon-delete icon-stack-1x" data-e2e="fermer-bandeau"></i>
			</span>
		</div>

	</div>
	<div class="general-page-content" data-e2e="general-page-content"
		 [class.inversed]="background == 'INVERSED'"><!-- FIXME: A voir si le  container-fluid-->
		<router-outlet></router-outlet>
	</div>
</div>

<div class="cgu hidden-xs hidden-sm">
	<a href="http://www.sps-solutions.fr/cgu/cgu.pdf" target="_blank">
		Conditions générales d'utilisation
	</a>
</div>

<!--<editor-fold desc="Section Modales">-->

<modification-password *ngIf="utilisateur" [utilisateur]="utilisateur"></modification-password>

<div class="modal fade" id="deconnexionAlerte" tabindex="-1" role="dialog" data-backdrop="static">
	<div class="modal-dialog" role="document">
		<div class="modal-content">
			<div class="modal-header">
				<div class="close-btn">
					<span class="icon-stack" (click)="logout()">
						<i class="icon-circle icon-stack-2x"></i>
						<i class="icon-on-off icon-stack-1x"></i>
					</span>
				</div>

				<h4 class="modal-title">Attention</h4>
			</div>
			<div class="modal-body">
				La session a expiré, vous allez être déconnecté.
			</div>
			<div class="modal-footer">
				<button type="button" data-dismiss="modal" class="btn btn-success" (click)="logout(true)">
					<i class="icon-check"></i>
					<span>OK</span>
				</button>
			</div>
		</div>
	</div>
</div>

<div class="modal fade" id="sessionInvalide" tabindex="-1" role="dialog" data-backdrop="static">
	<div class="modal-dialog" role="document">
		<div class="modal-content">
			<div class="modal-header">
				<div class="close-btn">
					<span class="icon-stack" (click)="logout(true)">
						<i class="icon-circle icon-stack-2x"></i>
						<i class="icon-on-off icon-stack-1x"></i>
					</span>
				</div>

				<h4 class="modal-title">Attention</h4>
			</div>
			<div class="modal-body">
				Pour des raisons de sécurité, votre session a été fermée. Veuillez vous reconnecter.
			</div>
			<div class="modal-footer">
				<button type="button" data-dismiss="modal" class="btn btn-success" (click)="logout(true)">
					<i class="icon-check"></i>
					<span>OK</span>
				</button>
			</div>
		</div>
	</div>
</div>

<sps-confirm-popup></sps-confirm-popup>

<contact-integration-full-modale></contact-integration-full-modale>

<!--</editor-fold> Section Modales-->
