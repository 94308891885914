/**
 * Created by mcalvez on 29/03/17.
 */
export class ConfirmPopupModel {
	modalId: string;
	modalTitle: string;
	modalTitleTwoLines: boolean = false;
	modalContent: string;
	modalDetails = [];

	hasConfirmBtn: boolean = true;
	modalConfirmBtn: string;
	modalConfirmBtnClass: string = 'success';

	hasCancelBtn: boolean = false;
	modalCancelBtn: string;
	modalCancelBtnClass: string = 'danger';

	onConfirmCallback: Function;
	onCancelCallback: Function;
}