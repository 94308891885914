<ul class="items" data-e2e="items">
	<li [routerLinkActive]="['active']"
		[routerLink]="['/contacts/'+typeContactFront+'/'+contactOpened?.id+'/resume']">
		<a data-e2e="resume" id="lien-entreprise-resume">
			<i class="icon-user-o" aria-hidden="true"></i>
			<span class="item-libelle">Résumé</span>
		</a>
	</li>
	<li [routerLinkActive]="['active']"
		[routerLink]="['/contacts/'+typeContactFront+'/'+contactOpened?.id+'/informations']"
		data-e2e="informations">
		<a id="lien-entreprise-informations">
			<i class="icon-information" aria-hidden="true"></i>
			<span class="item-libelle">Informations</span>
		</a>
	</li>
	<li [routerLinkActive]="['active']"
		*ngIf="typeContactFront == 'courtiers'"
		[class.disabled]="!estManagerReseau"
		[routerLink]="estManagerReseau? ['/contacts/'+typeContactFront+'/'+contactOpened?.id+'/habilitations'] : null">
		<a id="lien-entreprise-habilitation">
			<i class="icon-habilitation" aria-hidden="true"></i>
			<span class="item-libelle">Habilitations</span>
		</a>
	</li>


	<!--<li [routerLinkActive]="['active'] *ngIf="typeContactFront == 'courtiers'">
		<a id="lien-entreprise-statistique"
		   [routerLink]="['./'+typeContactFront+'/'+contactOpened.id+'/statistiques']">
			<i class="icon-stat" aria-hidden="true"></i><span
				*ngIf="!menuGaucheReduit">Statistiques</span></a>
	</li>-->


	<li [routerLinkActive]="['active']"
		[routerLink]="['/contacts/'+typeContactFront+'/'+contactOpened?.id+'/evenements']"
		data-e2e="evenements">
		<a id="lien-entreprise-evenements">
			<i class="icon-bell-o" aria-hidden="true"></i>
			<span class="item-libelle">Évènements</span>
			<span class="mini-badge" *ngIf="nbEvenementEnCours"></span>
			<span class="badge" *ngIf="nbEvenementEnCours">{{nbEvenementEnCours}}</span>
		</a>
	</li>

	<li [routerLinkActive]="['active']"
		*ngIf="typeContactFront == 'entreprises' && !featureSaisiesMasquerMenu"
		[routerLink]="['/contacts/'+typeContactFront+'/'+contactOpened?.id+'/saisies']">
		<a id="lien-entreprise-saisies">
			<i class="icon-save" aria-hidden="true"></i>
			<span class="item-libelle">Saisies</span>
		</a>
	</li>

	<li [routerLinkActive]="['active']"
		*ngIf="(typeContactFront == 'entreprises' || typeContactFront == 'particuliers') && !featureProjetsMasquerMenu"
		[routerLink]="['/contacts/'+typeContactFront+'/'+contactOpened?.id+'/projets']"
		data-e2e="projets">
		<a id="lien-entreprise-projets">
			<i class="icon-folder-o" aria-hidden="true"></i>
			<span class="item-libelle">Projets</span>
			<span class="mini-badge" *ngIf="nbProjetEnCours"></span>
			<span class="badge" *ngIf="nbProjetEnCours">{{nbProjetEnCours}}</span>
		</a>
	</li>
	<li [routerLinkActive]="['active']"
		*ngIf="(typeContactFront == 'entreprises' || typeContactFront == 'particuliers')"
		hasRole="ROLE_GESTION"
		[routerLink]="['/contacts/'+typeContactFront+'/'+contactOpened?.id+'/contrats']"
		data-e2e="contrats">
		<a id="lien-entreprise-contrats">
			<i class="icon-contract-o" aria-hidden="true"></i>
			<span class="item-libelle">Contrats</span>
			<span class="mini-badge" *ngIf="nbContratEnCours"></span>
			<span class="badge" *ngIf="nbContratEnCours">{{nbContratEnCours}}</span>
		</a>
	</li>
	<li [routerLinkActive]="['active']"
		[routerLink]="['/contacts/'+typeContactFront+'/'+contactOpened?.id+'/reclamations']"
		data-e2e="reclamation">
		<a id="lien-entreprise-reclamations">
			<i class="icon-bullhorn-o" aria-hidden="true"></i>
			<span class="item-libelle">Réclamations</span>
			<span class="mini-badge badge-reclamation" *ngIf="nbReclamationEnCours"></span>
			<span class="badge badge-reclamation" *ngIf="nbReclamationEnCours">{{nbReclamationEnCours}}</span>
		</a>
	</li>
	<li class="two-lines"
		[routerLinkActive]="['active']"
		[routerLink]="['/contacts/'+typeContactFront+'/'+contactOpened?.id+'/ged']"
		data-e2e="documents_et_emails">
		<a id="lien-ged">
			<i class="icon-email-o" aria-hidden="true"></i>
			<span class="item-libelle">Documents
				<br class="hidden-xs">
				et emails
			</span>
		</a>
	</li>
	<li [routerLinkActive]="['active']"
		hasRole="ROLE_RESPONSABLE"
		*ngIf="peutAccederAuRGPDDuMenuContact()"
		[routerLink]="['/contacts/'+typeContactFront+'/'+contactOpened?.id+'/rgpd']"
		data-e2e="rgpd">
		<a id="lien-rgpd">
			<i class="icon-ddc-on" aria-hidden="true"></i>
			<span class="item-libelle">RGPD</span>
		</a>
	</li>
</ul>
