import { Injectable } from "@angular/core";
import { Observable, of, Subject, zip } from "rxjs";
import { AuthGuard } from "./guards/auth.guard";
import { LocalStorageService, LS_CONTACT } from "./services/LocalStorage.service";

export type InteralStateType = {
	[key: string]: any
};

@Injectable({
  providedIn: 'root'
})
export class AppState {
	_state: InteralStateType = {};

	/**
	 * Couple servant à notifier des modifications concernant le background CSS.
	 */
	private backgroundSub: Subject<string> = new Subject<string>();
	private backgroundObs: Observable<string> = this.backgroundSub.asObservable();

	public actionAvantDecoRealiseeSub: Subject<boolean> = new Subject<boolean>();
	public actionAvantDecoRealiseeObs: Observable<boolean> = this.actionAvantDecoRealiseeSub.asObservable();
	/**
	 * Liste des actions à executer avant la déconnexion.
	 * Ajouter ici les observables devant être appelés.
	 * @type {Array}
	 */
	private actionsAvantDeco: Array<{ identifiant: string, method: Function }> = [];

	constructor(private storage: LocalStorageService) {
	}

	public deconnecter() {
		console.debug("deconnecter", this.actionsAvantDeco);
		let obs = this.quitterPage();
		obs && obs.subscribe(() => {
			this.actionAvantDecoRealiseeSub.next(true);
			this.finaliserLogout();
		}, errors => {
			this.finaliserLogout();
		});

	}

	public quitterPage(): Observable<any> {
		if (this.confirmerQuitter()) {
			return this.obsActionAvantDeco();
		}
	}

	public obsActionAvantDeco(): Observable<any> {
		if (this.actionsAvantDeco.length) {
			let obs = [];
			this.actionsAvantDeco.forEach((item) => {
				if (item.method)
					obs.push(item.method());
			});

			return zip(...obs);
		}
		return of(null);
	}

	private confirmerQuitter(): boolean {
		if (this.storage.exists(LS_CONTACT)) {
			let confirmationMessage = "Vous avez des modifications non enregistrées dans SPS. Voulez­vous vraiment quitter l’application ?";
			return window.confirm(confirmationMessage);
		} else {
			return true;
		}
	}


	private finaliserLogout() {
		console.debug("clear cache avec deco");
		new LocalStorageService().clear();
		console.debug("redirection logout");
		AuthGuard.redirigerVersLogout();
	}

	/**
	 * Permet d'ajouter des actions à éxecuter avant la déconnexion.
	 * @param identifiant Identifiant unique de l'action
	 * @param action Fonction à appeler avant la déconnexion.
	 */
	ajouterActionAvantDeconnexion(identifiant: string, action: Function) {
		this.actionsAvantDeco.push({identifiant: identifiant, method: action});
	}

	/**
	 * Permet de supprimer une action précédemment ajoutée.
	 * @param action identifiant à supprimer.
	 */
	supprimerActionAvantDeconnexion(identifiant) {
		this.actionsAvantDeco.some((item, index) => {
			if (item.identifiant == identifiant) {
				this.actionsAvantDeco.splice(index, 1);
				return true;
			}

		});

	}

	/**
	 * Permet de supprimer une action précédemment ajoutée.
	 * @param action identifiant à supprimer.
	 */
	clearActionAvantDeconnexion() {
		this.actionsAvantDeco.forEach((item, index) => {
			this.actionsAvantDeco.splice(index, 1);
		});
	}

	// already return a clone of the current state
	get state() {
		return this._state = this._clone(this._state);
	}

	// never allow mutation
	set state(value) {
		throw new Error('do not mutate the `.state` directly');
	}


	get(prop?: any) {
		// use our state getter for the clone
		const state = this.state;
		return state.hasOwnProperty(prop) ? state[prop] : state;
	}

	set(prop: string, value: any) {
		// internally mutate our state
		return this._state[prop] = value;
	}

	/**
	 * Préviens les abonnés d'un changement de background.
	 * @param background
	 */
	setBackground(background: string) {
		this.backgroundSub.next(background);
	}

	/**
	 * Permet l'abonnement au modification de background.
	 * @returns {@link Observable<string>}
	 */
	registerBackground() {
		return this.backgroundObs;
	}


	private _clone(object: InteralStateType) {
		// simple object clone
		return JSON.parse(JSON.stringify(object));
	}
}
