/**
 * .
 * Created by Kobla GBENYO (kobla.gbenyo@scub.net) on 28/10/16.
 */
import { Component, Input } from "@angular/core";


@Component({
	selector: 'sps-niveau-besoin-display',
	templateUrl: './niveau.besoin.display.template.html',
	styleUrls: ['./niveau.besoin.display.style.less'],

})
export class NiveauBesoinDisplay  {

	@Input("niveau-besoin") niveauBesoin: string;
	@Input("niveau-besoin-libelle") niveauBesoinLibelle: string;
	@Input("icon-nom") iconeNom: string;
	@Input("more-icon") moreIcon: string = "";

	niveauBesoinValeur: number;

	constructor() {

	}

	ngOnInit() {
		if (this.niveauBesoin == 'NIVEAU_0') {
			this.niveauBesoinValeur = 1;
		} else if (this.niveauBesoin == 'NIVEAU_1') {
			this.niveauBesoinValeur = 2;
		} else if (this.niveauBesoin == 'NIVEAU_2') {
			this.niveauBesoinValeur = 3;
		} else if (this.niveauBesoin == 'NIVEAU_3') {
			this.niveauBesoinValeur = 4;
		} else if (this.niveauBesoin == 'NIVEAU_4') {
			this.niveauBesoinValeur = 5;
		} else {
			this.niveauBesoinValeur = 0;
		}
	}


}
