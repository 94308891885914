<div>
	<div class="couverture">
		<div class="besoin-title" *ngIf="niveauBesoinLibelle">
			<i class="{{moreIcon}}" *ngIf="moreIcon"></i>
			<i class="item-picture {{iconeNom}}"></i> {{niveauBesoinLibelle}}
		</div>
		<div class="steps-container">
			<ng-container *ngIf="!niveauBesoinLibelle">
				<i class="{{moreIcon}}" *ngIf="moreIcon"></i>
			</ng-container>
			<i class="item-picture {{iconeNom}}" *ngIf="!niveauBesoinLibelle"></i>

			<i *ngFor="let i of [1, 2, 3, 4]" class="step step{{i}} icon-round"
			   [class.active]="(i + 1) <= niveauBesoinValeur"></i>
		</div>
	</div>
</div>