import { IdLibelleModel } from "../referentiels/id.libelle.model";
import { FichierModel } from "../commun/ged/fichier.model";
import { PortefeuilleUtilisateur } from "./portefeuille.utilisateur.model";
import { UtilisateurAccesDonneesModel } from "./utilisateur.accesdonnees.model";
import { RessourceModel } from "./ressource.model";
import { OutilSupport } from "../outil-support.model";

export enum ApporteurAssureur {
	APPORTEUR,
	ASSUREUR
}

export class UtilisateurModelBase {
	id: number;
	nom: string;
	prenom: string;
	login: string;
	actif: any;
	entiteJuridique: IdLibelleModel;
	isApporteur: keyof typeof ApporteurAssureur;
	fonction: IdLibelleModel;
	service: IdLibelleModel;
	dateCreation: string;
	dateDerniereConnexion: string;
	nombreConnexion: number;
	idCreateur: number;
	visibleParAutresPortefeuilles: boolean;
	creePar: RessourceModel;
	identifiantExterieur: string;
}

export class UtilisateurConnecte extends UtilisateurModelBase {
	pole: IdLibelleModel;
	portefeuille: PortefeuilleUtilisateur;
	roles: Array<string>;
	featureFlippingKeys: Array<string>;
	codePrimaire: string;
	cguAcceptees: boolean;
	affectationPossible: boolean;
	outilSupport: OutilSupport;
}

export class UtilisateurModel extends UtilisateurConnecte {
	avatar: FichierModel;
	avatarUrl: string;//url contruite avec le token
	accesDonnees: Array<UtilisateurAccesDonneesModel>;
	createur: string;
	quotasPossible: boolean;
}
