import { Component, Input, SimpleChanges, ViewEncapsulation } from "@angular/core";
import { RessourceModel } from "../../../models/utilisateur/ressource.model";
import { TranslateService } from "@ngx-translate/core";
import { AffectationModeEnum } from "../../../enums/afectation.mode.enum";
import { Utils } from "../../../utils/Utils";


@Component({
	selector: 'sps-res-affectation',  // <sps-res-affectation></sps-res-affectation>
	encapsulation: ViewEncapsulation.None,
	templateUrl: 'affectation.template.html',
	styleUrls: ['affectation.style.less']
})
export class AffectationComponent  {

	/**
	 * Cle de trad pour AFFECTATION.ENTETE. Defaut -> CREE_PAR
	 */
	@Input("en-tete") enTete: string = "CREE_PAR";

	/**
	 * La ressourceModel à afficher
	 */
	@Input("ressource") ressource: RessourceModel;

	/**
	 * Le mode d'affichage (affichage nom user ou pole/agence). Ddefaut -> UTILISATEUR
	 */
	@Input("mode-affichage") modeAffichage: string = AffectationModeEnum[AffectationModeEnum.UTILISATEUR];

	/**
	 * Date a afficher. Defaut -> ""
	 */
	@Input("date-string") dateString: string = "";

	libelleFinal: string = "";
	libelleTooltip: string = "";

	constructor(private translateService: TranslateService) {

	}

	ngOnChanges(changes: SimpleChanges) {
		if (changes && (changes.enTete || changes.ressource || changes.modeAffichage || changes.dateString)) {
			this.construireLibelles();
		}
	}

	ngOnInit() {
		this.construireLibelles();
	}

	construireLibelles() {
		let cleanObjet = null;
		if (this.ressource != null) {
			cleanObjet = Utils.cleanObjet(Utils.clone(this.ressource));
		}
		//Si pas de ressource
		if (!cleanObjet) {
			this.libelleFinal = this.translateService.instant("UTILISATEUR.ATTRIBUTION.VIDE");
		} else {
			//Sinon on affiche systématiquement l'entité juridique
			this.libelleFinal = this.getEntiteJuridique();
			this.libelleTooltip = this.getEntiteJuridique(true);
			//Si utilisateur, alors le nom, si pole, alors le pole/agence
			if (this.modeAffichage == AffectationModeEnum[AffectationModeEnum.UTILISATEUR]) {
				this.libelleFinal += cleanObjet.libelle ? cleanObjet.libelle : this.getPoleAgence();
				this.libelleTooltip += this.getPortefeuille() + this.getPoleAgence() + (cleanObjet.libelle ? (this.getPoleAgence() ? "\n" : "") + cleanObjet.libelle : "");
			} else if (this.modeAffichage == AffectationModeEnum[AffectationModeEnum.POLE]) {
				this.libelleFinal += this.getPoleAgence();
				this.libelleTooltip += this.getPortefeuille() + this.getPoleAgence();
			} else if (this.modeAffichage == AffectationModeEnum[AffectationModeEnum.POLE_UTILISATEUR]) {
				this.libelleFinal += this.getPoleAgence() + (cleanObjet.libelle ? " - " + cleanObjet.libelle : "");
				this.libelleTooltip += this.getPortefeuille() + this.getPoleAgence() + (cleanObjet.libelle ? (this.getPoleAgence() ? "\n" : "") + cleanObjet.libelle : "");
			}
		}
	}

	getEntiteJuridique(tooltip: boolean = false) {
		return this.ressource.entiteJuridique && this.ressource.entiteJuridique.libelle ? this.ressource.entiteJuridique.libelle + (tooltip ? "\n" : " - ") : "";
	}

	getPoleAgence() {
		return this.ressource.pole && this.ressource.pole.libelle ? this.ressource.pole.libelle : "";
	}

	getPortefeuille() {
		return this.ressource.portefeuille && this.ressource.portefeuille.libelle ? this.ressource.portefeuille.libelle + "\n" : "";
	}

	ngOnDestroy() {
	}
}
