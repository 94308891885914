import { Routes } from "@angular/router";
import { TranslationResolve } from "../../app.translation.resolver";
import { Error403Component } from "./403.component";
import { ErrorsComponent } from "./errors.component";

export const errorsRoutes: Routes = <Routes>[
	{
		path: 'erreurs',
		component: ErrorsComponent,
		resolve: {
			translate: TranslationResolve
		},
		children: [
			{path: '403', component: Error403Component},
		]
	}
];
