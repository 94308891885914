export class ScrollUtils {

	/**
	 * Initialisation du comportement de scroll des composants utilisant la ScrollDirective.
	 *
	 * Le but est de masquer le composant application-container / la nav bar en haut de l'écran lorsque l'utilisateur
	 * commence à scroller et de le réafficher s'il remonte en haut de page.
	 */
	public static startScrollListner() {
		let lastKnownScrollPosition = 0;
		let ticking = false;

		/**
		 * Gestion de l'état de l'application-container / la nav bar en fonction du scroll.
		 * @param scrollPos la valeur du scroll en pixels
		 */
		function handleScrollBar(scrollPos) {
			if (scrollPos > 10) {
				// On masque l'application-container / la nav bar dés qu'on a un scroll de plus de 10 pixels
				document.getElementById("application-container").classList.add("nav-bar-off");
			} else {
				// Si le scroll est suffisamment faible, on ré-affiche l'application-container / la nav bar
				document.getElementById("application-container").classList.remove("nav-bar-off");
			}
		}

		// Retire d'éventuels scroll event listener sur le composant
		document.getElementById("page-scroller").removeEventListener('scroll', null, false);

		document.getElementById("page-scroller").addEventListener('scroll', function (e) {
			// On récupère la valeur actuelle du scroll
			lastKnownScrollPosition = document.getElementById("page-scroller").scrollTop;

			// On utilise un verrou pour éviter d'appeler handleScrollBar tant que l'appel précédent n'a pas été traié
			if (!ticking) {
				// On active immédiatement le verrou pour être sûr qu'il soit désactivé lors de l'animation frame (appel asynchrone).
				// Si on l'activait après l'appel à window.requestAnimationFrame, on pourrait bloquer les appels à
				// handleScrollBar si jamais on entrait immédiatement dans l'animation frame car le verrou serait alors
				// toujours actif et ne pourrait plus être retiré
				ticking = true;

				// A la prochaine frame d'animation disponible, on actualise l'état de la nav bar et on retire le verrou
				window.requestAnimationFrame(function () {
					handleScrollBar(lastKnownScrollPosition);
					ticking = false;
				});
			}
		});

		// Émet un événement scroll sur le composant pour rentrer dans l'event listener et ainsi actualiser l'état de l'application-container
		document.getElementById("page-scroller").dispatchEvent(new Event('scroll'));
	}

	public static scrollToElm(container, elm, duration) {
		if (elm && container) {
			var pos = ScrollUtils.getRelativePos(elm);
			ScrollUtils.scrollTo(container, pos.top, duration, null);  // duration in seconds
		} else
			console.warn("Impossible de scrollToElm: Container ou cible non existant");
	}

	public static getRelativePos(elm): HTMLNode {
		var pPos = elm.parentNode.getBoundingClientRect(), // parent pos
			cPos = elm.getBoundingClientRect(), // target pos
			pos = new HTMLNode();

		pos.top = cPos.top - pPos.top + elm.parentNode.scrollTop,
			pos.right = cPos.right - pPos.right,
			pos.bottom = cPos.bottom - pPos.bottom,
			pos.left = cPos.left - pPos.left;

		return pos;
	}

	public static scrollTo(element, to, duration, onDone = null) {
		function animateScroll() {
			now = performance.now();
			elapsed = (now - startTime) / 1000;
			t = (elapsed / duration);

			element.scrollTop = start + change * ScrollUtils.easeInOutQuad(t);

			if (t < 1)
				window.requestAnimationFrame(animateScroll);
			else
				onDone && onDone();
		};

		if (element && to != undefined) {
			var start = element.scrollTop,
				change = to - start,
				startTime = performance.now(),
				val, now, elapsed, t;
			animateScroll();
		} else
			console.warn("Impossible de scrollTo: Container ou cible non existant")
	}

	public static easeInOutQuad(t) {
		return t < .5 ? 2 * t * t : -1 + (4 - 2 * t) * t
	};


}

export class HTMLNode {
	top: number;
	left: number;
	right: number;
	bottom: number;
}
