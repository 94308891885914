/**
 * Created by "Juanito Goncalves <juanito.goncalves@scub.net>" on 16/07/16.
 */
import { Component } from "@angular/core";

@Component({
	selector: 'referentiel-accordsante-entreprise',
	styleUrls: ['./referentiel.accordsante.entreprise.style.less'],
	templateUrl: './referentiel.accordsante.entreprise.template.html',
})
export class ReferentielAccordSanteEntrepriseComponent  {

}
