import { Injectable } from "@angular/core";
import { Observable, of, Subject } from "rxjs";
import { ContactCourtier } from "../models/courtier/contacts/contact.courtier.model";
import { ContactResource } from "../resources/contact/contact.resource";
import { GlobalMessageService } from "./global.message.service";
import { CourtierResource } from "../resources/courtier/courtier.resource";
import { HabilitationModel } from "../models/courtier/contacts/habilitation.model";
import { catchError, map, single } from 'rxjs/operators';
import { CourtierOriasResponse } from '../models/courtier/contacts/courtier.orias.response.enum';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
	providedIn: 'root'
})
export class CourtierService {

	constructor(private contactResource: ContactResource,
				private courtierResource: CourtierResource,
				private messageService: GlobalMessageService,
				private translateService: TranslateService) {
	}

	private habilitation = new Subject<HabilitationModel>();
	public habilitationObs = this.habilitation.asObservable();

	update(contactId: number): Observable<ContactCourtier> {
		return this.courtierResource.actualiser({id: contactId}).pipe(single(res => {
				this.messageService.showInfo("Le rafraîchissement des données a bien été effectué.");
				return res;
			}
		));
	}

	rafraichir(contactId: number): Observable<boolean> {
		return this.courtierResource.rafraichir({id: contactId}).pipe( // Appel de la route /rafraichir pour demander un rafraichissment ORIAS en back du courtier.
			map((response: CourtierOriasResponse) => { // Mapping de cette réponse vers un boolean (qui n'est pas utilisé pour l'instant, mais qui pourrait l'être par la suite, plus affichage d'un message en fonction du retour de la route /rafraichir
				switch (response) {
					case CourtierOriasResponse.OK:
						this.messageService.showInfo(this.translateService.instant("ORIAS.RAFRAICHISSEMENT_OK"));
						return true;
					case CourtierOriasResponse.DEJA_A_JOUR:
						return true;
					case CourtierOriasResponse.ERREUR:
						this.messageService.showError(this.translateService.instant("ORIAS.SERVICE_INDISPONIBLE"));
						return false;
				}
			}),
			catchError(_ => of(false)) // Dans le cas où la route /rafraichir a un problème, le catchError se déclanche et créé un nouveau observable pour pouvoir continuer la chaine et ignorer l'erreur sur /rafraichir
		);
	}

	delete(contactId: number): Observable<ContactCourtier> {
		return this.contactResource.remove({
			typeContact: "courtiers",
			id: contactId
		}).pipe(single((contact: ContactCourtier) => {
			return null;
		}));
	}

	onHabilitationSauvegardee(habilitation: HabilitationModel) {
		this.habilitation.next(habilitation);
	}
}
