import { Injectable } from "@angular/core";
import { Subject } from "rxjs";
import { GlobalMessageModel, MessageType } from "../models/global.message.model";
import { Utils } from "../utils/Utils";

@Injectable({
	providedIn: 'root'
})
export class GlobalMessageService {
	private subject = new Subject<GlobalMessageModel>();


	globalMessageObserver = this.subject.asObservable();

	showSuccess(message: string) {
		this.subject.next(new GlobalMessageModel(MessageType.SUCCESS, message));
	}

	showError(message: string) {
		this.subject.next(new GlobalMessageModel(MessageType.ERROR, message));
	}

	showInfo(message: string) {
		this.subject.next(new GlobalMessageModel(MessageType.INFO, message));
	}

	showWarning(message: string) {
		this.subject.next(new GlobalMessageModel(MessageType.WARNING, message));
	}

	handlerRessourceErrorResponse(error: any, message: string = null) {
		if (error.status == 307) {
			this.showWarning(Utils.formaterErreurBack(error, "PROVIDER_NON_DISPONIBLE"));
		} else if (message) {
			this.showError(message);
		}

	}

}
