/**
 * Ressource Backend pour les contacts.
 * Created by "Juanito Goncalves <juanito.goncalves@scub.net>" on 27/07/16.
 */
import { ResourceParams } from "@ngx-resource/core";
import { Injectable } from "@angular/core";
import { PaginateResource } from "./sps-resource";
import { ContactModel } from "../models/commun/contacts/contact.model";

@Injectable({
  providedIn: 'root'
})
@ResourceParams({
	// Api path
	path: '/personnes/{typeContact}/{id}',
})
export class ContactPaginateResource extends PaginateResource<ContactModel> {

}
