/**
 * Created by "Juanito Goncalves <juanito.goncalves@scub.net>" on 16/07/16.
 */
import { Component } from "@angular/core";

@Component({
	selector: 'referentiel',
	templateUrl: './referentiel.template.html',
	styleUrls: ['./referentiel.style.less'],
})
export class ReferentielComponent  {

}
