/**
 * Created by cguimezanes on 02/03/17.
 */
import { Directive, ElementRef, HostBinding, Input } from "@angular/core";
import { UtilisateurService } from "../../services/utilisateur.service";

@Directive({
	selector: '[hasRole]'
})
export class HasRoleDirective {

	@Input('disable-on-forbidden') disableOnForbidden: boolean = false;
	@Input('role-operator') operateur = 'OR';
	@Input() hasRole: string | Array<string>;
	@HostBinding('class.disabled') isDisabled: boolean = false;

	constructor(private el: ElementRef, private utilisateurService: UtilisateurService) {

	}

	ngOnInit() {
		if (!this.utilisateurService.hasRole(this.hasRole, this.operateur)) {
			this.isDisabled = this.disableOnForbidden;

			if (!this.disableOnForbidden && this.el && this.el.nativeElement && this.el.nativeElement.style) {
				this.el.nativeElement.style.display = 'none';
			}
		}
	}
}
