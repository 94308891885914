/* External modules */

/* Modules */
import { CustomDirectivesModule } from "../directives/custom.directives.module";
import { CustomPipesModule } from "../pipe/custom.pipes.module";
/* Components */
import { BoutonComponent } from "./bouton/bouton.component";
import { CheckboxRadioComponent } from "./checkbox/checkbox-radio.component";
import { ConsignesGenerales } from "./consignes-generales/consignes.generales.component";
import { CountrySelector, CountrySelectorButton } from "./country-selector";
import { DragDropComponent } from "./dragdrop/dragdrop.component";
import { LoaderComponent } from "./loader/loader.components";
import { RattachementContratComponent } from "./rattachement-contrat/rattachement.contrat.component";
import { RecapErreur } from "./recapErreur/recapErreur.component";
import { AffectationComponent } from "./ressource-affectaction/affectation.component";
import { EnumSuggest } from "./resource-suggest/enum/suggest.enum.component";
import { SuggestAdresse } from "./resource-suggest/adresse";
import { SuggestComponent } from "./resource-suggest/suggest/suggest.component";
import { ServeurErreur } from "./serveur-erreur/serveur.erreur.component";
import { SimpleInputModaleComponent } from "./simple-input/simple-input.modale.component";
import { TexteEditableComponent } from "./texte-editable/texte.editable.component";
import { SuggestUtilisateurComponent } from "./utilisateur-suggest/suggest.utilisateur.component";
import { VisualisationDocumentComponent } from "./visualisation-document/visualisation.document.component";
import { VisualisationWidgetComponent } from "./visualisation-document/visualisation/visualisation.widget.component";
import { VisuRattachementDocumentComponent } from "./visualisation-document/visualisation/rattachement/rattachement.component";
import { CorrespondantComponent } from "./correspondant";
import { NiveauBesoinDisplay } from "./niveau-besoin-display/niveau.besoin.display.component";
import { IbanComponent } from "./iban/iban.components";
import { DetailProduit } from "./detail-produit/detail.produit.component";
import { TarifProduitComponent } from "./tarif-produit/tarif.produit.component";
import { NiveauBesoin } from "./niveau-besoin/niveau.besoin.component";
import { NiveauBesoinForm } from "./niveau-besoin/niveau.besoin.formgroup.component";
import { ListingDestinataireComponent } from "./listing-destinataire/listing.destinataire.component";
import { ContratRessourceComponent } from "./contrat.ressource.component";
import { ConfirmPopupComponent } from "./confirm-popup/confirm.popup.component";
import { SuggestLABItemComponent } from "../contacts/particuliers/informations/lab/suggest-lab-item/suggest-lab-item.component";
import { ProfilParticulier } from "../contacts/particuliers/profil";
import { RadioListComponent } from "./radio-list/radio.list.component";
import { IconControlStatusComponent } from "../contacts/entreprises/projet/adhesion/shared/icon-control-status";
import { ListingExpediteurComponent } from "./listing-expediteur/listing.expediteur.component";
import { ListingExpediteurCourrierComponent } from "./listing-expediteur-courrier/listing.expediteur.courrier.component";
import { PublipostageModeleComponent } from "./publipostage-modele/publipostage-modele.component";
import { TiffViewerComponent } from './visualisation-document/visualisation/tiff-viewer/tiff-viewer.component';
import { PublipostageSmsComponent } from './publipostage-sms/publipostage-sms.component';
import { NgModule } from "@angular/core";
import { PaginatePipe } from 'ngx-pagination';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { FileUploadModule } from 'ng2-file-upload';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { DateBox } from './datebox';
import { DateTimeBox } from './datebox/dateTime.component';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { CKEditorModule } from 'ng2-ckeditor';

@NgModule({
	imports: [
		CommonModule,
		FormsModule, // EnumSuggest
		ReactiveFormsModule, // SuggestUtilisateurComponent
		HttpClientModule,
		TranslateModule.forChild(),
		TypeaheadModule, // EnumSuggest
		TooltipModule,
		FileUploadModule,
		PdfViewerModule,
		BsDatepickerModule,
		CustomPipesModule,
		CustomDirectivesModule,
		CKEditorModule
	],
	declarations: [
		BoutonComponent,
		CheckboxRadioComponent,
		ConsignesGenerales,
		CountrySelector,
		CountrySelectorButton,
		DragDropComponent,
		LoaderComponent,
		RattachementContratComponent,
		RecapErreur,
		AffectationComponent,
		EnumSuggest,
		SuggestAdresse,
		SuggestComponent,
		ServeurErreur,
		SimpleInputModaleComponent,
		TexteEditableComponent,
		SuggestUtilisateurComponent,
		VisualisationDocumentComponent,
		VisualisationWidgetComponent,
		VisuRattachementDocumentComponent,
		CorrespondantComponent,
		ConfirmPopupComponent,
		NiveauBesoin,
		NiveauBesoinForm,
		NiveauBesoinDisplay,
		DetailProduit,
		IbanComponent,
		ListingDestinataireComponent,
		ListingExpediteurComponent,
		ListingExpediteurCourrierComponent,
		TarifProduitComponent,
		ContratRessourceComponent,
		SuggestLABItemComponent, // TODO: Déplacer dans le dossier 'components/ui'
		ProfilParticulier,  // TODO: Déplacer dans le dossier 'components/ui'
		RadioListComponent,
		IconControlStatusComponent, // TODO: Déplacer vers '/ui' ou ajouter dans module adhesion
		TiffViewerComponent,
		PublipostageModeleComponent,
		PublipostageSmsComponent,
		DateBox,
		DateTimeBox,
	],
	providers:[
		PaginatePipe
	],
	exports: [
		CommonModule,
		FormsModule, // EnumSuggest
		ReactiveFormsModule, // SuggestUtilisateurComponent
		HttpClientModule,
		TranslateModule,
		BsDatepickerModule,

		TypeaheadModule,
		TooltipModule,
		FileUploadModule,
		PdfViewerModule,
		DateBox,
		DateTimeBox,
		CustomPipesModule,
		CustomDirectivesModule,
		CKEditorModule,


		BoutonComponent,
		CheckboxRadioComponent,
		ConsignesGenerales,
		CountrySelector,
		CountrySelectorButton,
		DragDropComponent,
		LoaderComponent,
		RattachementContratComponent,
		RecapErreur,
		AffectationComponent,
		EnumSuggest,
		SuggestAdresse,
		SuggestComponent,
		ServeurErreur,
		SimpleInputModaleComponent,
		TexteEditableComponent,
		SuggestUtilisateurComponent,
		VisualisationDocumentComponent,
		VisualisationWidgetComponent,
		VisuRattachementDocumentComponent,
		CorrespondantComponent,
		ConfirmPopupComponent,
		NiveauBesoin,
		NiveauBesoinForm,
		NiveauBesoinDisplay,
		DetailProduit,
		IbanComponent,
		ListingDestinataireComponent,
		ListingExpediteurComponent,
		ListingExpediteurCourrierComponent,
		TarifProduitComponent,
		ContratRessourceComponent,
		SuggestLABItemComponent, // TODO: Déplacer dans le dossier 'components/ui'
		ProfilParticulier,  // TODO: Déplacer dans le dossier 'components/ui'
		RadioListComponent,
		IconControlStatusComponent, // TODO: Déplacer vers '/ui' ou ajouter dans module adhesion
		TiffViewerComponent,
		PublipostageModeleComponent,

		PublipostageSmsComponent
	]
})
/* Widget Module */
export class UiModule {
}
