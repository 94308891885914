/* External decorators & services */
import { Injectable } from "@angular/core";
import {
	ActivatedRouteSnapshot,
	CanActivate,
	CanActivateChild,
	Router,
	RouterStateSnapshot
} from "@angular/router";
import { Observable, Subject } from "rxjs";
import { environment } from "environments/environment";
/* Services */
import {
	LocalStorageService,
	LS_AUTHENTIFICATION_SESSION_DATE,
	LS_AUTHENTIFICATION_TOKEN
} from "../services/LocalStorage.service";
import { GlobalMessageService } from "../services/global.message.service";
import { UtilisateurService } from "../services/utilisateur.service";
/* Models */
import { AuthentificationModel } from "../models/authentification.model";
import * as moment from "moment";

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate, CanActivateChild {
	urlAuth: string;

	constructor(private router: Router,
				private storage: LocalStorageService,
				private messageService: GlobalMessageService,
				private utilisateurService: UtilisateurService) {
		this.urlAuth = "/oauth/authorize?response_type=code&client_id=" + environment.AUTH_APPNAME + "&secret=" + environment.AUTH_SECRET + "&scope=read&redirect_uri=";
	}

	private subjectChilds: Subject<boolean> = new Subject<boolean>();
	private childObservable;

	canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean {
		if (!AuthGuard.isSessionValid()) {
			return false;
		}
		let authentification: AuthentificationModel = this.storage.load(LS_AUTHENTIFICATION_TOKEN);
		if (authentification) {
			let utilisateur = this.utilisateurService.getUtilisateurConnecte();
			if (utilisateur && !utilisateur.cguAcceptees && state.url !== '/cgu') {
				this.router.navigate(['/cgu']);
				return false;
			}
			return true;
		} else {
			if (this.childObservable) {
				return this.childObservable;
			} else {
				this.childObservable = this.subjectChilds.asObservable();
			}
			// Pas d'authentification
			if (route.queryParams["code"]) {
				// Authentification en cours
				return Observable.create(observer => {
					let formData = new FormData();

					formData.append("grant_type", "authorization_code");
					formData.append("client_id", environment.AUTH_APPNAME);
					formData.append("code", route.queryParams["code"]);
					formData.append("redirect_uri", window.location.origin + (state.url.split('?')[0] || '/contacts'));
					formData.append("secret", environment.AUTH_SECRET);

					let request = new XMLHttpRequest();
					request.open("POST", environment.AUTH_URL + "/oauth/token");
					request.setRequestHeader("Authorization", "Basic " + btoa(environment.AUTH_APPNAME + ":" + environment.AUTH_SECRET));
					request.send(formData);
					request.onerror = (evt) => {
						this.messageService.showError("Erreur lors de la récupération du jeton de sécurité");
						AuthGuard.alerteDesactivation();
						observer.next(false);
						observer.complete();
						this.subjectChilds.next(false);
					};
					request.onload = (evt) => {
						let response = JSON.parse(request.response);

						// initialisation de la presence d'un token
						if (response.access_token) {
							let utilisateur = this.utilisateurService.mettreAJourUtilisateur(response);

							if (utilisateur && !utilisateur.cguAcceptees && state.url !== '/cgu') {
								this.router.navigate(['/cgu']);
								observer.next(false);
								this.subjectChilds.next(false);
							} else {
								this.router.navigateByUrl((state.url.split('?')[0] || ''));
								observer.next(true);
								this.subjectChilds.next(true);
							}
							observer.complete();
						} else if (response.error) {
							this.messageService.showError("Erreur lors de la récupération du jeton de sécurité");
							console.error("Erreur lors de la récupération du jeton de sécurité", response.error);
							this.storage.remove(LS_AUTHENTIFICATION_TOKEN);
							this.redirigerVersLogin(window.location.origin + (state.url.split('?')[0] || '/contacts'));
							observer.next(false);
							this.subjectChilds.next(false);
							observer.complete();
						}
					};
				});
			} else {
				// Authentification nécessaire, redirection vers la page de login
				this.storage.remove(LS_AUTHENTIFICATION_TOKEN);
				this.redirigerVersLogin(window.location.origin + (state.url.split('?')[0] || '/contacts'));
				return false;
			}
		}
	}

	canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
		return this.canActivate(route, state);
	}

	static isSessionValid(): boolean {
		let localStorageService = new LocalStorageService();
		let date: Date = localStorageService.load(LS_AUTHENTIFICATION_SESSION_DATE) as Date;
		let isValid = (!date || moment(new Date()).isBefore(moment(date).add(4, "hour")));
		// let isValid = (!date || moment(new Date()).isBefore(moment(date).add(30, "seconds")));
		if (isValid) {
			localStorageService.persist(new Date(), LS_AUTHENTIFICATION_SESSION_DATE);
		} else {
			AuthGuard.alerteSessionInvalide();
			localStorageService.remove(LS_AUTHENTIFICATION_TOKEN);
			localStorageService.remove(LS_AUTHENTIFICATION_SESSION_DATE);
		}
		return isValid;
	}

	redirigerVersLogin(currentUrl?: string) {
		this.router.navigate(['/redirect'], {queryParams: {origin: environment.AUTH_URL + this.urlAuth + currentUrl}});
	}

	static redirigerVersLogout() {
		window.location.href = environment.AUTH_URL + '/logout';
	}

	static alerteDesactivation() {
		jQuery('#deconnexionAlerte').modal({
			show: true,
			backdrop: 'static',
			keyboard: false
		});
	}

	static alerteSessionInvalide() {
		jQuery('#sessionInvalide').modal({
			show: true,
			backdrop: 'static',
			keyboard: false
		});
	}
}
