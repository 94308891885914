import { NgModule } from "@angular/core";
/* Directives */
import { AutofocusDirective } from "./autofocus.directive";
import { ClickBlurDirective } from "./click.blur.directive";
import { DynamicHostDirective } from "./dynamic.host.directive";
import { HasRoleDirective } from "./has.role.directive";
import { IconRisqueDirective } from "./icon.risque.directive";
import { BsDatepickerDirective } from 'ngx-bootstrap/datepicker/bs-datepicker.component';
import { ScrollDirective } from './scroll.directive';

@NgModule({
	declarations: [
		AutofocusDirective,
		ClickBlurDirective,
		DynamicHostDirective,
		HasRoleDirective,
		IconRisqueDirective,
		ScrollDirective
	],
	exports: [
		AutofocusDirective,
		ClickBlurDirective,
		DynamicHostDirective,
		HasRoleDirective,
		IconRisqueDirective,
		ScrollDirective
	]
})
/* Widget Module */
export class CustomDirectivesModule {
}
