import { MissingTranslationHandler, MissingTranslationHandlerParams } from '@ngx-translate/core';
import { environment } from "../environments/environment";

export class AppMissingTranslationHandler implements MissingTranslationHandler {
	handle(params: MissingTranslationHandlerParams) {
		if (environment.production)
			return params.key;
		else
			return "** " + params.key + " **";
	}
}
