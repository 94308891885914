<div [ngClass]="{'active': navbarOpen}"  class="mobile-navbar" (click)="closeNavbar($event)">
	<div class="mobile-navbar-content">
		<div class="row-eq-height">
			<sps-connected-user class="col-stretch" [theme]="'left'"></sps-connected-user>
			<div class="mobile-navbar-close btn" (click)="closeNavbar()">x</div>
		</div>
		<div *ngIf="isContactOuvert" class="navbar-contact-container">
			<div class="row-eq-height">
				<h3>Contact ouvert</h3>
				<bouton-liste-contacts (onClick)="toggleListeContacts()" [state]="contactsOuvertComponent?.bandeauContactOuvert"></bouton-liste-contacts>
				<bouton-fermer-contact (click)="closeNavbar()"></bouton-fermer-contact>
			</div>
			<sps-contacts-ouvert></sps-contacts-ouvert>
		</div>
		<ul class="mobile-navbar-menu">
			<li [routerLinkActive]="['active']">
				<a id="lien-contacts" [routerLink]="['/contacts']" (click)="closeNavbar()">Contacts</a>
				<div class="menu-submenu" *ngIf="isContactOuvert">
					<menu-contact (click)="closeNavbar()"></menu-contact>
				</div>
			</li>
			<li [routerLinkActive]="['active']">
				<a id="lien-evenements" [routerLink]="['/evenements']" (click)="closeNavbar()">Evenements</a>
			</li>
		</ul>
	</div>
</div>