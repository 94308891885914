import { ResourceParams } from "@ngx-resource/core";
import { Injectable } from "@angular/core";
import { PaginateResource } from "../sps-resource";


@Injectable({
  providedIn: 'root'
})
@ResourceParams({
	// Api path
	path: 'utilisateurs/{id}'
})
export class UtilisateurResource extends PaginateResource<any> {
}
