/**
 * Created by "Juanito Goncalves <juanito.goncalves@scub.net>" on 16/07/16.
 */
import { Component } from "@angular/core";
import { Router } from "@angular/router";


@Component({
	selector: 'error403',
	templateUrl: './403.template.html',
	styleUrls: ['./errors.style.less'],
})
export class Error403Component  {

	constructor(private router: Router) {

	}

	retourAccueil() {
		this.router.navigate(['/']);
	}
}
