<div class="full-modal cgu-container on" id="cgu-modal">
	<div class="modal-title">
		<div class="title">Conditions générales d’utilisation</div>
		<div class="back-button">
            <span class="btn-icon-circle btn-circle-md" title="Fermer" (click)="fermerModal()">
                <i class="icon-delete"></i>
            </span>
		</div>
	</div>
	<div class="modal-body"
		 [formGroup]="cguForm"
		 [class.ng-submited]="cguSubmitted">
		<div class="row">
			<div class="col-md-6 col-md-offset-3">
				<div class="text-center page-intro">
					Bonjour <span *ngIf="utilisateur?.prenom">{{utilisateur?.prenom}}</span>
					<span *ngIf="utilisateur?.nom"> {{utilisateur?.nom}}</span>
					<span *ngIf="!utilisateur?.prenom && utilisateur?.nom && utilisateur?.login">{{utilisateur?.login}}</span>,
				</div>
				<div class="text-center cgu-intro">
					Vous vous apprêtez à accéder à votre espace SPS.
					Lisez le règlement dans son ensemble, acceptez les conditions puis cliquez sur <span>VALIDER</span>.
				</div>
				<div class="form-group">
					<div id="cgu-content"
						 class="cgu-content min-height-xl"
						 [innerHTML]="cguForm.get('content')?.value | safeHtml"
						 *ngIf="!chargementCGU"
						 (scroll)="onTextAreaToBottom()">
					</div>
					<sps-loader [message]="'Chargement des Conditions générales d\'utilisation'"
								*ngIf="chargementCGU">
					</sps-loader>
				</div>
				<label for="cgu-read">
					<input type="checkbox" id="cgu-read" name="cgu-read" formControlName="read"/> {{'CGU.READ' |
					translate}}
				</label>
				<label for="cgu-accepted">
					<input type="checkbox" id="cgu-accepted" name="cgu-accepted" formControlName="accepted"/>
					{{'CGU.ACCEPTED' | translate}}
				</label>

				<p class="error-box"
				   *ngIf="((cguForm.get('read').disabled || cguForm.get('read').errors?.required) && cguForm.get('accepted').errors?.required) && cguSubmitted">
					{{ 'CGU.VALIDATION.BOTH' | translate }}
				</p>
				<p class="error-box"
				   *ngIf="(cguForm.get('read').enabled && cguForm.get('accepted').errors?.required && !cguForm.get('read').errors?.required) && cguSubmitted">
					{{ 'CGU.VALIDATION.ACCEPTED' | translate }}
				</p>
				<p class="error-box"
				   *ngIf="((cguForm.get('read').disabled || cguForm.get('read').errors?.required) && !cguForm.get('accepted').errors?.required) && cguSubmitted">
					{{ 'CGU.VALIDATION.READ' | translate }}
				</p>

				<div class="btn btn-success sm centered"
					 (click)="validerCGU()">
					<span>valider</span>
				</div>
			</div>
		</div>
	</div>
</div>