import { Component, Input, SimpleChanges, ViewEncapsulation } from "@angular/core";


@Component({
	selector: 'sps-serveur-erreur',
	encapsulation: ViewEncapsulation.None,
	templateUrl: 'serveur.erreur.template.html'
})
export class ServeurErreur  {


	@Input("erreur-serveur") erreurs: Array<any> = null;
	@Input("afficher-exception") afficherException: boolean = false;

	serveurErrors: any;

	constructor() {

	}

	ngOnChanges(changes: SimpleChanges) {
		if (changes && changes.erreurs) {
			this.construireListe();
		}
	}

	ngOnInit() {
		this.construireListe();
	}

	construireListe() {
		if (this.erreurs && this.erreurs.length) {
			try {
				this.serveurErrors = [];
				this.erreurs.forEach(erreur => {
					if (erreur && erreur.body) {
						let erreurParse = erreur.body;

						if (erreurParse && erreurParse.errors && erreurParse.errors.length) {
							this.serveurErrors = this.serveurErrors.concat(erreurParse.errors.map(error => error.defaultMessage));
						} else if (erreurParse && erreurParse.message && this.afficherException) {
							let match = erreurParse.message && (<string>erreurParse.message).split(new RegExp(erreurParse.status + " "));
							this.serveurErrors.push(match && [match[match.length - 1]]);
						}
					}
				});
			} catch (e) {
				this.serveurErrors = null;
			}
		} else {
			this.serveurErrors = null;
		}
	}


	ngOnDestroy() {
	}
}
