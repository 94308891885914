import { Injectable, isDevMode } from "@angular/core";
import { LocalStorageService, LS_AUTHENTIFICATION_TOKEN } from "./LocalStorage.service";
import { AuthentificationModel } from "../models/authentification.model";
import { environment } from "../../environments/environment";
import { RxStompService } from '@stomp/ng2-stompjs';
import { Observable, Subject, Subscription } from 'rxjs';
import { IMessage } from '@stomp/stompjs';
import { RxStompState } from '@stomp/rx-stomp/esm6/rx-stomp-state';
import { myRxStompConfig } from '../components/contacts/commun/telephonie/telephonie.config';

/**
 * Service permettant de gérer les websockets (connexion, envoi et réception de messages).
 * Created by "Julie Jageneau <julie.jageneau@scub.net>" on 21/02/17.
 */
@Injectable({
  providedIn: 'root'
})
export class StompService {

	private subscribedRoutes: Array<Observable<IMessage>> = [];
	private rxStompService: RxStompService;

	constructor(private storage: LocalStorageService) {
		this.rxStompService = new RxStompService();
	}

	public connect(parametres: {active: boolean}): Observable<RxStompState> {

		let auth: AuthentificationModel = this.storage.load(LS_AUTHENTIFICATION_TOKEN) as AuthentificationModel;
		if (auth != null) {

			myRxStompConfig.brokerURL = environment.TELEPHONIE_URL + ("?access_token=" + auth.access_token)
			myRxStompConfig.connectHeaders = {"Authorization": auth.access_token};

			//si on est en live realod, on évite de faire les reconnexion au CTI qui est down 99% du temps
			if (isDevMode())
				myRxStompConfig.reconnectDelay = 0;

			this.rxStompService.configure(myRxStompConfig);
			this.rxStompService.activate();
		}
		return this.rxStompService.connected$;
	}

	public isConnected(){
		return this.rxStompService.connected();
	}

	public disconnect(): Observable<boolean> {
		let subject = new Subject<boolean>();
		if (this.rxStompService && this.rxStompService.active) {



			this.rxStompService.deactivate()
				.then(
					s => {subject.next(true);},
					s => {subject.next(false);}
				)
			;
		}
		setTimeout(() => {
			subject.next(true)
		}, 0);

		return subject.asObservable();
	}

	/*public tentativeConnexion(url: string, callback: any, errorCallback: any) {
		console.debug("stomp.service : demande de reconnexion n°" + this.nbTentativesConnexion);
		if (this.nbTentativesConnexion <= environment.NB_TENTATIVES_RECONNEXION) {
			this.nbTentativesConnexion = this.nbTentativesConnexion + 1;
			this.delaisReconnexion = this.delaisReconnexion * (this.nbTentativesConnexion - 1);
			setTimeout(() => {
				this.connect(url, callback, errorCallback);
			}, this.delaisReconnexion);
		}
	}*/

	public watch(destination: string): Observable<IMessage> {
		let subscription: Observable<IMessage> = this.rxStompService.watch(destination);
		this.subscribedRoutes.push(subscription);
		return subscription;
	}

	public send(destination: string, message: string) {
		let storage = new LocalStorageService();
		let auth: AuthentificationModel = storage.load(LS_AUTHENTIFICATION_TOKEN) as AuthentificationModel;

		this.rxStompService.publish({
			destination: destination,
			body: JSON.stringify({numeroTelephone: message}),
			headers: {"Authorization": auth.access_token}
		});
	}

}
