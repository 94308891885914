import {Component, EventEmitter, forwardRef, Input, Output, TemplateRef} from "@angular/core";
import {Observable} from "rxjs";
import {AbstractControl, ControlValueAccessor, NG_VALIDATORS, NG_VALUE_ACCESSOR, Validator} from "@angular/forms";
import {TranslateService} from "@ngx-translate/core";
import {ResourceCRUDBase} from "../../../../resources/sps-resource";
import {Utils} from "../../../../utils/Utils";
import {map, mergeMap} from 'rxjs/operators';


@Component({
	selector: 'sps-res-suggest',  // <sps-res-suggest></sps-res-suggest>
	templateUrl: './suggest.template.html',
	styleUrls: ['./suggest.style.less'],
	providers: [
		{provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => SuggestComponent), multi: true},
		{provide: NG_VALIDATORS, useExisting: SuggestComponent, multi: true}
	]
})
export class SuggestComponent  implements ControlValueAccessor, Validator {
	private initEnCours: boolean;

	@Input() readonly: boolean;
	@Input('valeur-vide') valeurVide: boolean = true;
	@Input('valeur-autre') valeurAutre: boolean = false;
	@Input('valeur-complementaire') valeurComplementaire: Array<any>;
	@Input() name: string;
	@Input() id: string;
	// NOTE: le composant ngx-bootstrap/typeahead ne gère pas l'infinité d'éléments, il faut une limite fixe
	@Input() limit: number = 1000000;
	@Input() minLength: number = 0;
	// Possibilié d'injecter un result transformer pour controler la forme des données affichées.
	@Input() resultTransformer: Function;
	@Input('tri-alpha') triAlpha: boolean = false;

	/**
	 * Nom de la propriété pour grouper les résultats.
	 * Va rassembler les résultats en catégorie en rajoutant des éléments non sélectionnables
	 * @see https://www.w3schools.com/tags/tag_optgroup.asp
	 * Lié à la propriété typeaheadGroupField
	 */
	@Input('grouper-par') grouperPar: string;
	/**
	 * Stocke le nom de la propriété servant au regroupement
	 * Ou devient une nouvelle propriété si la valeur de la propriété doit être traduite
	 */
	public grouperParEffectif: string;
	/**
	 * Racine de traduction de la valeur de la propriété grouper-par.
	 */
	@Input('racine-traduction') racineTraduction: string;

	@Input() required: any;
	@Input() placeholder: string = "Commencez une saisie pour voir les propositions";
	/**
	 * SI l'ajout est autorisé, la valeur retournée aura un id = null
	 */
	@Input('autoriser-ajout') autoriserAjout: boolean = false;
	disabled: boolean = false;
	nomLabel = 'libelle';
	@Input() filtreValeurs: Array<any>;

	/**
	 * Ressource utilisée
	 */
	@Input() dataSource: ResourceCRUDBase<any, any, any, any>;
	/**
	 * Permet de passer des params supplémentaire à la dataSource
	 */
	@Input() resourceObject: any;

	@Input() customItemTemplate: TemplateRef<any>;
	@Input() customInputClass: string;

	@Output() onBlur: EventEmitter<any> = new EventEmitter();
	@Output() onSelectionChange: EventEmitter<any> = new EventEmitter();

	private innerValue: any;
	currentLabel: string;
	private rechercheArretee: boolean = false;
	private stringValue = 'string';

	// For internal purposes only
	dataSourceInternal: Observable<any>;

	uniqId: number = Math.floor(Math.random() * 100000);

	constructor(private translate: TranslateService) {


	}

	ngOnInit() {
		if (this.grouperPar) {
			this.grouperParEffectif = this.grouperPar;
			if (this.racineTraduction) {
				this.grouperParEffectif += "Traduction";
			}
		}
		this.dataSourceInternal = Observable.create((observer: any) => {
			// Runs on every search
			observer.next(this.currentLabel);
		}).pipe(mergeMap((token: string) => {

			if (!token) token = '';
			let queryData;
			if (token == null) token = '';
			if (this.resourceObject == null)
				queryData = {q: token};
			else {
				queryData = this.resourceObject;
				queryData.q = token;
			}
			if (!this.rechercheArretee) {
				if (this.resultTransformer) {
					this.nomLabel = 'libelleAffiche';
					return this.dataSource.query(queryData).pipe(map((res: Array<any>) => {
						for (let item of res) {
							(<any>item).libelleAffiche = this.resultTransformer(item);
							// Si les résultats sont groupés et qu'on souhaite traduire la valeur de la propriété du groupe.
							if (item && this.grouperPar) {
								item[this.grouperParEffectif] = this.translate.instant((this.racineTraduction ? this.racineTraduction + "." + item[this.grouperPar] : item[this.grouperPar]));
							}
						}
						if (this.valeurVide) {
							this.ajouterValeurVide(res);
						}
						if (this.valeurAutre) {
							this.ajouterValeurAutre(res);
						}
						if (this.filtreValeurs) {
							console.debug("Filtre les valeurs de la liste", this.filtreValeurs);
							res = res.filter(valeur => {
								return !this.filtreValeurs.some(filtre => {
									return !!filtre && (filtre.id == valeur.id || filtre[this.nomLabel] == valeur[this.nomLabel]);
								})
							});
						}
						if (this.valeurComplementaire && this.valeurComplementaire.length) {
							console.debug("VALEUR COMPELEMENTAIRES !!! => ", this.valeurComplementaire);
							this.valeurComplementaire.forEach(valeurComplementaire => {
								if (valeurComplementaire) {
									valeurComplementaire.libelleAffiche = this.resultTransformer(valeurComplementaire);
									res.splice(this.limit, 0, valeurComplementaire);
								}
							});
						}
						if (this.triAlpha) {
							res.sort((a, b) => {
								return (a.libelleAffiche > b.libelleAffiche ? 1 : -1);
							});
						}
						return res;
					}));
				} else {
					return this.dataSource.query(queryData).pipe(map((res: Array<any>) => {
						for (let item of res) {
							// Si les résultats sont groupés et qu'on souhaite traduire la valeur de la propriété du groupe.
							if (item && this.grouperPar) {
								item[this.grouperParEffectif] = this.translate.instant((this.racineTraduction ? this.racineTraduction + "." + item[this.grouperPar] : item[this.grouperPar]));
							}
						}
						if (this.valeurVide) {
							this.ajouterValeurVide(res);
						}
						if (this.valeurAutre) {
							this.ajouterValeurAutre(res);
						}
						if (this.filtreValeurs) {
							console.debug("Filtre les valeurs de la liste", this.filtreValeurs);
							res = res.filter(valeur => {
								return !this.filtreValeurs.some(filtre => {
									return !!filtre && (filtre.id == valeur.id || filtre.libelle == valeur.libelle);
								})
							});
						}
						if (this.valeurComplementaire && this.valeurComplementaire.length) {
							console.debug("VALEUR COMPELEMENTAIRES !!! => ", this.valeurComplementaire);
							this.valeurComplementaire.forEach(valeurComplementaire => {
								if (valeurComplementaire) {
									res.splice(this.limit, 0, valeurComplementaire);
								}
							});
						}
						if (this.triAlpha) {
							res.sort((a, b) => {
								return (a.libelle > b.libelle ? 1 : -1);
							});
						}
						return res;
					}));
				}
			} else {
				return Observable.create((obs) => {
					setTimeout(() => {
						obs.next([]);
					}, 0);
					return obs;
				});
			}
		}));
	}

	ajouterValeurVide(res: Array<any>) {
		let defaultResult = {
			libelle: "",
			libelleAffiche: ""
		};
		res.unshift(defaultResult);
	}

	ajouterValeurAutre(res: Array<any>) {
		let defaultResult = {
			libelle: "Autre",
			libelleAffiche: "Autre"
		};
		res.push(defaultResult);
	}

	afficherListeComplete() {
		this.currentLabel = '';
		this.focus();
	}

	ngOnDestroy() {
	}

	loading(e: boolean) {
		this.rechercheArretee = false;
	}

	noResult(noResult: boolean) {
	}

	select(e: any) {
		this.selectedLabel = Utils.sanitize(e.item);
	}


	// Setter pour la prise en charge de ngModel
	set selectedLabel(value: any) {
		if (value) {
			if (!Utils.isUndefined(value.id)) {
				// On a choisi un element de la liste
				this.innerValue = value;
				this.currentLabel = value.libelle;
				if (!this.initEnCours) this.onChangeCallback(this.innerValue);
			} else if (value.libelle == "") {
				// On a vidé la saisie
				this.currentLabel = value.libelle;
				if (!this.initEnCours) this.onChangeCallback(this.innerValue);
			} else if (Utils.isString(value)) {
				// La valeur saisie n'existe pas dans le référentiel
				this.currentLabel = value;
				this.innerValue = {
					id: null,
					libelle: value
				};
				if (this.resultTransformer) this.innerValue.libelleAffiche = this.resultTransformer(this.innerValue);
			} else if (Utils.isUndefined(value.id) && value.libelle != "") {
				this.currentLabel = value.libelle;
				value.id = null;
				this.innerValue = value;
				if (!this.initEnCours) this.onChangeCallback(this.innerValue);
			} else {
				console.warn("value (" + JSON.stringify(value) + ") cannot be affected to this.currentLabel in SLB " + (this.id || this.uniqId));
			}
		} else {
			this.reset();
		}
	}

	gererClavier() {
		if (this.autoriserAjout) {
			this.onChangeCallback({id: null, libelle: this.currentLabel});
		} else {
			// On envoie une valeur nulle seulement si l'événement clavier a entraîné une désélection de la valeur précédente
			// (ex : un CTRL + C ne doit pas déclencher le onChangeCallback)
			if (!this.initEnCours && !this.innerValue?.id) {
				this.onChangeCallback(null);
			}
		}
	}


	private reset() {
//Reset
		let change: boolean = !!(this.innerValue && this.innerValue.id) || this.autoriserAjout;
		delete this.innerValue;
		delete this.currentLabel;
		if (change && !this.initEnCours) {
			this.onChangeCallback(null);
		}
	}

// Getter
	get selectedLabel(): any {
		if (this.innerValue) {
			return this.resultTransformer ? this.innerValue.libelleAffiche : this.innerValue.libelle;
		} else {
			return null;
		}
	}


	//Placeholders for the callbacks which are later providesd
	//by the Control Value Accessor
	private onTouchedCallback: () => void = () => {
	};
	private onChangeCallback: (_: any) => void = () => {
	};

	writeValue(value: any): void {
		this.initEnCours = true;
		if (value && typeof value != this.stringValue && this.resultTransformer) {
			value.libelleAffiche = this.resultTransformer(value);
		}
		this.selectedLabel = value;
		this.initEnCours = false;
	}

	setDisabledState(isDisabled: boolean) {
		this.disabled = isDisabled;
	}

	registerOnChange(fn: (_: any) => void): void {
		this.onChangeCallback = fn;
	}

	registerOnTouched(fn: () => void): void {
		this.onTouchedCallback = fn;
	}

	focus() {
		jQuery("#suggest_" + this.uniqId).focus();
	}

	validate(c: AbstractControl): { [key: string]: any } {
		if (this.autoriserAjout) return null;
		if (this.currentLabel && !this.innerValue)
			return {"selectInvalid": true};
		if (this.currentLabel && this.innerValue && this.innerValue.libelle != this.currentLabel)
			return {"selectInvalid": true};
		return null;
	}

	arreterRecherche() {
		this.rechercheArretee = true;
	}

	notifierPerteFocus() {
		this.onBlur.emit(this.innerValue);
	}

	notifierModificationSelection() {
		this.onSelectionChange.emit(this.innerValue);
	}
}
