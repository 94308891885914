/**
 * Confirm popup projet component.
 * Created by Adrien BORDERON (adrien.borderon@scub.net) on 04/01/17.
 */
import { Component } from "@angular/core";
import { Observable, Subject } from "rxjs";
import { ConfirmPopupModel } from "../../../models/confirm.popup.model";
import { ConfirmPopupService } from "../../../services/confirm.popup.service";

@Component({
	selector: 'sps-confirm-popup',
	templateUrl: './confirm.popup.template.html'
})
export class ConfirmPopupComponent  {

	confirmPopupModel: ConfirmPopupModel;
	modalId: string;
	modalTitle: string;
	modalTitleTwoLines: boolean = false;
	modalContent: string;
	modalDetails = [];
	hasConfirmBtn: boolean;
	modalConfirmBtn: string;
	modalConfirmBtnClass: string = "success";
	hasCancelBtn: boolean = false;
	modalCancelBtn: string;
	modalCancelBtnClass: string = "danger";

	private subject: Subject<boolean>;
	private showObs: Observable<boolean>;

	constructor(private confirmPopupService: ConfirmPopupService) {

		this.subject = this.confirmPopupService.getSubject();
		if (!this.subject) {
			this.subject = new Subject<boolean>();
			this.confirmPopupService.setSubject(this.subject);
		}
		this.showObs = this.subject.asObservable();
		this.showObs.subscribe((show) => {
			if (show) {
				this.confirmPopupModel = this.confirmPopupService.confirmPopupModel;
				this.mappingModal();
				jQuery("#sps-confirm-modal").modal('show');
			} else {
				jQuery("#sps-confirm-modal").modal('hide');
			}
		});
	}

	ngOnInit() {
	}

	onConfirmClick() {
		this.confirmPopupService.onConfirm(this.confirmPopupModel.onConfirmCallback);
		this.subject.next(false);
	}

	onCancelClick() {
		this.confirmPopupService.onCancel(this.confirmPopupModel.onCancelCallback);
		this.subject.next(false);
	}

	private mappingModal() {
		this.modalId = this.confirmPopupModel.modalId;
		this.modalTitle = this.confirmPopupModel.modalTitle;
		this.modalTitleTwoLines = this.confirmPopupModel.modalTitleTwoLines;
		this.modalContent = this.confirmPopupModel.modalContent;
		this.modalDetails = this.confirmPopupModel.modalDetails;
		this.hasConfirmBtn = this.confirmPopupModel.hasConfirmBtn;
		this.modalConfirmBtn = this.confirmPopupModel.modalConfirmBtn;
		this.modalConfirmBtnClass = this.confirmPopupModel.modalConfirmBtnClass;
		this.hasCancelBtn = !!(this.confirmPopupModel.hasCancelBtn || this.confirmPopupModel.modalCancelBtn);
		this.modalCancelBtn = this.confirmPopupModel.modalCancelBtn;
		this.modalCancelBtnClass = this.confirmPopupModel.modalCancelBtnClass;
	}
}
