import { Routes } from "@angular/router";
import { ReferentielComponent } from "./referentiel.component";
import { ReferentielAccordSanteEntrepriseComponent } from "./accordsante/referentiel.accordsante.entreprise.component";
import { AuthGuard } from "../../guards/auth.guard";

export const referentielsRoutes: Routes = <Routes>[
	{
		path: 'referentiels',
		component: ReferentielComponent,
		canActivate: [AuthGuard],
		canActivateChild: [AuthGuard],
		children: [
			{
				path: 'accordsantes/:idcc',
				component: ReferentielAccordSanteEntrepriseComponent,
				pathMatch: 'full'
			}
		]
	}
];
