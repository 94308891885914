/**
 * CourtierOriasResponse.
 */
export enum CourtierOriasResponse {
	/**
	 * OK.
	 */
	OK = 'OK',

	/**
	 * DEJA_A_JOUR.
	 */
	DEJA_A_JOUR = 'DEJA_A_JOUR',

	/**
	 * ERREUR.
	 */
	ERREUR = 'ERREUR'
}
