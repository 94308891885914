/* External decorators & services */
import { Component } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { concat, defer, Observable } from "rxjs";
/* Components */
/* Services */
import {
	FullScreenModalService,
	ModalServiceSubscribeModel
} from "../../services/modal.fullscreen.service";
import {
	LocalStorageService,
	LS_INTEGRATION_CLIENT_LISTE,
	LS_INTEGRATION_CLIENT_SELECTION
} from "../../services/LocalStorage.service";
import { GlobalMessageService } from "../../services/global.message.service";
import { ConfirmPopupService } from "../../services/confirm.popup.service";
/* Models */
import { AdresseModel } from "../../models/commun/contacts/adresse.model";
import { ContactModel } from "../../models/commun/contacts/contact.model";
import { ConfirmPopupModel } from "../../models/confirm.popup.model";
import { PaginateResponseModel } from "../../models/paginate.response.model";
/* Resources */
import { ContratResource } from "../../resources/contrats/contrat.resource";
import { ContactPaginateResource } from "../../resources/contact.paginate.resource";
import { ContactIntegrationResource } from "../../resources/contact/contact.integration.ressource";
import { ContratModel } from "../../models/particulier/contrats/contrat.model";
import { ProduitContrat } from "../../models/particulier/contrats/contrat.produit.model";
import { map } from 'rxjs/operators';

@Component({
	selector: 'contact-integration-full-modale',
	templateUrl: './contact.integration.template.html',
	styleUrls: ['./contact.integration.style.less']
})
export class ContactIntegrationComponent  {

	nbTotalElement: number;pageCourante: number = 1;
	taillePage: number = 20;

	contacts: Array<ContactModel> = [];
	selectionContacts: Array<ContactModel> = [];
	isCheckedAllSelection: boolean = false;

	contactsLoading: boolean = false;
	integrationLoading: boolean = false;
	integrationDesactivee: boolean = true;

	constructor(private modalService: FullScreenModalService,
				private storage: LocalStorageService,
				private messenger: GlobalMessageService,
				public translate: TranslateService,
				private confirmPopup: ConfirmPopupService,
				private contactIntegrationResource: ContactIntegrationResource,
				private contactPaginateResource: ContactPaginateResource,
				private contratResource: ContratResource) {

	}

	ngOnInit() {
		this.modalService.fullModalObserver.subscribe(({modal, open}: ModalServiceSubscribeModel) => {
			if (modal === 'contact-integration-full-modale' && open) {
				this.getContacts();
			}
		});
		this.modalService.fullModalEscapeObserver.subscribe(modal => {
			if (modal === 'contact-integration-full-modale') {
				this.closeIntegration();
			}
		});
	}

	ngOnDestroy() {
	}

	getContacts() {
		const criteres = {
			page: this.pageCourante,
			parPage: this.taillePage
		};
		this.contactsLoading = true;
		this.contacts.length = 0;
			this.contactIntegrationResource.query(criteres).subscribe(
				({data, count}: PaginateResponseModel<ContactModel>) => {
				this.nbTotalElement = count;
				this.storage.persist(data, LS_INTEGRATION_CLIENT_LISTE);
				this.completerContacts(data);
			},
			erreur => console.error('ContactIntegrationComponent', erreur)
		);
	}

	completerContacts(contacts: Array<ContactModel>) {
		let contrats = [];

		const requetes: Array<Observable<any>> = contacts.map(contact =>
			defer(() => this.contratResource.query({
				idPersonne: contact.id,
				typeContact: ContactModel.getContactType(contact),
				complet: true
			}))
		);

		concat(
			...requetes
		).pipe(map(
			(value: Array<ContratModel>, index: number) => ({data: value, index: index})
		)).subscribe(
			({data, index}) => {
				if (!data.length) {
					contrats[index] = null;
					return;
				}
				const contrat: ContratModel = data[0];
				const g: ProduitContrat = contrat.produits && contrat.produits.find(p => p.garantiePrincipale);
				contrats[index] = {
					// garantie: 'Optimum 450', // TODO: appel webservice (back)
					...contrat,
					garantie: g && g.produit && g.produit.libelle
				};
			},
			(error) => this.messenger.showError(this.translate.instant('CONTRAT.ERREUR.LISTE')),
			() => {
				this.contacts = contacts.map((contact, i) => ((contact as any).contrat = contrats[i], contact));
				if (this.isCheckedAllSelection) this.changeAllSelection(true);
				else this.selectionContacts = this.storage.load(LS_INTEGRATION_CLIENT_SELECTION) || [];
				this.contactsLoading = false;
			}
		);
	}

	integrerContact() {
		if (this.integrationDesactivee || this.integrationLoading) return;

		if (!this.selectionContacts.length) {
			this.messenger.showError(this.translate.instant('INTEGRATION.ERREUR.SELECTION_VIDE'));
			return;
		}

		this.integrationLoading = true;

		const idsPersonnes = this.isCheckedAllSelection ? [] : this.selectionContacts.map(v => v.id);

		this.contactIntegrationResource.update(idsPersonnes).subscribe(
			() => {
				this.messenger.showSuccess(this.translate.instant('INTEGRATION.SUCCES.INTEGRE', {
					nombre: this.isCheckedAllSelection ? this.nbTotalElement : this.selectionContacts.length
				}));
				// Recupération des contacts non integrés
				this.contacts = this.contacts.filter((c) => !this.isCheckedSelection(c.id));
				this.storage.persist(this.contacts, LS_INTEGRATION_CLIENT_LISTE);
				this.pageCourante = 1;
				this.integrationLoading = false;
				this.routerBack();
				// Effacement liste de selection & CàCs
				this.changeAllSelection(false);
				this.integrationDesactivee = true;
			},
			() => {
				this.messenger.showError(this.translate.instant('INTEGRATION.ERREUR.INTEGRE'));
				this.integrationLoading = false;
			}
		);
	}

	closeIntegration() {
		this.confirmPopup.popup({
			... new ConfirmPopupModel(),

			modalTitle: this.translate.instant('MODAL.DEFAULT_ALT.TITLE'),
			modalContent: this.translate.instant('MODAL.DEFAULT_ALT.CONTENT'),
			modalConfirmBtn: this.translate.instant('MODAL.DEFAULT_ALT.CONFIRM_BTN'),

			onConfirmCallback: () => {
				this.integrationDesactivee = !this.selectionContacts.length;
				this.routerBack();
			}
		} as ConfirmPopupModel);
	}

	//<editor-fold desc="Section Selection contacts">

	/**
	 * Lors de la (de)coche de la case de selection, on ajoute/supprime le contact dans la liste.
	 *
	 * @param checked - valeur de la case.
	 * @param contact - contact correspondant.
	 */
	changeSelectionContacts(checked: boolean, contact: any) {
		if (this.integrationDesactivee) this.integrationDesactivee = false;

		if (checked) this.selectionContacts.push(contact);
		else {
			this.isCheckedAllSelection = false;
			this.selectionContacts.splice(this.selectionContacts.findIndex(c => c.id === contact.id), 1);
		}
		this.storage.persist(this.selectionContacts, LS_INTEGRATION_CLIENT_SELECTION);
	}

	/**
	 * Lors de la (de)coche de la case de selection de tout les contacts, on rempli/vide la liste de selection.
	 *
	 * @param checked - valeur de la case.
	 */
	changeAllSelection(checked: boolean) {
		if (this.integrationDesactivee) this.integrationDesactivee = false;

		// SI coché ALORS on ajoute tous les contacts de la page et on filtre pour garder une selection unique
		if (checked) this.selectionContacts = this.contacts.map(c => c);
		// SINON on vide la selection (possible fix: ne supprimer que les contacts de la page)
		else this.selectionContacts.length = 0;
		this.checkAllSelection();
		this.storage.persist(this.selectionContacts, LS_INTEGRATION_CLIENT_SELECTION);
	}

	/**
	 * Vérifie si un contact et selectionné.
	 *
	 * @param {number} id - id du contact à vérifier
	 * @returns {boolean}
	 */
	isCheckedSelection(id: number): boolean {
		const selection = this.selectionContacts;

		for (let i = 0, ls = selection.length; i < ls; i++) {
			if (selection[i].id === id) return true;
		}
		return false;
	}

	/**
	 * Vérifie si tous les contacts de la page sont sélectionnés.
	 *
	 * @returns {boolean}
	 */
	checkAllSelection(): boolean {
		const contacts = this.contacts;
		const selection = this.selectionContacts;
		const lenContacts = contacts.length;
		const lenSelection = selection.length;

		// SI aucun contact OU si moins de contact selectionnés que de contact sur la page
		if (lenContacts == 0 || lenSelection < lenContacts) {
			return (this.isCheckedAllSelection = false);
		}

		let found = false;
		// Itération sur les contacts de la page
		for (let i = 0; i < lenContacts; i++) {
			found = false;
			// Itération sur la selection de contacts
			for (let j = 0; j < lenSelection; j++) {
				// SI le contact est trouvé dans la selection
				if (selection[j].id === contacts[i].id) {
					found = true;
					break;
				}
			}
			// SI le contact n'est pas trouvé dans la selection
			if (!found) {
				return (this.isCheckedAllSelection = false);
			}
		}
		return (this.isCheckedAllSelection = true);
	}

	//</editor-fold> Section Selection contacts

	routerBack() {
		this.modalService.closeFullModal('contact-integration-full-modale', {count: this.isCheckedAllSelection ? this.nbTotalElement : this.selectionContacts.length});
	}

	changePage(event) {
		this.pageCourante = event;
		this.getContacts();
	}

	formaterAdresse(adresse) {
		return AdresseModel.getAdresseFormatee(adresse, '<br/>');
	}
}
