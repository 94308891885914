import { ResourceParams } from "@ngx-resource/core";
import { Injectable } from "@angular/core";
import { SPSResource } from "../sps-resource";


@Injectable({
  providedIn: 'root'
})
@ResourceParams({
	// Api path
	path: 'utilisateurs/mot-passe'
})
export class MdpUtilisateurResource extends SPSResource<any, any, any, any> {
}
