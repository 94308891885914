/**
 * Created by mcalvez on 07/06/17.
 */
/* External decorators & services */
import { Component } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
/* App decorators & services */
import { AppState } from "../../app.service";
import { UtilisateurService } from "../../services/utilisateur.service";
import { GlobalMessageService } from "../../services/global.message.service";
import { UtilisateurConnecte } from "../../models/utilisateur/utilisateur.model";
import { CguResource } from "../../resources/utilisateur/cgu.resource";
import { HttpClient } from "@angular/common/http";

@Component({
	selector: 'cgu-component',
	templateUrl: './cgu.template.html',
	styleUrls: ['./cgu.style.less']
})
export class CguComponent  {

	public utilisateur: UtilisateurConnecte;
	public cguForm: FormGroup;
	public cguSubmitted: boolean;
	public chargementCGU: boolean;

	constructor(private utilisateurService: UtilisateurService,
				private fb: FormBuilder,
				private cguResource: CguResource,
				private translateService: TranslateService,
				private messageService: GlobalMessageService,
				private http: HttpClient,
				private router: Router,
				private appState: AppState) {



	}

	public ngOnInit() {
		this.utilisateur = this.utilisateurService.getUtilisateurConnecte();
		this.initCguForm();
		this.recupererContenuCGUs();
	}

	public ngOnDestroy() {
		this.cguSubmitted = false;
	}

	public validerCGU() {
		this.cguSubmitted = true;
		if (this.cguForm.get('read').enabled && this.cguForm.valid) {
			this.cguResource.save().subscribe(
				() => {
					let subscription = this.utilisateurService.refreshToken("contacts", true).subscribe(() => {
						this.quitterPage();
						subscription.unsubscribe();
					});

				},
				() => {
					this.messageService.showError(this.translateService.instant('CGU.ERREUR'));
					this.fermerModal();
				}
			);
		}
	}

	public fermerModal() {
		this.appState.deconnecter();
	}

	private quitterPage() {
		this.router.navigate(['contacts']);
	}

	private initCguForm() {
		this.cguForm = this.fb.group({
			content: [null, Validators.required],
			read: [{value: false, disabled: true}, Validators.requiredTrue],
			accepted: [null, Validators.requiredTrue]
		});

		this.cguForm.valueChanges.subscribe(() => {
			this.cguSubmitted = false;
		});
	}

	public onTextAreaToBottom() {
		let cguContent = jQuery('#cgu-content');
		let scrollHeight: number = cguContent[0].scrollHeight;
		let innerHeight: number = cguContent.innerHeight();
		let scrollTop: number = cguContent.scrollTop();

		//On retire un pixel pour assurer le calcul.
		if (scrollTop + innerHeight >= scrollHeight - 1) {
			this.cguForm.get('read').enable();
			// NOTE: Demande Mantis 1677, La CAC ne doît pas être cochée automatiquement (remplace demande Mantis 1386)
			// this.cguForm.get('read').setValue(true);
		}
		this.cguSubmitted = false;
	}

	private recupererContenuCGUs() {
		this.chargementCGU = true;
		let cguPath: string = 'assets/cgu/' + this.translateService.currentLang + '.html';
		this.http.get(cguPath, {responseType: 'text'}).subscribe(
			(response: string) => {
				this.cguForm.get('content').setValue(response);
				this.chargementCGU = false;
				setTimeout(() => {
					this.onTextAreaToBottom();
					this.fixCguText();
				}, 0);
			},
			() => {
				this.http.get('assets/cgu/fr-FR.html', {responseType: 'text'}).subscribe((frResponse: string) => {
					this.chargementCGU = false;
					this.cguForm.get('content').setValue(frResponse);
				});
			}
		);
	}

	private fixCguText() {
		/*
			Au téléchargement depuis Google Drive, tous les liens sont gérés par une redirection Google => On la supprime
		 */
		let cguContent = jQuery('#cgu-content');
		let links = cguContent.find("a");
		let reg = new RegExp('https:\\/\\/www\\.google\\.com\\/url\\?q=((?:https?:\\/\\/)?(?:[\\da-z\\.-]+)\\.(?:[a-z\\.]{2,6})(?:[\\/\\w \\.-]*)*\\/?).*')
		links.each((i, item) => {
			let element = jQuery(item);
			let res = reg.exec(element.prop("href"));
			if (res && res[1]) {
				element.prop("href", res[1]);
			}

		});
		//FIN
	}
}
