import { TelephoneModel } from "../contacts/telephone.model";
import { CorrespondantModel } from "../../entreprise/contacts/correspondant.model";

/**
 * Informations d'un contact pour la téléphonie.
 * Created by "Raphaël Marnier <raphael.marnier@scub.net>" on 14/02/17.
 */
export class ContactTelephonie {
	id: number;
	type: string;
	telephones: Array<TelephoneModel>;
	correspondants: Array<CorrespondantModel>;
}
