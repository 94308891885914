<!-- doc : http://valor-software.com/ngx-bootstrap/#/typeahead -->

<!-- Template du champ Typeahead -->
<div class="suggest-container" data-e2e="suggestions">
	<i (click)="afficherListeComplete()" class="icon-chevron-down open-thumb"
	   data-e2e="input-liste-entite-juridique-de-rattachement" id="carret_{{uniqId}}"
	   aria-hidden="true"
	   *ngIf="!readonly && !disabled"></i>
	<input [(ngModel)]="selectedLabel"
		   (keyup)="gererClavier()"
		   [typeahead]="dataSourceInternal"
		   (typeaheadLoading)="loading($event)"
		   (typeaheadNoResults)="noResult($event)"
		   (typeaheadOnSelect)="select($event);notifierModificationSelection()"
		   [typeaheadOptionsLimit]="limit"
		   [typeaheadItemTemplate]="customItemTemplate"
		   [typeaheadGroupField]="grouperParEffectif"
		   (blur)="arreterRecherche();notifierPerteFocus()"
		   [typeaheadAsync]="true"
		   [typeaheadWaitMs]="300"
		   [typeaheadMinLength]="minLength"
		   [typeaheadOptionField]="nomLabel"
		   [attr.placeholder]="placeholder"
		   class="form-control {{customInputClass}}"
		   [disabled]="disabled"
		   [readonly]="readonly"
		   autocomplete="off"
		   id="suggest_{{uniqId}}"
		   *ngIf="!readonly && !disabled"
		   data-e2e="input_suggest">
	<input class="form-control" type="text" *ngIf="readonly || disabled" value="{{selectedLabel}}"
		   [readonly]="true"/>
</div>