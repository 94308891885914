import { Injectable } from "@angular/core";
import { UtilisateurConnecte } from "../models/utilisateur/utilisateur.model";
import { OutilSupport, TypeOutilSupport } from "../models/outil-support.model";

@Injectable({
	providedIn: 'root'
})
export class UsersnapService {

	private api: any = null;

	private key: string = null;

	private needUpdate: boolean = false;

	private data: UsersnapFieldsModel = new UsersnapFieldsModel();

	constructor() {
		(window as any).onUsersnapLoad = (api) => {
			this.api = api;
			this.api.init({
				fields: {
					comment: {
						defaultValue: this.data.comment,
						label: 'Votre message',
						required: true,
						multiline: true,
					},
				}
			});
			this.api.on("open", (event) => this.onOpen(event));
		};
	}

	private onOpen(event: any) {
		if (this.data.title) event.api.setValue('title', this.data.title);
		if (this.data.email) event.api.setValue('email', this.data.email);
		if (this.data.comment) event.api.setValue('comment', this.data.comment);
		if (this.data.customData) event.api.setValue('customData', this.data.customData);
	}

	public loadScript() {
		if (!this.needUpdate || !this.key) return;

		const script = document.createElement('script');
		script.id = 'usersnap-script';
		script.async = true;
		script.src = 'https://api.usersnap.com/load/' + this.key + '.js?onload=onUsersnapLoad';

		document.body.appendChild(script);

		this.needUpdate = false;
	}

	public setup(utilisateur: UtilisateurConnecte) {
		this.setKey(utilisateur.outilSupport);
		this.setEmail(utilisateur.login);
		this.setTitle(utilisateur);
	}

	public setKey(outilSupport: OutilSupport) {
		if (outilSupport && outilSupport.libelle === TypeOutilSupport[TypeOutilSupport.USERSNAP]
			&& this.key !== outilSupport.cle) {
			// Si une clé différente est renseignée il faut recharger le widget.
			if (this.key) {
				window.location.reload();
				// NOTE: Le script Usersnap impacte le comportement de toutes les requêtes si il est chargé une seconde fois.
				// // Suppression du widget
				// const iframe = document.getElementsByName('us-entrypoint-button')[0];
				// if (iframe) iframe.outerHTML = '';
				// // Suppression du script
				// document.getElementById('usersnap-script').outerHTML = '';
			}

			this.needUpdate = true;
			this.key = outilSupport.cle;
		} else {
			this.key = null;
			//Suppression du widget
			const iframe = document.getElementsByName('us-entrypoint-button')[0];
			if (iframe) {
				iframe.outerHTML = '';
			}
			//Suppression du script
			let elementById = document.getElementById('usersnap-script');
			if (elementById) {
				elementById.outerHTML = '';
			}
		}
	}

	public setEmail(email: string) {
		if (email) {
			this.data.email = email;
		}
	}

	public setTitle(utilisateur: UtilisateurConnecte) {
		const arr = [
			utilisateur.entiteJuridique && utilisateur.entiteJuridique.libelle,
			utilisateur.portefeuille && utilisateur.portefeuille.libelle,
			utilisateur.pole && utilisateur.pole.libelle,
			utilisateur.nom + ' ' + utilisateur.prenom
		];

		this.data.title = arr.filter(v => v).join(' - ');
	}
}

class UsersnapFieldsModel {
	comment: string = '';
	email: string = '';
	title: string = '';
	customData: string = '';
}
