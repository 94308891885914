import { Component } from "@angular/core";
import { ActivatedRoute } from "@angular/router";

@Component({
	selector: 'redirect-logout-component',
	template: ``,
})
export class RedirectionLienExterieurComponent {

	constructor(private route: ActivatedRoute) {
	}

	ngOnInit() {
		window.location.href = this.route.snapshot.queryParams['origin'];
	}
}
