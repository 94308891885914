import { IdLibelleModel } from "./id.libelle.model";

export class PaysBase extends IdLibelleModel {

	/** Code pays. */
	codePays: string;
	/** Format téléphone. */
	formatTelephone: string;
	/** Pays à risque dans le cadre de la LAB/Tracfin. */
	risqueLAB: boolean;
}

export class ReferentielPays extends PaysBase {

	/** Override propriétés et valeur par défaut. */
	id: number = 70;
	libelle: string = "France";
	codePays: string = "fr";
	formatTelephone: string = ReferentielPays.formatFrance;
	risqueLAB: boolean = false;

	libelleAffiche: string = "France";

	static formatFrance: string = "^0[0-9]{9}$";
}
