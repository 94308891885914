import { Directive, ElementRef, EventEmitter, HostListener, Output } from '@angular/core';

@Directive({
	selector: '[clickBlur]'
})
export class ClickBlurDirective {
	initEnCours: boolean = true;

	@Output()
	public clickBlur = new EventEmitter<MouseEvent>();

	constructor(private _elementRef: ElementRef) {
		// NOTE: on attend légèrement avant d'activer le comportement de la directive
		setTimeout(() => this.initEnCours = false, 100);
	}

	@HostListener('document:click', ['$event', '$event.target'])
	public onClick(event: MouseEvent, targetElement: HTMLElement): void {
		if (this.initEnCours) return;

		const cliqueInterieur = this._elementRef.nativeElement.contains(targetElement);
		if (!cliqueInterieur) {
			this.clickBlur.emit(event);
		}
	}
}
