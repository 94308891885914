import { NgModule } from "@angular/core";
import { StringToDatePipe, StringToDateWithoutTimePipe } from "./string.to.date.pipe";
import { MontantPipe } from "./montant.pipe";
import { SafeHtmlPipe } from "./safehtml";
import { IbanPipe } from "./iban.pipe";
import { FilterPipe } from "../ui/country-selector";

@NgModule({
	declarations: [
		StringToDatePipe,
		StringToDateWithoutTimePipe,
		IbanPipe,
		MontantPipe,
		SafeHtmlPipe,
		FilterPipe,
	],
	exports: [
		StringToDatePipe,
		StringToDateWithoutTimePipe,
		IbanPipe,
		MontantPipe,
		SafeHtmlPipe,
		FilterPipe,
	]
})
/* Widget Module */
export class CustomPipesModule {
}
