/**
 * Service de communication dans la famille des composants contacts
 */
import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, Subject } from "rxjs";
import { GlobalMessageService } from "../global.message.service";
import {
	LocalStorageService,
	LS_CONTACT_CORRESPONDANT_NOUVEAU,
	LS_CONTACT_DEVIS_INFOS_ADHESION_ONGLET,
	LS_CONTACT_ENTREPRISE_COORDS,
	LS_CONTACT_ENTREPRISE_PROFIL,
	LS_CONTACT_EVENEMENT_ACTIVITE_COMMENTAIRE,
	LS_CONTACT_EVENEMENT_ACTIVITE_CREATION,
	LS_CONTACT_EVENEMENT_CREATION,
	LS_CONTACT_HABILITATION_CONVENTION_ID,
	LS_CONTACT_ID,
	LS_CONTACT_NOTES,
	LS_CONTACT_PAGE_OPENED,
	LS_CONTACT_PAGE_ORIGIN,
	LS_CONTACT_PARTICULIER_COORDS_CONTRAT,
	LS_CONTACT_PROJET_CREATION,
	LS_CONTACT_PROJET_NOUVELLE_ETUDE,
	LS_CONTACT_RECLAMATION,
	LS_CONTACT_TYPE_AJOUT_RELATIONS,
	LS_SAISIE_NOUVELLE,
	LS_TELEPHONIE_APPEL_ENTRANT
} from "../LocalStorage.service";
import { Router } from "@angular/router";
import { ContactModel } from "../../models/commun/contacts/contact.model";
import { ContactResource } from "../../resources/contact/contact.resource";
import { TranslateService } from "@ngx-translate/core";
import { CourtierService } from "../courtier.service";
import { ConfirmPopupModel } from "../../models/confirm.popup.model";
import { ConfirmPopupService } from "../confirm.popup.service";
import { Utils } from "../../utils/Utils";

export const MAX_OPENED_CONTACT = 8;

@Injectable({
	providedIn: 'root'
})
export class ContactsComponentsService {
	// Observable string sources
	private leftMenuContent = new Subject<boolean>();
	public setNbReclamationEnCoursSubject = new Subject<number>();
	private contactOpened: ContactModel = null;
	private contactsOpened: Array<ContactModel> = [];
	private contactsOpenedSubject: BehaviorSubject<Array<ContactModel>> = new BehaviorSubject<Array<ContactModel>>([]);
	private contactHeaderSource: BehaviorSubject<ContactModel> = new BehaviorSubject<ContactModel>(null);
	private featuresDirty = [];

	/**
	 * Subject/Observable pour vider la liste des contacts
	 * Utilisé au changement de portefeuille
	 */
	private viderContactsSub: Subject<boolean> = new Subject<boolean>();
	private viderContactsObs: Observable<boolean> = this.viderContactsSub.asObservable();

	// Observable string streams
	updateLeftMenuObserver = this.leftMenuContent.asObservable();
	contactOpened$: Observable<ContactModel> = this.contactHeaderSource.asObservable();
	setNbReclamationEnCoursObserver = this.setNbReclamationEnCoursSubject.asObservable();

	constructor(private messageService: GlobalMessageService,
				private contactResource: ContactResource,
				private confirmPopupService: ConfirmPopupService,
				private storage: LocalStorageService,
				private courtierService: CourtierService,
				private router: Router,
				private translate: TranslateService) {
	}

	updateLeftMenu() {
		this.leftMenuContent.next(true);
	}

	showCreateContactForm() {
		jQuery("ajout-contact .full-modal").addClass("on");
	}

	hideCreateContactForm() {
		jQuery("ajout-contact .full-modal").removeClass("on").addClass("off");
		setTimeout(() => {
			jQuery("ajout-contact .full-modal").removeClass("off");
		}, 500);
	}

	getContactsOpenedSubject() {
		return this.contactsOpenedSubject;
	}

	getContactHeaderSource() {
		return this.contactHeaderSource;
	}

	openContact(contact: ContactModel, pageToOpen: string = null): boolean {
		// Si pas de contact renseigné.
		if (!contact) {
			this.contactOpened = null;
			// Annonce du changement de contact.
			this.contactHeaderSource.next(null);
			return false;
		} else {
			// Recherche si le contact renseigné est déjà ouvert.
			let dejaOuvert: boolean = false;
			for (let i = 0; i < this.contactsOpened.length; i++) {
				if (this.contactsOpened[i].id === contact.id) {
					dejaOuvert = true;
					// Si oui, maj du contact dans la liste (pour maj des libellés affichés)
					this.contactsOpened[i] = contact;
					break;
				}
			}

			// Si un appel entrant est en cours sur le contact renseigné.
			let ficheAppelEntrant = false;
			if (this.storage.exists(LS_TELEPHONIE_APPEL_ENTRANT)) {
				let appelStocke = this.storage.load(LS_TELEPHONIE_APPEL_ENTRANT);
				ficheAppelEntrant = appelStocke && appelStocke.idContact && appelStocke.idContact == contact.id;
			}

			if (dejaOuvert || (!dejaOuvert && this.contactsOpened.length < MAX_OPENED_CONTACT) || ficheAppelEntrant) {
				// Si le contact n'as pas déjà été ouvert.
				if (!dejaOuvert) {
					// Ajout du contact à la liste des contacts ouverts.
					this.contactsOpened.push(contact);
					this.contactsOpenedSubject.next(this.contactsOpened);

					// Sauvegarde de la page d'origine lors de l'ouverture du contact.
					const pageOrigin = this.router.url;
					if (pageOrigin && !contact.redirectionSuiteFusion && !this.router.isActive('/contacts/' + ContactModel.getContactTypeFront(contact) + '/' + contact.id, false)) {
						this.storage.persist(pageOrigin, LS_CONTACT_PAGE_ORIGIN, {idContact: contact.id});
					}
				}
				this.contactOpened = contact;

				let contactType = ContactModel.getContactTypeFront(this.contactOpened);

				// Restauration de la dernière page ouverte pour ce contact.
				if (!pageToOpen && this.storage.exists(LS_CONTACT_PAGE_OPENED, {idContact: contact.id})) {
					let value = this.storage.load(LS_CONTACT_PAGE_OPENED, {idContact: contact.id});
					if (value && value != this.router.url) {
						if (!this.router.isActive(value, true)) {
							this.router.navigate(
								['/forward', {data: encodeURIComponent(JSON.stringify(value))}],
								{skipLocationChange: true});
						}
					} else {
						this.contactHeaderSource.next(contact);
					}
				} else if (pageToOpen || (!this.router.isActive('/contacts/' + contactType + '/' + this.contactOpened.id + '/' + (pageToOpen || 'resume'), true)
					&& this.contactsOpened.length > 0)) {
					this.router.navigate(
						['/forward', {data: encodeURIComponent(JSON.stringify('/contacts/' + contactType + '/' + this.contactOpened.id + '/' + (pageToOpen || 'resume')))}],
						{skipLocationChange: true});
				} else {
					this.contactHeaderSource.next(contact);
				}

				// Scroll de la vue vers le haut et réaffichage de la barre de navigation.
				jQuery("body").scrollTop(0).removeClass("nav-bar-off");
				return true;
			} else {
				this.showTooManyContactOpenError();
			}
			return false;
		}
	}

	closeContactOnDelete(contactId: number) {
		for (let index = 0; index < this.contactsOpened.length; index++) {
			if (this.contactsOpened[index].id == contactId) {
				this.contactsOpened.splice(index, 1);
				this.contactsOpenedSubject.next(this.contactsOpened);
			}
		}
	}

	closeContact(contact: ContactModel, forcer: boolean = false, redirection: boolean = true) {
		this.featuresDirty = [];
		let modifsEnCours = this.checkDirtyFeature(
			[LS_CONTACT_ENTREPRISE_PROFIL,
				LS_CONTACT_ENTREPRISE_COORDS,
				LS_CONTACT_TYPE_AJOUT_RELATIONS,
				LS_CONTACT_EVENEMENT_CREATION,
				LS_CONTACT_EVENEMENT_ACTIVITE_CREATION,
				LS_CONTACT_EVENEMENT_ACTIVITE_COMMENTAIRE,
				LS_CONTACT_PARTICULIER_COORDS_CONTRAT,
				LS_CONTACT_RECLAMATION,
				LS_CONTACT_PROJET_NOUVELLE_ETUDE,
				LS_CONTACT_DEVIS_INFOS_ADHESION_ONGLET,
				LS_CONTACT_CORRESPONDANT_NOUVEAU,
				LS_SAISIE_NOUVELLE,
				LS_CONTACT_HABILITATION_CONVENTION_ID,
				LS_CONTACT_PROJET_CREATION,
				LS_CONTACT_NOTES],
			contact);

		if (!forcer && modifsEnCours) {
			let confirmModel: ConfirmPopupModel = new ConfirmPopupModel();
			confirmModel.modalTitle = this.translate.instant("MODAL.CLOSE_CONTACT.TITLE");
			confirmModel.modalContent = this.translate.instant("MODAL.CLOSE_CONTACT.CONTENT");

			if (this.featuresDirty && this.featuresDirty.length) {
				let modalDetails = [this.translate.instant("MODAL.CLOSE_CONTACT.DETAILS")];
				this.featuresDirty.forEach(key => {
					modalDetails.push(this.translate.instant("CONFIRME_FERMETURE." + key));
				});
				confirmModel.modalDetails = modalDetails;
			}
			confirmModel.onConfirmCallback = () => {
				this.confirmCloseContact(contact, redirection);
			};
			this.confirmPopupService.popup(confirmModel);
		} else {
			this.confirmCloseContact(contact, redirection);
		}
	}

	/**
	 * Check si une clé de LS est présente en session signifiant une modification en cours
	 * @param features
	 * @param contact
	 */
	checkDirtyFeature(features: Array<string>, contact: ContactModel) {
		//Récupération des clé de session commancant par LS_CONTACT_ID et en remplacant la variable idContact
		const listkeySession = this.storage.listkeySession(LS_CONTACT_ID, {"idContact": contact.id});

		//Création d'une liste d'objet avec les clés à tester
		//featureContact => liste des clé avec idContact setter
		//featureSanitize => clé avec toutes les variables supprimée
		let featuresACheck = [];
		features.forEach(feature => {
			const featureContact = Utils.clone(feature.replace("{idContact}", contact.id + ""));
			const featureSanitize = this.deleteVariable(feature);
			featuresACheck.push({featureContact: featureContact, featureSanitize: featureSanitize});
		});

		let checkDirtyAll = false;
		//on boucle sur toutes les clés à tester
		featuresACheck.forEach(featureACheck => {
			//On split la clé à tester
			const splitACheck = featureACheck.featureContact.split("-");
			//Pour chacune des clés à tester on regarde toutes les clées de sessions
			listkeySession.forEach(keySession => {
				const splitSession = keySession.split("-");
				//Si elles ont le meme nombre d'élément alors on les comparent, sinon elles sont forcément différentes
				//et ca ne correspond donc pas à notre feature à tester
				let egale = splitACheck.length == splitSession.length;
				if (egale) {
					//Pour chacun des éléménts on compare, si ca commence par "{" alors c'est une variable, on ne la compare pas.
					for (let i = 0; i < splitACheck.length; i++) {
						if (!splitACheck[i].startsWith("{")) {
							egale = splitACheck[i] == splitSession[i];
						}
						//Si on trouve une différence, on arrete, cette clé de session ne correspond pas à cette clé
						//de feature à tester
						if (!egale) {
							break;
						}
					}
				}
				//Si la clé de session est égale à la clé de feature à tester
				if (egale) {
					//Cas particulier de la gestion du storage pour les réclamations : la clé des réclamations peut être
					//présente pour restituer l'etat de la page mais n'a pas pour autant de formulaire en cours de modification.
					//Si c'est le cas on stop ce tour de boucle pour ne pas l'inclure dans les formulaires modifié
					if (featureACheck.featureSanitize == "contact-reclamations") {
						const reclamationStorage = this.storage.load(featureACheck.featureContact, {"idContact": this.contactOpened.id});
						if (!reclamationStorage.formulaireModifie) {
							return;
						}
					}
					checkDirtyAll = true;
					//Construction de la clé de trad avec la clé de localStorage
					const clefDeTrad = featureACheck.featureSanitize.replace('-', '.').toUpperCase();
					//On pousse la clé de trad dans le tableau de résultat si elle n'est pas déja présente
					if (this.featuresDirty.indexOf(clefDeTrad) === -1) {
						this.featuresDirty.push(clefDeTrad);
					}
				}
			});
		});
		return checkDirtyAll;
	}

	deleteVariable(key: string) {
		//Suppression des éléments variable dans la clé de storage
		const regexDeleteVariables = /(-{)+(.*?)(})+/;
		//Tant qu'une varaible du genre "-{exemple}" est présente alors on l'enlève
		while (key.match(regexDeleteVariables)) {
			key = key.replace(regexDeleteVariables, '');
		}
		return key;
	}

	confirmCloseContact(contact: ContactModel, redirection: boolean = true) {
		// Supression du contact de la liste des contacts ouverts.
		const index = this.contactsOpened.findIndex(contactOpened => contactOpened.id == contact.id);
		if (index != -1) {
			this.contactsOpened.splice(index, 1);
			this.contactsOpenedSubject.next(this.contactsOpened);
		}

		// Redirection sur la dernière page ouverte avant d'accéder au contact.
		if (redirection || this.router.isActive(`/contacts/${ContactModel.getContactTypeFront(contact)}/${contact.id}`, false)) {
			const pageOrigin = this.storage.load(LS_CONTACT_PAGE_ORIGIN, {idContact: contact.id});
			this.router.navigate(
				['/forward', {data: encodeURIComponent(JSON.stringify(pageOrigin || '/contacts'))}],
				{skipLocationChange: true}
			);
		}

		this.storage.remove(LS_CONTACT_ID, {"idContact": contact.id});
	}

	/**
	 * Fonction de gestion du redirect sur une personne cible fusion quand une URL de personne source fusion est demandée
	 * (personne source fusion est donc une personne supprimée)
	 * @param contact le contact de destination
	 * @param criteres le contact etant demandé
	 * @param cleRecherche la clé de recherche dans l'url "entreprises" ou "particuliers"
	 */
	redirectContactFusionne(contact: ContactModel, criteres: ContactModel, cleRecherche: string): ContactModel {
		//Information à l'utilisateur comme quoi le contact demandé à été fusionné sur lequel nous redirigeons
		this.messageService.showInfo(this.translate.instant("CONTACT.OUVERTURE.REDIRECTION_CONTACT_FUSIONNE"));

		//Recupération de l'url demandée (deux niveaux d'encodage possible)
		const urlSplit = decodeURIComponent(decodeURIComponent(this.router.url)).split("\"");
		//recherche de l'url demandée dans les réustat du split
		const url: string = urlSplit.length ? urlSplit.find(value => value.startsWith("/contacts")) : null;

		//Si une URL est trouvée et que l'identifiant du contact ayant été supprimé par la fusion est présent
		//alors on modifie l'url pour pointer vers le contact de redirection, on ferme la fiche du contact supprimé
		//et on navigue vers l'URL corrigée
		if (url && url.search(cleRecherche + criteres.id) != -1) {
			this.confirmCloseContact(criteres, false);
			this.router.navigateByUrl(url.replace(cleRecherche + criteres.id, cleRecherche + contact.id));
		}
		//Dans le cas ou on ne navigue pas vers une URL corrigée, on indique que celui-ci est issu d'une redirection pour le contactService
		contact.redirectionSuiteFusion = true;
		return contact;
	}

	getCurrentOpenedContact(): ContactModel {
		return this.contactOpened;
	}

	refreshCurrentContact() {
		if (this.contactOpened && this.contactOpened.type)
			this.contactResource.get({
				id: this.contactOpened.id,
				typeContact: ContactModel.getContactType(this.contactOpened)
			}).subscribe((contact) => {
				this.setCurrentContact(contact);
			});
	}

	setCurrentContact(contact: ContactModel) {
		console.log("refresh contact, ", contact);
		this.contactOpened = contact;
		this.contactHeaderSource.next(contact);
		for (let index in this.contactsOpened) {
			if (this.contactsOpened[index].id == contact.id) {
				this.contactsOpened[index] = contact;
			}
		}
	}

	/**
	 * Déclenche la remise à zéro de la liste des contacts ouverts.
	 * Et avertit les abonnés à l'observable viderContactObs
	 */
	resetContacts() {
		this.contactsOpened = [];
		this.contactOpened = null;
		this.contactsOpenedSubject.next(this.contactsOpened);
		this.viderContactsSub.next(true);
	}

	/**
	 * Permet de s'abonner à l'évènement de remise à zéro de la liste des contacts ouverts.
	 * @returns {Observable<boolean>} l'abonnement au reset des contacts
	 */
	auResetContacts() {
		return this.viderContactsObs;
	}

	getContacts() {
		return this.contactsOpened;
	}

	showTooManyContactOpenError() {
		this.messageService.showError("Vous ne pouvez pas ouvrir plus de 8 fiches contact en simultané. Merci de fermer certaines fiches afin de pouvoir en ouvrir d’autres.");
	}
}
