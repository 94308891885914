<div class="inversed-screen">
	<div class="header">
		Accords de santé
	</div>
	<div class="body">
		<div class="ccn-name">Convention collective ID3018 - bureaux d’études techniques, cabinets d’ingénieurs
			conseils, société de
			conseils
		</div>


		<ul class="outer idcc">
			<li><span>IDCC</span><span>3018</span></li>
			<li><span>Numéro de brochure</span><span>3257</span></li>
		</ul>

		<section>Informations générales</section>

		<table>
			<tr>
				<th>
					Collège
				</th>
				<th>
					Organisme désigné
				</th>
				<th>
					Garanties imposées
				</th>
			</tr>
			<tr>
				<td>
					Ensemble du personnel
				</td>
				<td>
					Non. Libre choix de l’organisme
				</td>
				<td>
					Oui
				</td>
			</tr>
		</table>

		<ul class="outer">
			<li><span>Cotisation</span><span>0,90% PMSS soit 28,96€/mois</span></li>
			<li><span>Participation employeur</span><span>50Ù</span></li>
			<li><span>Date de l’accord santé</span><span>03/06/2015</span></li>
			<li><span>Date de fin de l’accord</span><span>01/03/2017</span></li>
			<li><span>Lien officiel</span><span>Consulter la convention sur légifrance</span></li>
		</ul>

		<section>Niveau de couverture santé de l’accord</section>
		<div class="row">
			<div class="col-md-offset-1 col-md-6">
				<div class="row">
					<div class="col-md-6">
						<sps-niveau-besoin-display
								[icon-nom]="'icon-stethoscope'"
								[niveau-besoin]="">
						</sps-niveau-besoin-display>
					</div>
					<div class="col-md-6">
						<sps-niveau-besoin-display
								[icon-nom]="'icon-hospital'"
								[niveau-besoin]="">
						</sps-niveau-besoin-display>
					</div>
					<div class="col-md-6">
						<sps-niveau-besoin-display
								[icon-nom]="'icon-tooth'"
								[niveau-besoin]="">
						</sps-niveau-besoin-display>
					</div>
					<div class="col-md-6">
						<sps-niveau-besoin-display
								[icon-nom]="'icon-glasses'"
								[niveau-besoin]="">
						</sps-niveau-besoin-display>
					</div>
				</div>
			</div>
			<div class="col-md-4">
				<button class="btn btn-success btn-icon-right xl">
					<span>
						voir le panier<br>
						de soin de l’accord
					</span>
					<span>
						<i class="icon-chevron-right"></i>
					</span>
				</button>
			</div>
		</div>

		<section>Simulateur reste à charge</section>

		<div class="text-center simulateur-intro">
			<p>
				Cet accord vous rembourse-t-il bien ?
			</p>
			<p>
				Utilisez le simulateur de reste à charge pour connaître à l'euro près
			</p>
			<p>
				ce que l'accord rembourse et ce qu'il laisse à votre charge.
			</p>
		</div>
		<div class="row simulateur">
			<div class="col-md-offset-2 col-md-5 form">
				<div class="form-group inline">
					<label for="">Catégories de soin :</label>
					<select name="" id="" class="form-control"></select>
				</div>
				<div class="form-group inline">
					<label for="">Catégories de soin :</label>
					<select name="" id="" class="form-control"></select>
				</div>

				<div>
					Nb de consultations : 1
					<span class="icon-stack plus">
					  <i class="icon-circle icon-stack-2x"></i>
					  <i class="icon-plus icon-stack-1x"></i>
					</span>
				</div>
				<div>
					Dépense :
					<span class="icon-stack minus">
					  <i class="icon-circle icon-stack-2x"></i>
					  <i class="icon-minus icon-stack-1x"></i>
					</span>
					35.00€
					<span class="icon-stack plus">
					  <i class="icon-circle icon-stack-2x"></i>
					  <i class="icon-plus icon-stack-1x"></i>
					</span>
				</div>
				<div>Total de la dépense : 35.00€</div>

			</div>

			<div class="col-md-offset-2 col-md-3 refund">

				<div class="part-user">
					<span>1.00€</span>
					<div>A votre charge</div>
				</div>
				<div class="part-mutual">
					<span>18,90€</span>
					<div>Part mutuelle</div>
				</div>
				<div class="part-care">
					<span>15,10€</span>
					<div>Part régime général</div>

				</div>

			</div>
		</div>
	</div>
</div>