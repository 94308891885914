/**
 * .
 * Created by Kobla GBENYO (kobla.gbenyo@scub.net) on 27/10/16.
 */
import { Injectable } from "@angular/core";
import { SPSResource } from "../sps-resource";
import { ResourceParams } from "@ngx-resource/core";

@Injectable({
  providedIn: 'root'
})
@ResourceParams({
	// Api path
	path: '/parametrage-telephonie'
})
export class ParametrageTelephonieResource extends SPSResource<any, any, any, any> {
}
