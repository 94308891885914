import {
	IResourceMethodObservable,
	Resource,
	ResourceAction,
	ResourceRequestMethod
} from "@ngx-resource/core";

/**
 * Implementation de la classe ResourceCRUDBase à partir de l'ancien module 'ngx-resource'.
 */
export abstract class ResourceCRUDBase<TQuery, TKeys, TShort, TFull> extends Resource {

	@ResourceAction({
		expectJsonArray: true
	})
	query: IResourceMethodObservable<TQuery, TShort[]>;

	@ResourceAction()
	get: IResourceMethodObservable<TKeys, TFull>;

	@ResourceAction({
		method: ResourceRequestMethod.Post
	})
	save: IResourceMethodObservable<TFull, TFull>;

	@ResourceAction({
		method: ResourceRequestMethod.Put
	})
	update: IResourceMethodObservable<TFull, TFull>;

	@ResourceAction({
		method: ResourceRequestMethod.Delete
	})
	remove: IResourceMethodObservable<TKeys, any>;

}
