/* External decorators and services */
import { Component, ViewEncapsulation } from "@angular/core";
import { FormBuilder } from "@angular/forms";
import { NavigationStart, Router } from "@angular/router";
import { JwtHelperService } from "@auth0/angular-jwt";
import { TranslateService } from "@ngx-translate/core";
import * as moment from "moment";
import "moment/locale/fr";
import { environment } from "environments/environment";
/* Services */
import { AppState } from "./app.service";
import { GlobalMessageService } from "./services/global.message.service";
import {
	LocalStorageService,
	LS_AUTHENTIFICATION_SESSION_DATE,
	LS_AUTHENTIFICATION_TOKEN,
	LS_CONTACT,
	LS_CONTACT_PAGE_OPENED,
	LS_TELEPHONIE_APPEL_ENTRANT,
	LS_TELEPHONIE_PARAMETRAGE
} from "./services/LocalStorage.service";
import { UtilisateurService } from "./services/utilisateur.service";
import { TelephonieService } from "./services/telephonie.service";
import { ContactsComponentsService } from "./services/contacts/contacts.service";
import {
	FullScreenModalService,
	ModalServiceSubscribeModel
} from "./services/modal.fullscreen.service";
import { UsersnapService } from "./services/usersnap.service";
/* Components */
/* Models */
import { GlobalMessageModel } from "./models/global.message.model";
import { UtilisateurConnecte } from "./models/utilisateur/utilisateur.model";
import { AppelEntrant } from "./models/telephonie/appel.entrant";
import { ContactModel } from "./models/commun/contacts/contact.model";
import { ParametrageTelephonie } from "./models/telephonie/parametrage.telephonie";
import { PaginateResponseModel } from "./models/paginate.response.model";
import { TypeContact } from "./enums/contact.type.model.enum";
/* Resources */
import { UtilisateurResource } from "./resources/utilisateur/utilisateurs.resource";
import { ContactIntegrationResource } from "./resources/contact/contact.integration.ressource";
import { FeatureFlippingService } from './services/feature.flipping.service';

/*
 * App Component
 * Top Level Component
 */
@Component({
	selector: 'app-root',
	encapsulation: ViewEncapsulation.None,
	styleUrls: [
		'./app.style.less',
		'./styles/header.style.less'
	],
	templateUrl: './app.template.html',
	/*host: {
		'(window:resize)': 'onResize()'
	}*/
})
export class AppComponent  {
	messages: GlobalMessageModel[] = [];
	menuHautDeplie: boolean = false;
	utilisateur: UtilisateurConnecte = new UtilisateurConnecte();
	appelEntrant: AppelEntrant = null;

	dureeAttenteAppel: number = 20;
	background: string;

	roleCentreDocumentaire: boolean = false;
	roleAdministrationPortefeuille: boolean = false;
	roleBatch: boolean = false;

	featureQualificationMasquerMenu: boolean ;
	featureCampagneMasquerMenu: boolean ;
	featureSaisiesMasquerMenu: boolean ;

	/**
	 * nombre de client à integrer, affiché dans le Bandeau integration client
	 * @type {number}
	 */
	nombreIntegration: number;

	/**
	 * Affiche le Bandeau integration client
	 * @type {boolean}
	 */
	bandeauIntegrationAffiche: boolean = false;

	/**
	 * Nom de l'environnement actuel pour mettre un bandeau sur le logo
	 * @type {string}
	 */
	envName: string = environment.BANDEAU_ENV;

	batchFront: string = environment.BATCH_FRONT_URL;

	constructor(public appState: AppState,
				private router: Router,
				private fb: FormBuilder,
				// Services
				private messenger: GlobalMessageService,
				private storage: LocalStorageService,
				private translate: TranslateService,
				private utilisateurService: UtilisateurService,
				private utilisateurResource: UtilisateurResource,
				private contactsComponentService: ContactsComponentsService,
				private telephonieService: TelephonieService,
				private fullModalService: FullScreenModalService,
				private usersnapService: UsersnapService,
				// Resources
				private contactIntegrationResource: ContactIntegrationResource,
				private featureFlippingService: FeatureFlippingService) {



		//On confirme avant de quitter la page que des modifs peuvent être perdue
		window.onbeforeunload = (e) => {
			this.appState.quitterPage();
		};

		messenger.globalMessageObserver.subscribe(
			message => {
				this.messages.push(message);
				setTimeout(() => {
					this.messages = this.messages.filter((instance) => instance.id != message.id);
				}, 5000);
			}
		);
		telephonieService.appelEntrantObs.subscribe((appel: AppelEntrant) => {
			if (this.storage.exists(LS_TELEPHONIE_PARAMETRAGE)) {
				let parametre: ParametrageTelephonie = this.storage.load(LS_TELEPHONIE_PARAMETRAGE);
				if (parametre.delaisAppelEntrant && parametre.delaisAppelEntrant > 0) {
					this.dureeAttenteAppel = parametre.delaisAppelEntrant;
				}
			}
			this.appelEntrant = appel;
			if (!!appel) {
				this.appelEntrant.afficherBandeau = true;

				// Relance l'animation de la progress bar (utile uniquement dans le cas de la réception d'un appel
				// entrant alors que le bandeau était déjà affiché)
				this.appelEntrant.lancerProgression = false;
				setTimeout(() => {
					this.appelEntrant.lancerProgression = true;
				}, 100);
				this.appelEntrant.initialiserMoteurRecherche = !appel.idContact;

				this.storage.persist(this.appelEntrant, LS_TELEPHONIE_APPEL_ENTRANT);
			}
		});

		this.fullModalService.fullModalObserver.subscribe(({modal, open, params}: ModalServiceSubscribeModel) => {
			if (modal === "contact-integration-full-modale" && !open) {
				this.checkIntegration();
			}
		});

		utilisateurService.utilisateurConnecteObs.subscribe((connecte: boolean) => {
			this.utilisateur = this.utilisateurService.getUtilisateurConnecte();
			console.debug("Notification concernant l'utilisateur connecté reçue : ", connecte);
			if (!connecte) return;

			console.debug("Appel de la connexion à la téléphonie");
			// Lancement de la connexion pour la téléphonie
			this.telephonieService.connexionTelephonie();

			this.checkIntegration();

			this.roleCentreDocumentaire = this.utilisateurService.hasRole("ROLE_CENTRE_DOCUMENTAIRE");
			this.roleAdministrationPortefeuille = this.utilisateurService.hasRole("ROLE_ADMINISTRATION_PORTEFEUILLE");
			this.roleBatch = this.utilisateurService.hasRole("ROLE_BATCH");

			// Lancement Usersnap
			this.usersnapService.setup(this.utilisateur);
			this.usersnapService.loadScript();

			//On récupère les features flipping du portefeuille
			this.featureQualificationMasquerMenu = this.featureFlippingService.hasFeatureRight("QUALIFICATION_MASQUER_MENU");
			this.featureCampagneMasquerMenu = this.featureFlippingService.hasFeatureRight("CAMPAGNE_MASQUER_MENU");
			this.featureSaisiesMasquerMenu = this.featureFlippingService.hasFeatureRight("SAISIES_MASQUER_MENU");
		});

		router.events.subscribe((event: any) => {
			if (event instanceof NavigationStart) {

				// Lors de la réception d'un événement sur le changement de route
				// Si la destination est de type /contact/{typeContact}/idContact, alors on enregistre l'url de destination comme la page courante pour le contact
				let navigationStart = (<NavigationStart>event);
				if (navigationStart.url && navigationStart.url.startsWith("/contacts")) {
					let splited = navigationStart.url.split("/");
					if (splited.length > 2) {
						let idContact = splited[3];
						this.storage.persist(navigationStart.url, LS_CONTACT_PAGE_OPENED, {"idContact": idContact});
					}
				}
			}
			/*setTimeout(() => {
				jQuery("body").removeClass('nav-bar-off').scrollTop(0);
				//this.onResize();
			}, 0);*/
		});

		this.appState.registerBackground().subscribe(background => {
			this.background = background;
		});

		// Appel permettant de gérer le cas de rafraichissement de la page
		this.telephonieService.connexionTelephonie();
	}

	toggleMenuHautDeplie() {
		this.menuHautDeplie = !this.menuHautDeplie;
	}

	/**
	 * Traitement d'un appel entrant.
	 * Masque le bandeau de notification et redirige l'utilisateur sur la fiche du contact ou le moteur de recherche
	 * des contacts.
	 */
	traiterAppel() {
		this.masquerBandeauAppelEntrant();
		if (this.appelEntrant) {
			if (this.appelEntrant.contactsTrouves && !!this.appelEntrant.idContact) {
				// Cas ou un seul contact particulier correspond au numéro de téléphone
				let idContactParticulier = this.appelEntrant.idContact;
				let contact: ContactModel = new ContactModel();

				// Si le contact est déjà ouvert, j'initialise le contact à ouvrir avec les info dans contactService
				if (this.contactsComponentService.getCurrentOpenedContact() && this.contactsComponentService.getCurrentOpenedContact().id == idContactParticulier) {
					contact = this.contactsComponentService.getCurrentOpenedContact();
				} else {
					// Sinon on initilise le contact avec les informations de base (id, type)
					contact.id = idContactParticulier;
					contact.type = TypeContact[TypeContact.PERSONNE_PHYSIQUE];
				}

				// Modifie l'objet en session pour indiquer au moteur de recherche qu'il devra se réinitialiser
				let appelEntrant: AppelEntrant = this.storage.load(LS_TELEPHONIE_APPEL_ENTRANT);
				appelEntrant.initialiserMoteurRecherche = true;
				this.storage.persist(appelEntrant, LS_TELEPHONIE_APPEL_ENTRANT);

				this.telephonieService.notifieTraitementAppelEntrant(this.appelEntrant);
				jQuery(".modal").modal("hide");
				this.contactsComponentService.openContact(contact);
			} else {
				// Cas où 0 ou plusieurs contact(s) particulier(s) correspond(ent) au numéro de téléphone

				// Modifie l'objet en session pour indiquer au moteur de recherche qu'il devra se réinitialiser
				let appelEntrant: AppelEntrant = this.storage.load(LS_TELEPHONIE_APPEL_ENTRANT);
				appelEntrant.initialiserMoteurRecherche = true;
				this.storage.persist(appelEntrant, LS_TELEPHONIE_APPEL_ENTRANT);

				// Redirection vers le moteur de recherche
				jQuery(".modal").modal("hide");
				this.router.navigate(['/contacts/particuliers']).then((result) => {
					this.telephonieService.notifieTraitementAppelEntrant(appelEntrant);
				});
			}
		}
	}

	logout(force = false) {
		if (force) {
			this.storage.remove(LS_CONTACT);
			this.storage.remove(LS_AUTHENTIFICATION_SESSION_DATE);
		}
		this.appState.deconnecter();
	}

	masquerBandeauAppelEntrant() {
		this.appelEntrant.afficherBandeau = false;
		this.appelEntrant.initialiserMoteurRecherche = false;
		this.storage.persist(this.appelEntrant, LS_TELEPHONIE_APPEL_ENTRANT);
	}

	closeMessage(message: GlobalMessageModel) {
		this.messages = this.messages.filter((instance) => instance.id != message.id);
	}

	/**
	 * Gestion des actions particulières au clic sur le bandeau d'un message.
	 * @param message le message à gérer
	 */
	clickMessage(message: GlobalMessageModel) {
		switch (message.type) {
			case 1:
				this.messages = this.messages.filter((instance) => instance.id != message.id);
				break;
			default:
				return;
		}
	}

	ngOnInit() {
		console.log("initialise app");

		this.utilisateur = this.utilisateurService.getUtilisateurConnecte();
		if (this.utilisateur) {
			this.roleCentreDocumentaire = this.utilisateurService.hasRole("ROLE_CENTRE_DOCUMENTAIRE");
			this.roleAdministrationPortefeuille = this.utilisateurService.hasRole("ROLE_ADMINISTRATION_PORTEFEUILLE");
			this.roleBatch = this.utilisateurService.hasRole("ROLE_BATCH");

			// Lancement Usersnap
			this.usersnapService.setup(this.utilisateur);
			this.usersnapService.loadScript();
		}


		//Permet que les calendriers soit en Francais
		moment.locale('fr');

		const body: JQuery = jQuery('body') as JQuery;

		function setClasses() {
			if (window.innerWidth <= 768)
				body.addClass('xs mobile-screen');
			else if (window.innerWidth <= 992)
				body.addClass('sm mobile-screen');
			else if (window.innerWidth <= 1200)
				body.addClass('md desktop-screen');
			else
				body.addClass('lg desktop-screen');
		}

		body.resize(() => {
			setClasses();
		});
		setClasses();


	}

	/**
	 * Permet de fermer le drop-down qui remplace le menu du haut sur mobile.
	 */
	fermerMenuMobile() {
		//cette façon de faire évite de faire "si innerWith < xxxxPx;
		$(".backdrop-top-menu.page").filter(":visible").trigger("click");
	}

	//<editor-fold desc="Section Integration">
	checkIntegration() {
		if (!this.utilisateurService.hasRole('ROLE_RESPONSABLE')) return;

		// NOTE: Recupération du nombre de contact à valider
		const criteres = {parPage: 1};

		this.contactIntegrationResource.query(criteres).subscribe(
			({count}: PaginateResponseModel<ContactModel>) => {
				if ((this.nombreIntegration = count)) this.afficherBandeauIntegration();
			},
			erreur => console.error('ContactIntegrationComponent', erreur)
		);
	}

	lancerIntegration() {
		this.masquerBandeauIntegration();
		this.fullModalService.openFullModal('contact-integration-full-modale');
	}

	afficherBandeauIntegration() {
		this.bandeauIntegrationAffiche = true;
	}

	masquerBandeauIntegration() {
		this.bandeauIntegrationAffiche = false;
	}

	//</editor-fold> Section Integration


	/**
	 * Permet d'afficher le contenu du token au besoin.
	 * @returns {any}
	 */
	debugToken() {
		let jwt = this.storage.load(LS_AUTHENTIFICATION_TOKEN);

		if (!jwt)
			return "not Loaded";
		return new JwtHelperService().decodeToken(jwt.access_token);
	}
}
