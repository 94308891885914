import { ErrorHandler, Injectable, Injector } from "@angular/core";

@Injectable({
  providedIn: 'root'
})
export class AppErrorHandler implements ErrorHandler {

	/**
	 *
	 * @param {Injector} injector On utilise injector pour briser une dépendence circulaire
	 */
	constructor(private injector: Injector) {
	}

	//ancienne version inutilisée appelant core-api pour logger les erreurs
	// handleError(error) {
	// 	try {
	//
	//
	// 		let router = this.injector.get(Router);
	// 		let contactService = this.injector.get(ContactsComponentsService);
	// 		let utilisateurService = this.injector.get(UtilisateurService);
	// 		let localStorage = this.injector.get(LocalStorageService);
	// 		let logResource = this.injector.get(LogResource);
	//
	//
	// 		let contact = JSON.stringify(contactService.getCurrentOpenedContact());
	// 		let route = router.url;
	// 		let utilisateur = JSON.stringify(utilisateurService.getUtilisateurConnecte());
	// 		let message = {
	// 			message: "Exception: " + JSON.stringify(error.toString()),
	// 			stack: error.stack,
	// 			contact: Utils.sanitize(contact),
	// 			route: route,
	// 			utilisateur: utilisateur,
	// 			token: localStorage.load(LS_AUTHENTIFICATION_TOKEN)
	// 		};
	// 		console.error("Exception:", message);
	// 		console.error("Rapport d'exception: ", JSON.stringify(message));
	//
	// 		//si on est authentifié, on peut envoyer un log, sinon, tant pis...
	// 		if (message.token) {
	// 			logResource.save(message);
	// 		}
	// 		else
	// 			console.error("Impossible d'envoyer un rapport d'erreur sans token");
	//
	//
	// 	}
	// 	catch (e) {
	// 		let logResource = this.injector.get(LogResource);
	// 		let message = {
	// 			message: "exception during exception",
	// 			innerException: error,
	// 			stack: error.stack,
	// 			exception: e,
	// 			token: localStorage.load(LS_AUTHENTIFICATION_TOKEN)
	// 		};
	// 		if (message.token) {
	// 			logResource.save(message);
	// 		}
	// 		else
	// 			console.error("Impossible d'envoyer un rapport d'erreur sans token");
	// 	}
	// 	// IMPORTANT: Rethrow the error otherwise it gets swallowed
	// 	if (error.stack)
	// 		console.error(error.stack);
	//
	// }

	handleError(error) {
		console.error(error);
	}
}
