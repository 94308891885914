import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

//Charge les variables d'environnement avant de lancer l'application
let xhr: XMLHttpRequest = new XMLHttpRequest();
xhr.open('GET', './assets/environments/selected/environment.json');
xhr.onload = function () {
	if (xhr.status === 200) {
		try {
			let environmentData = JSON.parse(xhr.responseText);
			Object.keys(environmentData).forEach(key=>{
				environment[key] = environmentData[key];
			});
			if (environment.production) {
				enableProdMode();
			}

			platformBrowserDynamic().bootstrapModule(AppModule);
		} catch (e) {
			console.error(e);
			alert("Problème d'initialisation de l'application SPS.")
		}

	}
	else {
		alert("Problème d'initialisation de l'application SPS.");
	}
};
xhr.send();

