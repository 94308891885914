/**
 * Created by "Juanito Goncalves <juanito.goncalves@scub.net>" on 27/07/16.
 */
import { Injectable } from "@angular/core";
import { ResourceParams } from "@ngx-resource/core";
import { PaginateResource } from "../sps-resource";
import { EvenementModel } from "../../models/commun/evenement/evenement.model";

@Injectable({
  providedIn: 'root'
})
@ResourceParams({
	// Api path
	path: '/personnes/{typeContact}/{idPersonne}/evenements/{idEvenement}'
})
export class EvenementPersonnePaginateResource extends PaginateResource<EvenementModel> {
}
