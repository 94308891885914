import { Component, Input } from "@angular/core";
import { UtilisateurService } from "../../../services/utilisateur.service";
import { UtilisateurConnecte } from "../../../models/utilisateur/utilisateur.model";
import { FormBuilder, FormGroup } from "@angular/forms";
import { PortefeuilleUtilisateur } from "../../../models/utilisateur/portefeuille.utilisateur.model";
import {
	LocalStorageService,
	LS_AUTHENTIFICATION_SESSION_DATE,
	LS_AUTHENTIFICATION_TOKEN,
	LS_CONTACT,
	LS_UTILISATEUR_CONNECTE
} from "../../../services/LocalStorage.service";
import { GlobalMessageService } from "../../../services/global.message.service";
import { TranslateService } from "@ngx-translate/core";
import { ContactsComponentsService } from "../../../services/contacts/contacts.service";
import { IdLibelleModel } from "../../../models/referentiels/id.libelle.model";
import { Router } from "@angular/router";
import { UtilisateurResource } from "../../../resources/utilisateur/utilisateurs.resource";
import { TelephonieService } from "../../../services/telephonie.service";
import { UtilisateurPortefeuilleResource } from "../../../resources/utilisateur/utilisateur.portefeuille.resource";
import { PortefeuilleResource } from "../../../resources/portefeuilles.resource";
import { AppState } from "../../../app.service";
import { MobileNavbarService } from "../../../services/mobile.navbar.service";
import { Observable } from "../../../../../node_modules/rxjs";
import { FeatureFlippingService } from '../../../services/feature.flipping.service';

@Component({
	selector: 'sps-connected-user',
	templateUrl: './connected.user.template.html',
	styleUrls: ['./connected.user.style.less']
})
export class ConnectedUserComponent  {
	@Input() theme: 'default' | 'left' | null = null;

	/**
	 * Formulaire en charge du changement de portefeuille
	 */
	portefeuilleForm: FormGroup;
	contextCourant: IdLibelleModel;
	portefeuilleParDefaut: boolean;
	ouvertureParamPortefeuille: boolean = false;
	changePasswordOpen: boolean = false;
	public masquerLogout: boolean;
	public masquerChangementMotDePasse: boolean;

	utilisateur: UtilisateurConnecte = new UtilisateurConnecte();
	/**
	 * Clés à ne pas supprimer au changement de portefeuille.
	 * @type {[{key: string, params: {}}, {key: string, params: {}}]}
	 */
	private cleAGarder: Array<any> = [
		{
			key: LS_AUTHENTIFICATION_TOKEN,
			params: {}
		},
		{
			key: LS_UTILISATEUR_CONNECTE,
			params: {}
		}
	];

	constructor(private utilisateurService: UtilisateurService,
				private messenger: GlobalMessageService,
				private contactsComponentService: ContactsComponentsService,
				public appState: AppState,
				private router: Router,
				private utilisateurPortefeuilleResource: UtilisateurPortefeuilleResource,
				private utilisateurResource: UtilisateurResource,
				private telephonieService: TelephonieService,
				public portefeuilleResource: PortefeuilleResource,
				private navbarService: MobileNavbarService,
				private translate: TranslateService,
				private fb: FormBuilder,
				private storage: LocalStorageService,
				private featureFlippingService: FeatureFlippingService,
				private messageService: GlobalMessageService) {

	}

	blurParamPortefeuille() {
		if (this.ouvertureParamPortefeuille) this.ouvertureParamPortefeuille = false;
	}

	ngOnInit() {
		this.portefeuilleForm = this.fb.group({
			portefeuille: [null]
		});
		this.verifierAutorisationsFeatureFlipping();
		this.utilisateurService.utilisateurConnecteObs.subscribe((connecte: boolean) => {
			this.utilisateur = this.utilisateurService.getUtilisateurConnecte();
			this.portefeuilleForm.get('portefeuille').reset(this.utilisateur.portefeuille);
			this.verifierAutorisationsFeatureFlipping();
		});
		this.utilisateur = this.utilisateurService.getUtilisateurConnecte();
		if (this.utilisateur) {
			this.portefeuilleForm.get('portefeuille').reset(this.utilisateur.portefeuille);
			this.contextCourant = this.utilisateur.portefeuille;
		}
		this.portefeuilleForm.get('portefeuille').valueChanges.subscribe((chgtPortefeuille: PortefeuilleUtilisateur) => {
			if (chgtPortefeuille && this.utilisateur && this.utilisateur.portefeuille && chgtPortefeuille.libelle != this.utilisateur.portefeuille.libelle) {
				this.contextCourant = chgtPortefeuille;
				let obs: Observable<any> = this.appState.obsActionAvantDeco();
				if (obs) {
					obs.subscribe(() => {
						this.appState.actionAvantDecoRealiseeSub.next(true);
						this.appState.clearActionAvantDeconnexion();
						this.changementContext();
					}, errors => {
						this.changementContext();
					});
				} else {
					this.changementContext();
				}
			}
		});
		this.refreshPortefeuilleDefaut();
	}

	private verifierAutorisationsFeatureFlipping(): void {
		this.masquerLogout = this.featureFlippingService.hasFeatureRight('MASQUER_UTILISATEUR_LOGOUT');
		this.masquerChangementMotDePasse = this.featureFlippingService.hasFeatureRight('MASQUER_UTILISATEUR_CHANGEMENT_MDP');
	}

	// FIXME : À terme, cette méthode de changement de portefeuille sera dans le composant "RouterChangePortefeuille".
	changementContext() {
		let subscription = this.utilisateurService.refreshTokenPortefeuille(this.contextCourant.id).subscribe(
			() => {
				this.storage.clearExcept(this.cleAGarder);
				this.messenger.showSuccess(this.translate.instant("PORTEFEUILLE.CHANGEMENT.SUCCES", {nom: this.contextCourant.libelle}));
				this.ouvertureParamPortefeuille = !this.ouvertureParamPortefeuille;
				this.contactsComponentService.resetContacts();
				// On utilise une route intermédiaire pour forcer le remontage de la vue "/contacts" et le clear de ses états (si c'est déjà la vue actuelle)
				this.router.navigate(['switch']);
				this.refreshPortefeuilleDefaut();
				this.navbarService.toggleNavbar(false);
				this.telephonieService.resetConnexionTelephonie();
				subscription.unsubscribe();
			},
			() => {
				this.messenger.showError(this.translate.instant("ERREURS.RENOUVELLEMENT_TOKEN"));
				subscription.unsubscribe();
			}
		);
	}

	refreshPortefeuilleDefaut() {
		let utilisateurCourant = this.utilisateurService.getUtilisateurConnecte();
		if (utilisateurCourant) {
			this.utilisateurResource.get({id: utilisateurCourant.id}).subscribe((utilisateur) => {
				let portefeuilleParDefautId = utilisateur && utilisateur.portefeuilleParDefaut;
				this.portefeuilleParDefaut = portefeuilleParDefautId == utilisateurCourant.portefeuille.id;
			})
		}
	}

	changePortefeuilleDefaut() {
		let utilisateurCourant = this.utilisateurService.getUtilisateurConnecte();
		if (utilisateurCourant) {
			let param : any = {
				idPortefeuille : utilisateurCourant.portefeuille.id
			}
			this.utilisateurPortefeuilleResource.update(param).subscribe(projet => {
					this.messageService.showSuccess(this.translate.instant("PORTEFEUILLE.DEFAUT.SUCCES"));
				},
				err => {
					this.messageService.showError(this.translate.instant("PORTEFEUILLE.DEFAUT.ERREUR"));
				}
			);
		}
	}

	portefeuilleResultTransformer(res) {
		return res ? (res.entiteJuridique ? res.entiteJuridique.libelle + " - " : "") + res.libelle : '';
	};

	changerPassword() {
		jQuery('#confirm-recuperation-pasword').modal('show');
	}


	logout(force = false) {
		if (force) {
			this.storage.remove(LS_CONTACT);
			this.storage.remove(LS_AUTHENTIFICATION_SESSION_DATE);
		}
		this.appState.deconnecter();
	}
}
