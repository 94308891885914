import {ScrollUtils} from './ScrollUtils';

/**
 * Created by mcalvez on 23/02/17.
 */
export class Utils {

	public static scrollToElement(id: string){

		setTimeout(function() {
			ScrollUtils.scrollToElm(document.getElementById("page-scroller"), document.getElementById(id),  0.3)
		},0)
	}

	public static scrollTop(duration: number){
		setTimeout(function() {
			ScrollUtils.scrollTo(document.getElementById("page-scroller"), 0,  0.1)
		},0)
	}

	/**
	 * Compare 2 objets selon le résultat du tri de leurs propriétés par ordre alphabétique et le passage en JSON.stringify
	 * @param object1
	 * @param object2
	 * @returns {boolean} true si les 2 objets sont identiques
	 */
	public static equals(object1: any, object2: any): boolean {
		return (object1 && JSON.stringify(Object.keys(object1).sort().reduce((r, k) => (r[k] = object1[k], r), {}))) == (object2 && JSON.stringify(Object.keys(object2).sort().reduce((r, k) => (r[k] = object2[k], r), {})));
	}

	/**
	 * Supprime les attributs ngx-resouce d'un objet.
	 * @param obj
	 * @returns {any}
	 */
	public static sanitize(obj: any): any {

		if (!obj) return obj;

		delete obj["$resource"];
		delete obj["$resolved"];
		delete obj["$abortRequest"];

		return obj;
	}

	/**
	 * Cats un un string contenant un boolean en boolean. Si autre chose que true est contenue dans value, alors return false.
	 * @param value
	 */
	public static castStringToBoolean(value: string) {
		return value && value.toLowerCase().trim() == "true";
	}

	/**
	 * Convertit un enum en tableau de string.
	 * @param enumClass
	 * @param tri
	 * @returns {Array<string>}
	 */
	public static enumToStringArray(enumClass: any, tri: string = null): Array<string> {
		let tableau = Object.keys(enumClass).map(k => enumClass[k]).filter(v => typeof v === 'string') as Array<string>;
		if (!tri) return tableau;
		return tableau.sort((a, b) => (tri === 'ASC' ? Utils.strcmp(a, b) : Utils.strcmp(b, a)));
	}

	public static deepEmpty(obj: any, deep: number = 10): boolean {
		if (!obj) {
			return true;
		}
		if (deep <= 0) {
			return false;
		}
		if (Array.isArray(obj)) {
			return obj.every(v => Utils.deepEmpty(v, deep - 1));
		}
		if (typeof obj == 'object') {
			return Object.keys(obj).every(k => Utils.deepEmpty(obj[k], deep - 1));
		}
		return false;
	}

	//<editor-fold desc="Section Manipulation Date">

	/**
	 * Calcule la différence de temps entre une date donnée et 'maintenant'.
	 * @param {string} dateString - date au format 'DD/MM/YYYY'
	 * @return {string} - différence, au format 'X an(s) et X mois' ou 'X mois et X jour(s)'
	 */
	public static dateDiffFormateeDepuisDate(dateString: string): string {
		return Utils.dateDiffFormatee(Utils.dateDiffBetweenDates(new Date(), Utils.dateStringToDateIso(dateString)));
	}

	/**
	 * Transforme une date au format 'DD/MM/YYYY' en objet Date.
	 * @param {string} dateString
	 * @return {Date}
	 */
	public static dateStringToDateIso(dateString: string): Date {
		if (!dateString) {
			return null;
		}

		let dateHeure = dateString.split(" ");
		let datePart = dateHeure[0].split('/');
		let year = parseInt(datePart[2]);
		let month = parseInt(datePart[1]) - 1;
		let day = parseInt(datePart[0]);

		let hour = 0;
		let minute = 0;
		let second = 0;

		if (dateHeure[1]) {
			let timePart = dateHeure[1].split(':');
			hour = parseInt(timePart[0]);
			minute = parseInt(timePart[1]);
			if (timePart[2]) {
				second = parseInt(timePart[2]);
			}
		}

		return new Date(year, month, day, hour, minute, second);
	}

	/**
	 * Calcule la difference de temps entre deux objet Date.
	 * @param {Date} dateA
	 * @param {Date} dateB
	 * @return {{years: number; months: number; days: number}}
	 */
	public static dateDiffBetweenDates(dateA: Date, dateB: Date) {
		if (dateA < dateB) {
			let t = dateA;
			dateA = dateB;
			dateB = t;
		}
		let years = dateA.getFullYear() - dateB.getFullYear();
		let months = dateA.getMonth() - dateB.getMonth();
		let days = dateA.getDate() - dateB.getDate();

		if (days < 0) {
			months -= 1;
			days += Utils.daysInMonth(dateB.getMonth(), dateB.getFullYear());
		}
		if (months < 0) {
			years -= 1;
			months += 12;
		}

		return {years, months, days};
	}

	/**
	 * Retourne le nombre de jour dans un mois donné.
	 * @param {number} month - mois souhaité
	 * @param {number} year - année, pour le nombre de jours de février en fonction des années bisextiles
	 * @return {number}
	 */
	public static daysInMonth(month: number, year: number): number {
		switch (month) {
			case 0:
			case 2:
			case 4:
			case 6:
			case 7:
			case 9:
			case 11:
				return 31;
			case 3:
			case 5:
			case 8:
			case 10:
				return 30;
			case 1:
				if (year % 4 === 0 && year % 100 !== 0 || year % 400 === 0)
					return 29;
				else
					return 28;
		}
	}

	/**
	 * Formatage d'une difference de temps.
	 * @param {number} years - nombre années
	 * @param {number} months - nombre de mois
	 * @param {number} days - nombre de jours
	 * @return {string} différence, au format 'X an(s) et X mois' ou 'X mois et X jour(s)'
	 */
	public static dateDiffFormatee({years, months, days}: { years: number, months: number, days: number }): string {
		return (years ? `${years} an${years > 1 ? 's' : ''}${months || days ? ' et ' : ''}` : '')
			+ (months ? `${months} mois${!years && days ? ' et ' : ''}` : '')
			+ ((!years || !months) && days ? `${days} jour${days > 1 ? 's' : ''}` : '');
	}

	/**
	 * Compare deux dates au format 'JJ/MM/AAAA'. Fonction de tri.
	 * @param {string} a
	 * @param {string} b
	 * @returns {number}
	 */
	public static datecmp(a: string, b: string): number {
		if (!a || !b) {
			return (a && -1) || (b && 1) || 0;
		}
		let date1 = a.split('/');
		let date2 = b.split('/');
		return +date1[2] - +date2[2]
			|| +date1[1] - +date2[1]
			|| +date1[0] - +date2[0];
	}

	/**
	 * Retourne un objet Date en une string au format 'JJ/MM/AAAA'.
	 * @param {Date} date
	 * @returns {string}
	 */
	public static dateIsoToDateString(date: Date): string {
		return `${Utils.leadingZero(date.getDate())}/${Utils.leadingZero(date.getMonth() + 1)}/${date.getFullYear()}`;
	}

	/**
	 * Retourne un objet Date en une string au format 'JJ/MM/AAAA HH:MM'
	 * @param {Date} date
	 * @returns {string}
	 */
	public static dateIsoToDateTimeString(date: Date): string {
		return Utils.dateIsoToDateString(date) + ` ${Utils.leadingZero(date.getHours())}:${Utils.leadingZero(date.getMinutes())}`;
	}

	/**
	 * Retourne une date string depuis une dateTime string
	 * @param date la date
	 */
	public static dateTimeStringToDateString(date: string): string {
		const split = date.split(' ');
		if (split && split.length) {
			return split[0];
		}
		return null;
	}

	/**
	 * Si le nombre est entre 0 et 9 ajout d'un zero, utile pour les dates.
	 * @param {number} time
	 * @returns {string}
	 */
	public static leadingZero(time: number): string {
		return (time < 10 ? '0' : '') + time;
	}

	//</editor-fold> Section Manipulation Date

	/**
	 * Clone un objet.
	 * @param object
	 * @returns {any}
	 */
	public static clone(object: any) {
		let clone = Utils.safeJSONparse(JSON.stringify(object));
		if (clone === undefined) {
			console.error(`error: Utils.clone(${object}) => attention, référence renvoyée, pas une copie`);
			return object;
		}
		return clone;
	}

	/**
	 * Remplace tous les caractères d'un string par un autre.
	 * @param {string} stringEntry
	 * @param {string} match
	 * @param {string} replace
	 * @returns {string}
	 */
	public static replaceAll(stringEntry: string, match: string, replace: string): string {
		return stringEntry.split(match).join(replace);
	}

	/**
	 * Formate le message d'erreur retourné par le back.
	 * @param erreur Objet erreur
	 * @param {string} msgDefaut message d'erreur par defaut
	 * @returns {string} msg erreur
	 */
	public static formaterErreurBack(erreur: any, msgDefaut: string): string {
		if (!erreur) return msgDefaut;

		if (erreur.status == 404) {
			return msgDefaut || "Une erreur est survenue, veuillez réessayer.";
		}
		if (erreur.message) {
			if (erreur.status) {
				return erreur.message.replace(erreur.status + ' ', '');
			}
			return erreur.message;
		}
		if (erreur.body) {
			if (erreur.body.message) {
				if (erreur.status) {
					return erreur.body.message.replace(erreur.status + ' ', '');
				}
				return erreur.message;
			}
		}
		return msgDefaut
	}

	/**
	 * Supprime récursivement les propriétées vide d'un objet.
	 * - supprime les propriétés liées au module `ngx-resource`
	 * - supprime également la propriété 'libelleAffiche'
	 * @param value
	 * @returns {any}
	 */
	public static cleanObjet(value): any {
		try {
			if (!value) {
				return value;
			}

			value = Utils.sanitize(value);

			Object.keys(value).forEach((key: any) => {
				if (key === "libelleAffiche") {
					delete value[key];
				}
				if (!value[key]) {
					delete value[key];
				} else if (typeof value[key] == "object") {
					let clean = this.cleanObjet(value[key]);
					let cleanKeys = Object.keys(clean);
					if (!(cleanKeys && cleanKeys.length)) {
						delete value[key];
					}
				}
			});

		} catch (e) {
			console.error("Erreur lors de Utils.cleanObjet ", e, 'attention, objet non nettoyé renvoyé objet:', value);
		}


		return value;
	}

	/**
	 * Utilisation de JSON.parse() sans throw, retourne undefined en cas d'erreur.
	 * @param json
	 * @returns {any}
	 */
	public static safeJSONparse(json: any) {
		let obj: any;
		try {
			obj = JSON.parse(json);
		} catch (e) {
			console.error(`error: Utils.safeJSONparse(${json})`, e);
		}
		return obj;
	}

	/**
	 * Renvoi un objet avec les clés et valeurs de propriété inversée.
	 * @param obj
	 * @returns {{}}
	 */
	public static swapKeyWithValue(obj: any): any {
		return Object.keys(obj).reduce((acc, cur) => (acc[obj[cur]] = cur, acc), {});
	}

	/**
	 * Compare deux chaine de caracteres.
	 * @param {string} a
	 * @param {string} b
	 * @returns {number}
	 */
	public static strcmp(a: string, b: string): number {
		return a == b ? 0 : (a < b ? -1 : 1);
	}

	/**
	 * Déplace l'élement spécifié au début du tableau.
	 * @param {Array<any>} array - tableau
	 * @param {number} index - position de l'élément
	 */
	public static arrayMoveFirst(array: Array<any>, index: number) {
		if (index > 0) array.unshift(array.splice(index, 1)[0]);
	}

	/**
	 * Retourne un objet composé des propriétés selectionnées d'un objet.
	 * @param obj - objet d'origine
	 * @param {Array<string>} keys - liste des proriétés
	 * @returns {{}}
	 */
	public static objectPick(obj: any, keys: Array<string>) {
		return keys.reduce((sum, k) => (sum[k] = obj[k], sum), {});
	};

	/**
	 * Supprime les propriétés vide d'un objet (undefined, null, '', 0)
	 * @param obj
	 */
	public static objectClean(obj: any) {
		if (obj) Object.keys(obj).forEach((k) => !obj[k] && delete obj[k]);
	}

	/**
	 * Retourne null si string chaine vide ou composé que d'espaces, sinon retourne string d'entré.
	 * @param param la string à controler
	 */
	public static stringClean(param: string) {
		if (param != null) {
			if (param == "" || param.trim() == "") return null;
		}
		return param;
	}

	/**
	 * Check si string null ou vide (espace compris)
	 * @param {string} param
	 * @returns {boolean}
	 */
	public static stringNonRenseignee(param: string) {
		return param == null || param.trim() == "";
	}

	/**
	 * Check si string ni null ni vide (espace compris)
	 * @param {string} param
	 * @return {boolean}
	 */
	public static stringRenseignee(param: string) {
		return !this.stringNonRenseignee(param);
	}

	// Returns a function, that, as long as it continues to be invoked, will not
	// be triggered. The function will be called after it stops being called for
	// N milliseconds. If `immediate` is passed, trigger the function on the
	// leading edge, instead of the trailing.
	public static debounce(func, wait, immediate) {
		let timeout;
		return function () {
			let context = this, args = arguments;
			let later = function () {
				timeout = null;
				if (!immediate) func.apply(context, args);
			};
			let callNow = immediate && !timeout;
			clearTimeout(timeout);
			timeout = setTimeout(later, wait);
			if (callNow) func.apply(context, args);
		};
	};

	/**
	 * Permet de mapper les proppriétes d'un objet à partir d'une classe.
	 * @param obj - objet à mapper
	 * @param cl - instance de la classe
	 * @return {any} - un objet au format de la classe passée en paramètre.
	 */
	public static mapObjectToClass(obj: any, cl: any) {
		let parseObj = (cl) => Object.keys(cl).map(
			k => (cl[k] === Object(cl[k]) ? [k, parseObj(cl[k])] : k)
		);
		return this.mapObjectProperties(obj, parseObj(cl));
	};

	/**
	 * Permet de mapper un objet à partir d'une liste de propriétés
	 *   Exemple du format de la liste :
	 *     { propA: null, propB: { sousPropA: null, sousPropB: null } }
	 *     `-> ['propA', ['propB', ['sousPropA', 'sousPropB']]]
	 * @param obj - l'objet à mapper
	 * @param {Array<any>} keys - la liste de propriétés
	 * @return {any} - l'objet mapper
	 */
	public static mapObjectProperties(obj: any, keys: Array<any>) {
		return keys.reduce((sum, k) => (Array.isArray(k) ? sum[k[0]] = this.mapObjectProperties(obj[k[0]], k[1]) : sum[k] = obj && obj[k] || null, sum), {});
	}

	/**
	 * Renvoi un nouvel objet sans les propriété vides (undefined, null) d'un objet donné.
	 * @param obj - l'objet source à vider
	 * @return {any} - l'objet vidé
	 */
	public static removeEmptyProperties(obj: any): any {
		return Object.keys(obj).reduce((sum, k) => (obj[k] != null && (sum[k] = obj[k]), sum), {});
	}


	public static isUndefined(value : any): boolean{
		return value === undefined
	}

	public static isDefined(value: any): boolean {
		return value !== undefined && value !== null;
	}

	public static isString(value : any): boolean{
		return typeof value === 'string';
	}

	public static requiredProperties(obj: any, keyNames: string[]) {
		for (let keyName of keyNames) {
			if (!obj[keyName]) {
				throw new Error(`${keyName} not found, ${this.constructor.name} required ${keyNames}`)
			}
		}
	}


	/**
	 * Fonction d'util pour vérifier que deux tableaux sont égaux.
	 * @param array1 premier tableau
	 * @param array2 deuxieme tableau
	 */
	public static arrayAreEqual(array1, array2) {
		if (array1.length === array2.length) {
			return array1.every(element => {
				if (array2.includes(element)) {
					return true;
				}

				return false;
			});
		}

		return false;
	}

	/**
	 * Ajoute nbr jours calandaires à une date.
	 * @param nbrJour nombre de jours
	 * @param dateParam date à modifier
	 */
	public static ajouterJourADate(nbrJour: string, dateParam: string): string {
		let dateIso: Date = Utils.dateStringToDateIso(dateParam);
		let nbrJourTimeStamp = Number(nbrJour) * 24 * 60 * 60*1000;
		let dateResult = new Date(dateIso.getTime() + nbrJourTimeStamp);
		return  dateResult.toLocaleDateString();
	}

	/**
	 * Renvoie la chaîne donnée en paramètre avec les diacritiques(accents, cédille, etc) en moins : String.prototype.normalize renvoie l'équivalent unicode de la chaîne,
	 * qui en mémoire sépare les lettres elles-mêmes et les accents, et String.prototype.replace enlève les accents.
	 * La regex de replace représente la range unicode des diacritiques.
	 * @param str La chaîne à transformer
	 * @return La chaîne sans les diacritiques
	 */
	public static unaccent(str: string): string {
		if (str == null) {
			return null;
		}
		return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
	}
}
