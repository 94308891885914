import { ResourceParams } from "@ngx-resource/core";
import { Injectable } from "@angular/core";
import { SPSResource } from "./sps-resource";


@Injectable({
  providedIn: 'root'
})
@ResourceParams({
	// Api path
	path: '/contexte-utilisateurs'
})
export class PortefeuilleResource extends SPSResource<any, any, any, any> {
}
