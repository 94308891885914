import { Injectable, Type } from "@angular/core";
import {
	IResourceActionInner,
	IResourceResponse,
	Resource,
	ResourceHandler,
	ResourceRequestMethod
} from "@ngx-resource/core";
import { Observable, Subject } from "rxjs";
import { environment } from "environments/environment";
import { ResourceCRUDBase } from "./sps.resource.implementation";
import { AuthGuard } from "../../guards/auth.guard";
import {
	LocalStorageService,
	LS_AUTHENTIFICATION_TOKEN,
	LS_UTILISATEUR_CONNECTE
} from "../../services/LocalStorage.service";
import { UtilisateurService } from "../../services/utilisateur.service";
import { AuthentificationModel } from "../../models/authentification.model";
import { PaginateResponseModel } from "../../models/paginate.response.model";

// permet de n'évaluer environment.API_URL qu'une fois que la valeur est set avant l'initialisation de l'app.
export function OverrideResource(version: string = "v1") {
	return function (target: Type<Resource>) {
		target.prototype.$getUrl = function () {
			//Par défaut => v1
			if (version == "v1") {
				return environment.API_URL
			} else if (version == "v2") {
				return environment.API_URL_V2;
			} else {
				throw ("Version demandée de la ressource non paramétrée dans SPSRessource. Version demandée :" + version);
			}
		}
	}
}

@Injectable({
  providedIn: 'root'
})
@OverrideResource()
export class SPSResource<TQuery, TKeys, TShort, TFull> extends ResourceCRUDBase<TQuery, TKeys, TShort, TFull> {

	constructor(resourceHandler: ResourceHandler,
				private storage: LocalStorageService,
				private utilisateurService: UtilisateurService) {
		super(resourceHandler);
	}

	$restAction(options: IResourceActionInner): Observable<TFull> {

		if (AuthGuard.isSessionValid()) {
			let auth: AuthentificationModel = this.storage.load(LS_AUTHENTIFICATION_TOKEN) as AuthentificationModel;
			let token = auth.token_type;
			token = token + " " + auth.access_token;
			super.$setHeaders({"Authorization": token});
		} else {
			// Redirection nécessaire
			setTimeout(() => {
				this.storage.clear();
				console.debug("redirection logout");
				AuthGuard.redirigerVersLogout();
			}, 4000);
		}

		return  super.$restAction(options);;
	}

	protected $handleSuccessResponse(options: IResourceActionInner, resp: IResourceResponse): any {
		const paginate = options.actionOptions.isPaginated;

		if (paginate) {
			let ret = new PaginateResponseModel<any>();
			ret.data = super.$handleSuccessResponse(options, resp);
			ret.count = +(resp.headers).get("X-Total-Count") || 0;
			ret.code = resp.status;
			return ret;
		}

		/**
		 * SI on reçoit un code 307 c'est que un provider est down et donc, on a pas vraiment les données.
		 * On gére cela comme une erreur.
		 */
		if (resp.status == 307){
			return this.$handleErrorResponse(options, resp);
		}

		return super.$handleSuccessResponse(options, resp);
	}

	protected $handleErrorResponse(options: IResourceActionInner, error: IResourceResponse): IResourceResponse {

		console.error("Erreur lors de l'appel à la resource", error, error.headers);
		let authentification: AuthentificationModel = this.storage.load(LS_AUTHENTIFICATION_TOKEN);
		if (authentification && (error.status === 401)) {
			// Le status ne remonte pas à 401... je ne trouve pas d'information relative au 401 attendu
			// La requète a échoué (avec un code 401), on tente de renouveler le jeton de sécurité
			let formData = new FormData();
			formData.append("grant_type", "refresh_token");
			formData.append("refresh_token", authentification.refresh_token);
			formData.append("redirect_uri", window.location.origin + "/" + "contacts");

			let request = new XMLHttpRequest();

			const utilisateur = this.storage.load(LS_UTILISATEUR_CONNECTE);
			let url = environment.AUTH_URL + "/oauth/token?idPortefeuille=" + (utilisateur && utilisateur.portefeuille.id);
			request.open("POST", url, false);
			// La requête doit contenir les information d'authentification de l'application
			request.setRequestHeader("Authorization", "Basic " + btoa(environment.AUTH_APPNAME + ":" + environment.AUTH_SECRET));
			request.send(formData);

			if (request.status === 200){

				let response = JSON.parse(request.response);
				// initialisation de la presence d'un token
				if (response.access_token) {
					// Le jeton a bien été renouvellé
					// Mise à jour des information d'authentification dans la session
					this.utilisateurService.mettreAJourUtilisateur(response);
					this.retry(options);

				} else if (response.error) {
					this.storage.remove(LS_AUTHENTIFICATION_TOKEN);
					console.error("Erreur lors du renouvellement du jeton de sécurité !!!!!!!", request.getAllResponseHeaders(), response.error);
					if (response.error == "unauthorized") {
						AuthGuard.alerteDesactivation();
					} else {
						AuthGuard.alerteSessionInvalide();
					}
					return  error;
				}
			}
			else {
				// En cas d'erreur de renouvellement du jeton.
				this.storage.remove(LS_AUTHENTIFICATION_TOKEN);
				let response = JSON.parse(request.response);
				console.error("Erreur lors du renouvellement du jeton de sécurité", request.getAllResponseHeaders(), response.error);
				AuthGuard.alerteDesactivation();
				return error;
			}
		}
		else {
			// La requete a échoué avec un code erreur autre que 401, on remonte l'information à l'appelant
			return error;
		}
	}

	retry(options){
		let auth = this.storage.load(LS_AUTHENTIFICATION_TOKEN);

		const originalRequest = options.requestOptions;
		const paginate = options.actionOptions.isPaginated;

		// Rappel de la précédente requete avec les nouvelles informations d'authentification.
		let previousRequest = new XMLHttpRequest();
		previousRequest.open(ResourceRequestMethod[originalRequest.method], originalRequest.url, false);
		previousRequest.setRequestHeader("Authorization", auth.token_type + " " + auth.access_token);

		//On récupère le champ accept de la requête précédente
		if (originalRequest && originalRequest.headers && originalRequest.headers["accept"]) {
			previousRequest.setRequestHeader("Accept", originalRequest.headers["accept"]);
		} else {
			previousRequest.setRequestHeader("Accept", "application/json");
		}

		//On récupère le content-type de la requête précédent, si aucune on met "application/json"
		//Par defaut XMLHttpRequest met du plain/text mais cela est refusé par le back
		if (originalRequest && originalRequest.headers && originalRequest.headers["content-type"]) {
			previousRequest.setRequestHeader("Content-Type", originalRequest.headers["content-type"]);
		} else {
			previousRequest.setRequestHeader("Content-Type", "application/json");
		}

		previousRequest.send(originalRequest.body);

		if (previousRequest.status === 200) {
			const resp = super.$handleErrorResponse(options, previousRequest.response);
			if (paginate) {
				let ret = new PaginateResponseModel<any>();
				ret.data = resp;
				ret.count = +previousRequest.getResponseHeader("X-Total-Count") || 0;
				ret.code = previousRequest.status;
				return ret;
			} else {
				return resp;
			}
		}
		else {
			const resp = super.$handleErrorResponse(options, previousRequest.response);
			return resp;
		}

	}
}
