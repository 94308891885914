/*
 Template d'environnement rempli par les fichiers d'environnement json au lancement de l'appli
 */


/**
 * *********************************************************************************************
 * ATTENTION: Si une modification est fait sur ce fichier, il faut aussi modifier
 * openstack/sps-generali/install-application-1.sh
 * *********************************************************************************************
 */
export const environment = {
	production: null,
	HOST: null,
	PORT: null,
	HMR: null, // NOTE: unused as using angular-cli instead of webpack-starter. /* helpers.hasProcessFlag('hot'); */
	API_BASE_URL: null,
	API_URL: null,
	API_URL_V2: null,
	AUTH_URL: null,
	AUTH_APPNAME: null,
	AUTH_SECRET: null,
	TELEPHONIE_URL: null,//wss://cti.rec.sps-solutions.fr/telephonie
	NB_TENTATIVES_RECONNEXION: null,
	BANDEAU_ENV: null,
	BATCH_FRONT_URL: null
};
