/**
 * Service de stockage temporaire des informations en cours d'édition par l'utilisateur courant.
 * Created by "Juanito Goncalves <juanito.goncalves@scub.net>" on 24/08/16.
 */
import {Injectable} from "@angular/core";

//Réferentiels
export const LS_REFERENTIEL = "referentiel";
export const LS_REFERENTIEL_TAG = LS_REFERENTIEL + "-tag";
export const LS_REFERENTIEL_RISQUE = LS_REFERENTIEL + "-risques";
export const LS_REFERENTIEL_RISQUE_PHYSIQUE = LS_REFERENTIEL_RISQUE + "-physique";
export const LS_REFERENTIEL_RISQUE_MORALE = LS_REFERENTIEL_RISQUE + "-morale";
export const LS_REFERENTIEL_EXPEDITEURS_EMAIL = LS_REFERENTIEL + "-expediteurs-email";
export const LS_REFERENTIEL_EXPEDITEURS_COURRIER = LS_REFERENTIEL + "-expediteurs-courrier";

//pour restaurer la page du contact lors d'un re-chargement de contact
export const LS_CONTACT = "contact";
export const LS_CONTACT_ID = "contact-{idContact}";
export const LS_CONTACT_COURITER_ID = "contact-courtier-{idContact}";
export const LS_CONTACT_NUMERO = LS_CONTACT_ID + "-numero";
export const LS_CONTACT_NOTES = LS_CONTACT_ID + "-notes";
export const LS_CONTACT_ACTIVITE_AUTO = LS_CONTACT_ID + "-activite-auto";
export const LS_CONTACT_PAGE_OPENED = LS_CONTACT_ID + "-page-opened";
export const LS_CONTACT_PAGE_ORIGIN = LS_CONTACT_ID + '-page-origin';
export const LS_CONTACT_TYPE_HISTORIQUE_RECHERCHE = LS_CONTACT + "-{typeContact}-historique-recherche";
export const LS_CONTACT_ENTREPRISE = LS_CONTACT + "-entreprise-{id}";
export const LS_CONTACT_ENTREPRISE_PROFIL = LS_CONTACT_ID + "-profil";
export const LS_CONTACT_ENTREPRISE_COORDS = LS_CONTACT_ID + "-coordonnees";
export const LS_CONTACT_ENTREPRISE_REL = LS_CONTACT_ID + "-relations";
export const LS_CONTACT_TYPE_RECHERCHE = "recherche-type-contact";
export const LS_CONTRAT_COTISATIONS = "contratCotisation";
export const LS_CONTACT_TYPE_CRITERES_RECHERCHE = LS_CONTACT + "-{typeContact}-recherche-criteres";
export const LS_CONTACT_TYPE_CRITERES_RECHERCHE_REFRESH = LS_CONTACT_TYPE_CRITERES_RECHERCHE + "-refesh";
export const LS_CONTACT_TYPE_RESULTATS_RECHERCHE = LS_CONTACT + "-{typeContact}-recherche";
export const LS_CONTACT_TYPE_AJOUT_RELATIONS = LS_CONTACT_ID + "-{typeContact}-ajout-relations";

// Routes
export const LS_CONTACT_PRESAVE_ROUTES_INFORMATIONS = LS_CONTACT_ID + "-routes-informations";
export const LS_CONTACT_PRESAVE_ONGLET_INFORMATIONS = LS_CONTACT_ID + "-onglet-informations";
export const LS_CONTACT_PRESAVE_ONGLET_INFORMATIONS_IGNORE = LS_CONTACT_PRESAVE_ONGLET_INFORMATIONS + "-ignore";
export const LS_CONTACT_PRESAVE_ROUTES_PROJETS = LS_CONTACT_ID + "-routes-projets";
export const LS_CONTACT_PRESAVE_ROUTES_EVENEMENTS = LS_CONTACT_ID + "-routes-evenements";
export const LS_CONTACT_PRESAVE_ROUTES_CONTRATS = LS_CONTACT_ID + "-routes-contrats";
export const LS_CONTACT_PRESAVE_ROUTES_SAISIES = LS_CONTACT_ID + "-routes-saisies";
export const LS_CONTACT_PRESAVE_ROUTES_QUALIFICATIONS = LS_CONTACT + "-routes-qualifications";

export const LS_CONTACT_PARTICULIER_COORDS = LS_CONTACT_ID + "-coordonnees";
export const LS_CONTACT_PARTICULIER_PROFIL = LS_CONTACT_ID + "-profil";
export const LS_CONTACT_PARTICULIER_PROFIL_ERREUR = LS_CONTACT_PARTICULIER_PROFIL + "-erreur";
export const LS_CONTACT_PARTICULIER_ADRESS_A_SUPPR = LS_CONTACT_ID + "-adresses-a-supprimer";
export const LS_CONTACT_PARTICULIER_COORDS_CONTRAT = LS_CONTACT_PARTICULIER_COORDS + "-contrat-{idContrat}";

export const LS_CONTACT_COURTIER_PROFIL = LS_CONTACT_COURITER_ID + "-profil";

export const LS_CONTACT_RECLAMATION = LS_CONTACT_ID + "-reclamations";
export const LS_CONTACT_ENVOI_EMAIL_DONNEES = LS_CONTACT_ID + "-email-data";
export const LS_CONTACT_ENVOI_SMS_DONNEES = LS_CONTACT_ID + "-sms-data";
export const LS_CONTACT_ENVOI_COURRIER_DONNEES = LS_CONTACT_ID + "-courrier-data";
export const LS_CONTACT_ENVOI_NOTIFICATION_ACTIVITE_DONNEES = LS_CONTACT_ID + "-notification-activite-data";
export const LS_CONTACT_ENVOI_NOTIFICATION_FORMULAIRE = LS_CONTACT_ID + "-formulaire";
export const LS_CONTACT_ENVOI_EMAIL_DONNEES_PJ = LS_CONTACT_ENVOI_EMAIL_DONNEES + "-pj";
export const LS_CONTACT_ENVOI_COURRIER_DONNEES_PJ = LS_CONTACT_ENVOI_COURRIER_DONNEES + "-pj";
//Evenements / Activités
export const LS_CONTACT_EVENEMENT_CREATION = LS_CONTACT_ID + "-evenement-creation";
export const LS_CONTACT_EVENEMENT_INIT = LS_CONTACT_ID + "-evenement-init";
export const LS_CONTACT_EVENEMENT_ID = LS_CONTACT_ID + "-evenement-{idEvenement}";

export const LS_CONTACT_EVENEMENT_FIL_ACTIVITE = LS_CONTACT_EVENEMENT_ID + "-fil-{idFil}";
export const LS_CONTACT_EVENEMENT_ACTIVITE_CREATION = LS_CONTACT_EVENEMENT_FIL_ACTIVITE + "-nouvelleActivite";

export const LS_CONTACT_EVENEMENT_ACTIVITE = LS_CONTACT_EVENEMENT_ID + "-{idActivite}";
export const LS_CONTACT_EVENEMENT_ACTIVITE_CLOTURE = LS_CONTACT_EVENEMENT_ACTIVITE + "-cloture";
export const LS_CONTACT_EVENEMENT_ACTIVITE_REOUVERTURE = LS_CONTACT_EVENEMENT_ACTIVITE + "-reouverture";
export const LS_CONTACT_EVENEMENT_ACTIVITE_COMMENTAIRE = LS_CONTACT_EVENEMENT_ACTIVITE + "-commentaire";
export const LS_CONTACT_EVENEMENT_ACTIVITE_STATUT = LS_CONTACT_EVENEMENT_ACTIVITE + "-statut";


//Projets/Etude
export const LS_CONTACT_ETUDE_OUVERTE = LS_CONTACT_ID + "-etude-ouverte";
export const LS_CONTACT_DERNIER_PROJET_ETUDE = LS_CONTACT_ID + "-dernier-projet-etude";
export const LS_CONTACT_DEMANDE_SAISIE = LS_CONTACT_ID + "-demande-saisie";
export const LS_CONTACT_PROJET_CREATION = LS_CONTACT_ID + "-projet-creation";
export const LS_CONTACT_PROJET_ID = LS_CONTACT_ID + "-projet-{idProjet}";
export const LS_CONTACT_PROJET_ID_IDENTIFIANT_PRODUIT_AVENANT = LS_CONTACT_PROJET_ID + "-identifiant-produit-avenant";
export const LS_CONTACT_SUGGESTIONS_CORRESPONDANT = LS_CONTACT_ID + "-suggestions-correspondant";
export const LS_CONTACT_CREATION_RELATION_PARAMETRES = LS_CONTACT_ID + "-creation-relation-parametres";

export const LS_CONTACT_PROJET_DEVIS_SELECTION = LS_CONTACT_PROJET_ID + "-selection-devis";
export const LS_CONTACT_PROJET_ORIGINE_CREATION = LS_CONTACT_ID + "-origine-creation-projet";

export const LS_CONTACT_PROJET_NOUVELLE_ETUDE = LS_CONTACT_PROJET_ID + "-etude-nouvelle";
export const LS_CONTACT_PROJET_ETUDE = LS_CONTACT_PROJET_ID + "-etude-{idEtude}";
export const LS_CONTACT_PROJET_ETUDE_CHOIX = LS_CONTACT_PROJET_ETUDE + "-choix";
export const LS_CONTACT_PROJET_ETUDE_ETAT_ONGLETS = LS_CONTACT_PROJET_ETUDE + "-etat-onglets";
export const LS_CONTACT_PROJET_ETUDE_COMPARATIF = LS_CONTACT_PROJET_ETUDE + "-comparatif";
export const LS_CONTACT_PROJET_ETUDE_COMPARATIF_STATUT = LS_CONTACT_PROJET_ETUDE_COMPARATIF + "-statut";
export const LS_CONTACT_PROJET_ETUDE_COMPARATIF_CLEF = LS_CONTACT_PROJET_ETUDE_COMPARATIF + "-clef";
export const LS_CONTACT_PROJET_ETUDE_DEVIS_COMPARATIF = LS_CONTACT_PROJET_ETUDE + "-devis-comparatif";
export const LS_CONTACT_PROJET_ETUDE_SELECTION = LS_CONTACT_PROJET_ETUDE + "-selection";
export const LS_CONTACT_PROJET_ETUDE_COUVERTURES = LS_CONTACT_PROJET_ETUDE + "-couvertures";
export const LS_CONTACT_PROJET_ETUDE_BRIEF = LS_CONTACT_PROJET_ETUDE + "-brief";
export const LS_CONTACT_PROJET_ETUDE_ONGLET_FORCE = LS_CONTACT_PROJET_ETUDE + '-onglet-force';
export const LS_CONTACT_PROJET_ETUDE_BRIEF_COURANT = LS_CONTACT_PROJET_ETUDE_BRIEF + "-courant";
export const LS_CONTACT_PROJET_ETUDE_BRIEF_COURANT_BENEFS_ALSACE_MOSELLE = LS_CONTACT_PROJET_ETUDE_BRIEF_COURANT + "-benefs-alsace-moselle";

export const LS_ETUDE_COURANTE = 'etude-courante';

export const LS_RISQUES_CONTRATS = LS_CONTACT_ID + "-risques-contrats";
export const LS_CONTRATS_RISQUE_SELECTIONNE = LS_CONTACT_ID + "-risque-{typeRisque}-organisation-{idOrganisation}-contrats";
export const LS_RISQUE_CONTRAT_SELECTIONNE = LS_CONTACT_ID + "-risque-{typeRisque}-contrat-{idContrat}";
export const LS_CONTRAT_INFOS_SOUSCRIPTIONS = LS_CONTACT_ID + "-informations-souscriptions-{typeRisque}-contrat-{idContrat}"

// Devis
export const LS_CONTACT_DEVIS_ID = LS_CONTACT_ID + "-devis-{idDevis}";
export const LS_CONTACT_PROJET_ETUDE_DEVIS_OPTIONS = LS_CONTACT_PROJET_ETUDE + "-devis-{idDevis}-options";
export const LS_CONTACT_DEVIS_SELECTION_TOUS = LS_CONTACT_PROJET_ETUDE + "-voir-tous";

// Envoi proposition
export const LS_CONTACT_DEVIS_PROPOSITION = LS_CONTACT_PROJET_ID + '-envoi-proposition';
export const LS_CONTACT_DEVIS_PROPOSITION_EMAIL = LS_CONTACT_PROJET_ID + "-envoi-proposition-email";
export const LS_CONTACT_DEVIS_PROPOSITION_EMAIL_ETAT = LS_CONTACT_PROJET_ID + "-envoi-proposition-email-etat";
export const LS_CONTACT_DEVIS_PROPOSITION_SMS = LS_CONTACT_PROJET_ID + "-envoi-proposition-sms";
export const LS_CONTACT_DEVIS_PROPOSITION_SMS_ETAT = LS_CONTACT_PROJET_ID + "-envoi-proposition-sms-etat";

// Infos adhésion
export const LS_CONTACT_DEVIS_INFOS_ADHESION = LS_CONTACT_DEVIS_ID + '-infos-adhesion';
export const LS_CONTACT_DEVIS_INFOS_ADHESION_ONGLET_COURANT = LS_CONTACT_DEVIS_INFOS_ADHESION + '-onglet';
export const LS_CONTACT_DEVIS_INFOS_ADHESION_ONGLET = LS_CONTACT_DEVIS_INFOS_ADHESION + '-{onglet}';
export const LS_CONTACT_DEVIS_INFOS_ADHESION_PAIEMENT_ONGLET = LS_CONTACT_DEVIS_INFOS_ADHESION_ONGLET_COURANT + '-paiement';
export const LS_CLIPBOARD_ADHESION = LS_CONTACT_PROJET_ETUDE + "-clipboard-adhesion";
export const LS_CLIPBOARD_DEVIS = LS_CONTACT_PROJET_ETUDE + "-clipboard-devis";
// Correspondants
export const LS_CONTACT_CORRESPONDANT = LS_CONTACT_ID + "-{typeContact}-correspondants-{id}";
export const LS_CONTACT_CORRESPONDANT_NOUVEAU = LS_CONTACT_ID + "-{typeContact}-correspondants-nouveau";

// Habilitation - Convention Courtier
export const LS_CONTACT_HABILITATION = LS_CONTACT_ID + '-habilitation-{idHabilitation}';
export const LS_CONTACT_HABILITATION_CONVENTION = LS_CONTACT_HABILITATION + '-convention';
export const LS_CONTACT_HABILITATION_CONVENTION_ID = LS_CONTACT_HABILITATION_CONVENTION + '-{idConvention}';
export const LS_CONTACT_HABILITATION_CONVENTION_ONGLET_SELECTIONNE = LS_CONTACT_HABILITATION_CONVENTION_ID + '-onglet-selectionne';
export const LS_CONTACT_HABILITATION_CONVENTION_ETAT_OUVERTURE = LS_CONTACT_HABILITATION_CONVENTION_ID + '-etat-{sousMenu}';
export const LS_CONTACT_HABILITATION_CONVENTION_ETAT_CONTROLE_OUVERTURE = LS_CONTACT_HABILITATION_CONVENTION_ID + '-etat-controle-{sousMenu}';

// LAB
export const LS_CONTACT_PARTICULIER_DOSSIER_LAB_ID_OPEN = "contact-particulier-dossier-lab-id-open";
export const LS_CONTACT_PARTICULIER_LAB_CREATION_CONTACT_ID = "contact-particulier-lab-creation-{idContact}";
export const LS_CONTACT_PARTICULIER_LAB_DEVIS_ID = "contact-particulier-{idContact}-lab-devis";
export const LS_CONTACT_PARTICULIER_LAB_CONTRATS_SELECTIONNES = "contact-particulier-{idContact}-contrat-selectionnes";

// Évènements
export const LS_EVENEMENT_RECHERCHE = "recherche-evenements";
export const LS_EVENEMENT_RECHERCHE_PARAMS = LS_EVENEMENT_RECHERCHE + "-parametres";
export const LS_EVENEMENT_RECHERCHE_RESULTATS = LS_EVENEMENT_RECHERCHE + "-resultats";
export const LS_EVENEMENT_RECHERCHE_SELECTIONNE = LS_EVENEMENT_RECHERCHE + "-selectionne";

// Campagnes
export const LS_CAMPAGNE_RECHERCHE_AVANCEE = "campagne-recherche-avancee";
export const LS_CAMPAGNE_RECHERCHE_PARAMS = "campagne-parametres-recherche";
export const LS_CAMPAGNE_HISTORIQUE_RECHERCHE = "campagne-historique-recherche";
export const LS_CAMPAGNE_DERNIERE_PAGE_COURANTE = "campagne-recherche-derniere-page-courante";
export const LS_CAMPAGNE_RECHERCHE_LANCEE = "campagne-recherche-lancee";
export const LS_CAMPAGNE_RESULTATS_RECHERCHE = "campagne-resultats-recherche";
export const LS_CAMPAGNE_EDITION = "campagne-edition";
// Securité
export const LS_AUTHENTIFICATION_TOKEN = "jwt-token";
export const LS_AUTHENTIFICATION_SESSION_DATE = "date-session";
export const LS_UTILISATEUR_CONNECTE = "currentUser"; // Différencié de LS_UTILISATEUR pour éviter de le vider en cas de remove

// Utilisateur
export const LS_UTILISATEUR = "utilisateur";
export const LS_UTILISATEUR_TYPE_CRITERES_RECHERCHE = LS_UTILISATEUR + "-criteres-recherche";
export const LS_UTILISATEUR_TYPE_HISTORIQUE_RECHERCHE = LS_UTILISATEUR + "-historique-recherche";
export const LS_UTILISATEUR_RESULTAT_RECHERCHE = LS_UTILISATEUR + "-resultat-recherche";

// Téléphonie
export const LS_TELEPHONIE_APPEL_ENTRANT = "telephonie_appel_entrant";
export const LS_TELEPHONIE_APPEL_SORTANT = "telephonie_appel_sortant";
export const LS_TELEPHONIE_APPEL_CREATION_CONTACT = "telephonie_appel_creation_contact";
export const LS_TELEPHONIE_SERVICE_ACTIF = "telephonie_service_actif";
export const LS_TELEPHONIE_PARAMETRAGE = "telephonie_parametrage";

//contrats
export const LS_CONTACT_CONTRAT_PRESTATION_CRITERE = LS_CONTACT_ID + "-contrat-prestation-critere-recherche";
export const LS_CONTACT_CONTRAT_COTISATION_CRITERE = LS_CONTACT_ID + "-contrat-prestation-cotisation-recherche";
export const LS_CONTACT_CONTRAT_NOEMISATION_CRITERE = LS_CONTACT_ID + "-contrat-noemisation-critere-recherche";
export const LS_CONTACT_CONTRAT_PRESTATION_PRE_SAVE_CRITERE = LS_CONTACT_ID + "-contrat-prestation-critere-presave-recherche";
export const LS_CONTACT_CONTRAT_COTISATION_PRE_SAVE_CRITERE = LS_CONTACT_ID + "-contrat-prestation-cotisation-presave-recherche";
export const LS_CONTACT_CONTRAT_NOEMISATION_PRE_SAVE_CRITERE = LS_CONTACT_ID + "-contrat-noemisation-cotisation-presave-recherche";
export const LS_CONTACT_CONTRATS_AFFICHES = LS_CONTACT_ID + "-contrat-affiches";
export const LS_CONTACT_CONTRATS_SELECTIONNE_TYPE_RISQUE = LS_CONTACT_ID + "-contrat-selectionne-type-risque";
export const LS_CONTACT_CONTRATS_INFORMATIONS = LS_CONTACT_ID + "-contrats-information";

//GED
export const LS_CONTACT_RECHERCHE_DOCUMENT = LS_CONTACT_ID + "-recherche-document";
export const LS_CONTACT_HISTORIQUE_RECHERCHE_DOCUMENT = LS_CONTACT_ID + "-hsitorique-recherche-document";
export const LS_CONTACT_DOCUMENT_OPEN = LS_CONTACT_ID + "-document-ouvert";

//QUALIFICATION
export const LS_QUALIFICATION = "qualification";
export const LS_QUALIFICATION_ETAT_GROUPE = LS_QUALIFICATION + "-etat-groupe-{date}";
export const LS_QUALIFICATION_ETAT_LOT = LS_QUALIFICATION + "-etat-lot-{id}";
export const LS_QUALIFICATION_ETAT_RECHERCHE = LS_QUALIFICATION + "-etat-recherche";
export const LS_QUALIFICATION_EMAIL_HISTORIQUE = LS_QUALIFICATION + "-email-historique";
export const LS_QUALIFICATION_LOT_PLI_TRAITEMENT_FORM = LS_QUALIFICATION + "-lot-{idLot}-pli-{idPli}-traitement-form";
export const LS_QUALIFICATION_LOT_PLI_TRAITEMENT_FORM_CONTACT = LS_QUALIFICATION_LOT_PLI_TRAITEMENT_FORM + "-contact";
export const LS_QUALIFICATION_LOT_PLI_TRAITEMENT_FORM_CONTRATS = LS_QUALIFICATION_LOT_PLI_TRAITEMENT_FORM + "-contrats";
export const LS_QUALIFICATION_LOT_PLI_TRAITEMENT_FORM_TAGS = LS_QUALIFICATION_LOT_PLI_TRAITEMENT_FORM + "-tags";
export const LS_QUALIFICATION_LOT_PLI_TRAITEMENT_ACTIVITES_FORM = LS_QUALIFICATION + "-lot-{idLot}-pli-{idPli}-traitement-activites-form";

//SAISIE
export const LS_SAISIE = LS_CONTACT_ID + "-saisie";
export const LS_ALL_SAISIE_OUVERTE = LS_SAISIE + "-ouverte";
export const LS_SAISIE_OUVERTE = LS_ALL_SAISIE_OUVERTE + "-{idSaisie}";
export const LS_SAISIE_OUVERTE_ETAT = LS_SAISIE_OUVERTE + "-etat";
export const LS_SAISIE_NOUVELLE = LS_SAISIE + "-nouvelle";
export const LS_SAISIE_NOUVELLE_DETAILS = LS_SAISIE_NOUVELLE + "-details";
export const LS_SAISIE_NOUVELLE_DESTINATAIRES = LS_SAISIE_OUVERTE + "-destinataires";
export const LS_SAISIES_CRITERES_RECHERCHE = "saisies-criteres-recherche-{cleStorage}";
export const LS_SAISIES_RESULTATS_RECHERCHE = "saisies-resultats-recherche-{cleStorage}";
export const LS_SAISIES_HISTORIQUE_RECHERCHE = "saisies-historique-recherche-{cleStorage}";
export const LS_CONTACT_TRANSFORMATION_SAISIE = LS_CONTACT_ID + "saisie-a-transformer";

//DOUBLON
export const LS_DOUBLON = "doublon";
export const LS_DOUBLON_RECHERCHE_LISTES = LS_DOUBLON + "-recherche-{typeContact}-listes";

//QUOTA
export const LS_QUOTA = "quota";
export const LS_QUOTA_RECHERCHE_LISTE = LS_QUOTA + "-recherche-liste";

//ETAT MENU CONTACT
export const LS_ETAT_MENU_CONTACT = "etat-menu-contact";
export const LS_ETAT_MENU_CENTRE_DOCUMENTAIRE = "etat-menu-contact";

//INTEGRATION
export const LS_INTEGRATION = "integration";
export const LS_INTEGRATION_CLIENT_LISTE = LS_INTEGRATION + "-client-liste";
export const LS_INTEGRATION_CLIENT_SELECTION = LS_INTEGRATION + "-client-selection";

//CGU
export const LS_CGU_ACCEPTEES = "cgu-acceptees";

//DESTINATAIRE EMAIL
export const LS_AJOUT_DESTINATAIRE = "{classObj}-{idObjet}-ajout-destinataires";

//RELATION
export const LS_PERSONNE_CREATION_PRE_SAVE = 'finalisation-personne-fichage';

//CENTRE DOCUMENTAIRE
export const LS_CENTRE_DOCUMENTAIRE = 'CENTRE_DOCUMENTAIRE';
export const LS_CENTRE_DOCUMENTAIRE_ARBORESCENCE_COMPLETE = LS_CENTRE_DOCUMENTAIRE + '_ARBORESCENCE';
export const LS_CENTRE_DOCUMENTAIRE_CHEMIN_COURANT = LS_CENTRE_DOCUMENTAIRE + '_CHEMIN_COURANT';
export const LS_CENTRE_DOCUMENTAIRE_CRITERES_RECHERCHE = LS_CENTRE_DOCUMENTAIRE + '_CRITERES_RECHERCHE';
export const LS_CENTRE_DOCUMENTAIRE_ROUTE_COURANTE = LS_CENTRE_DOCUMENTAIRE + '_ROUTE_COUANTE';

//ADMINISTRATION
export const LS_ADMINISTRATION = 'ADMINISTRATION';
export const LS_ADMINISTRATION_PORTEFEUILLE = LS_ADMINISTRATION + '_PORTEFEUILLE';
export const LS_ADMINISTRATION_PORTEFEUILLE_MODELE = LS_ADMINISTRATION_PORTEFEUILLE + '_MODELE';
export const LS_ADMINISTRATION_PORTEFEUILLE_MODELE_CANAL = LS_ADMINISTRATION_PORTEFEUILLE_MODELE + '_{canal}';
export const LS_ADMINISTRATION_PORTEFEUILLE_PERSONNALISATION = LS_ADMINISTRATION_PORTEFEUILLE + '_PERSONNALISATION';

// interface StringConstructor {
// 	interpolate(props: string[]): string;
// }

// let interpolate = function (template: string, props: string[]): string {
// 	return template.replace(/\{(\w+)\}/g, function (match, expr) {
// 		return (props || window)[expr];
// 	});
// }

@Injectable({
	providedIn: 'root'
})
export class LocalStorageService {

	/**
	 * Remplace les variables par les valeurs dans la clé.
	 *
	 * @param key la clé
	 * @param values les valeurs
	 * @returns {string} la clé avec les valeurs
	 */
	private interpolate(key: string, values: { [value: string]: any }): string {
		return key.replace(/\{(\w+)\}/g, function (match, expr) {
			return values[expr] || match;
		});
	}

	/**
	 * Test si une clé avec valeur correspond a une clé générique
	 *
	 * @param key la clé avec valeur
	 * @param pattern le pattern de la clé
	 * @returns {string} true si la clé correspond
	 */
	public keyMatchPattern(key: string, pattern: string): boolean {
		let re = new RegExp(pattern.replace(/\{(\w+)\}/g, "[^-]+"));
		return re.test(key);
	}

	/**
	 * Stocke des données en session en associant une clé.
	 *
	 * @param object les données à stocker
	 * @param key la clé de stockage
	 * @param params es paramètres de construction de la clé
	 */
	public persist(object, key: string, params: any = {}) {
		if (!window.sessionStorage) console.error("Pas de gestion des données temporaires possible sur ce terminal");
		let storageKey = this.interpolate(key, params);
		// console.trace("Enregistrement de " + storageKey + " dans la session", object);
		sessionStorage.setItem(storageKey, JSON.stringify(object, (key, value) => {
			//on supprime les clés settées par ng2-ressource-rest (pour casser les JSON circulaires)
			if (key && key.charAt && key.charAt(0) == '$') {

				//TODO warning temporaire pour validation du concept de suppression des clé circulaires
				//on affiche un warning que si on est sur un clé != des 4 settée par ng2-ressource-rest
				if (['$resource', '$resolved', '$abortRequest'].indexOf(key) == -1)
					console.warn("Localstorage persist drop key " + key);

				return null;
			}

			return value;
		}));
	}

	/**
	 * Récupère les données en session selon une clé.
	 *
	 * @param key la clé de stockage
	 * @param params les paramètres de construction de la clé
	 * @returns {any} les données en session
	 */
	public load(key: string, params: any = {}): any {
		if (!window.sessionStorage) throw "Pas de gestion des données temporaires possible sur ce terminal";
		let storageKey = this.interpolate(key, params);
		let loaded = JSON.parse(sessionStorage.getItem(storageKey));
		// console.trace("chargement de " + storageKey + " depuis la session", loaded);
		return loaded;
	}

	/**
	 * Supprime les données en session selon un clé.
	 *
	 * @param key la clé de stockage
	 * @param params les paramètres de construction de la clé
	 * @param recurse si true, supprime aussi les données dont la clé commence par la clé fournie
	 */
	public remove(key: string, params: any = {}, recurse: boolean = true) {
		if (!window.sessionStorage) console.error("Pas de gestion des données temporaires possible sur ce terminal");
		let storageKey = this.interpolate(key, params);
		// console.trace("Suppression de " + storageKey + (recurse ? " et de ses descendants" : "") + " depuis la session");
		if (recurse) {
			for (let i = sessionStorage.length - 1; i > -1; i--) {
				if (storageKey && sessionStorage.key(i).startsWith(storageKey)) {
					// console.trace("suppression de " + sessionStorage.key(i));
					sessionStorage.removeItem(sessionStorage.key(i));
				}
			}
		}
		sessionStorage.removeItem(storageKey);
	}

	/**
	 * Vérifie l'existance du clé en session.
	 *
	 * @param key la clé de stockage
	 * @param params les paramètres de construction de la clé
	 * @param recurse si true, vérifie l'existance de clés commençant par la clé fournie
	 * @returns {boolean} clé existante
	 */
	public exists(key: string, params: any = {}, recurse: boolean = true) {
		if (!window.sessionStorage) console.error("Pas de gestion des données temporaires possible sur ce terminal");
		let storageKey = this.interpolate(key, params);
		if (recurse) {
			for (let i = 0; i < sessionStorage.length; i++) {
				if (storageKey && sessionStorage.key(i).startsWith(storageKey)) {
					return true;
				}
			}
		}
		return !!sessionStorage.getItem(storageKey);
	}

	/**
	 * Vérifie l'existance d'une clé en session selon critères.
	 *
	 * @param key la clé de stockage
	 * @param params les paramètres de construction de la clé
	 * @param filter fonction qui prend une clé et renvoie true si elle correpond à une clé recherchée
	 * @returns {boolean} clé existante
	 */
	public existsFiltered(key: string, params: any = {}, filter: (key: string) => boolean) {
		if (!window.sessionStorage) console.error("Pas de gestion des données temporaires possible sur ce terminal");
		let storageKey = this.interpolate(key, params);
		for (let i = 0; i < sessionStorage.length; i++) {
			let key = sessionStorage.key(i);
			if (storageKey && key.startsWith(storageKey) && filter(key)) {
				return true;
			}
		}
		return false;
	}

	/**
	 * Vérifie l'existance d'une clé en session selon critères.
	 *
	 * @param key la clé de stockage
	 * @param params les paramètres de construction de la clé
	 * @param filter fonction qui prend une clé et renvoie true si elle correpond à une clé recherchée
	 * @returns {boolean} clé existante
	 */
	public listkeySession(key: string, params: any = {}) {
		if (!window.sessionStorage) console.error("Pas de gestion des données temporaires possible sur ce terminal");
		let storageKey = this.interpolate(key, params);
		let listKey = [];
		for (let i = 0; i < sessionStorage.length; i++) {
			let key = sessionStorage.key(i);
			if (storageKey && key.startsWith(storageKey)) {
				listKey.push(key)
			}
		}
		return listKey;
	}


	/**
	 * Lit la clé dans le storage et la détruit
	 * @param key clé à retrouver
	 * @param params params de la clé
	 * @returns {any}
	 */
	public loadAndRemove(key: string, params: any = {}) {
		let value = this.load(key, params);
		this.remove(key, params);
		return value;
	}

	/**
	 * Vide les données stockées en session.
	 */
	public clear() {
		sessionStorage.clear();
	}

	/**
	 * Vide les données stockées en session sauf les clés données.
	 * @param clésAGarder
	 */
	public clearExcept(clesAGarder: Array<{ key: string, params: any }>) {
		for (let indexSession = sessionStorage.length - 1; indexSession > -1; indexSession--) {
			let sessionKey: string = sessionStorage.key(indexSession);
			if (!clesAGarder.some((cleValeur: any) => {
				return this.interpolate(cleValeur.key, cleValeur.params) == sessionKey
			})) {
				sessionStorage.removeItem(sessionKey);
			}
		}
	}
}
