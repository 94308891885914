export enum TypeContact {

	/**
	 * PersonneEntity physique.
	 */
	PERSONNE_PHYSIQUE,

		/**
		 * PersonneEntity morale.
		 */
	PERSONNE_MORALE,

		/**
		 * CourtierEntity.
		 */
	COURTIER
}