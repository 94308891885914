<div data-e2e="input-connected-user" class="connected-user {{theme ? 'connected-user-' + theme : ''}}" (click)="ouvertureParamPortefeuille = !ouvertureParamPortefeuille">
	<div class="connected-user__card">
		<div class="username">{{utilisateur?.nom}} {{utilisateur?.prenom}}</div>
		<div class="wallet">
			{{utilisateur?.portefeuille?.entiteJuridique?.libelle}} -
			{{utilisateur?.portefeuille?.libelle}}
		</div>
	</div>
	<div class="login-box">
		<i class="icon-user-login" data-e2e="fermer-informations-adhesion"></i>
	</div>

</div>
<div class="connected-user-options open" *ngIf="ouvertureParamPortefeuille" (clickBlur)="blurParamPortefeuille($event)">
	<div class="row-eq-height params cursor-pointer" (click)="changePasswordOpen = !changePasswordOpen">
		<div class="col-md-8 libelle">Paramètres</div>
		<div class="col-md-4 text-center">
				<span class="icon-stack">
				  <i class="icon-circle icon-stack-2x"></i>
				  <i class="icon-gear icon-stack-1x"></i>
				</span>
		</div>
	</div>
	<div class="change-password" [class.open]="changePasswordOpen">
		<div *ngIf="!masquerChangementMotDePasse" class="btn btn-success xl very-compact" (click)="changerPassword()">
			<span>changement de mot de passe</span>
		</div>
		<div>
			<label>
				<input type="checkbox" [(ngModel)]="portefeuilleParDefaut"
					   [disabled]="portefeuilleParDefaut"
					   (change)="changePortefeuilleDefaut()"/>
				Définir portefeuille par défaut</label>
		</div>
	</div>
	<div class="form-group wallet-selector"
		 [formGroup]="portefeuilleForm">
		<label class="full-line-label" for="field_portefeuillle">portefeuille</label>
		<sps-res-suggest
				[resultTransformer]="portefeuilleResultTransformer"
				[dataSource]="portefeuilleResource"
				[valeur-vide]=false
				id="field_portefeuillle"
				formControlName="portefeuille"
				data-e2e="input-field_portefeuille">
		</sps-res-suggest>
	</div>
	<div *ngIf="!masquerLogout" class="row-eq-height logout cursor-pointer" (click)="logout()">
		<div class="col-md-8 libelle">Déconnexion</div>
		<div class="col-md-4 text-center">
				<span class="icon-stack">
				  <i class="icon-circle icon-stack-2x"></i>
				  <i class="icon-on-off icon-stack-1x"></i>
				</span>
		</div>
	</div>
</div>
