export enum MessageType {
	ERROR,
	SUCCESS,
	INFO,
	WARNING
}
export class GlobalMessageModel {
	constructor(type: MessageType, message: string) {
		this.type = type;
		this.message = message;
		this.id = Math.floor(Math.random() * 9999999999);
	}

	type: MessageType;
	message: string;
	id: number;
}