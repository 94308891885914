import { ResourceParams } from "@ngx-resource/core";
import { Injectable } from "@angular/core";
import { SPSResource } from "./sps-resource";


@Injectable({
  providedIn: 'root'
})
@ResourceParams({
	// Api path
	path: 'personnes/{!typeContact}/{!idContact}/correspondants/{idCorrespondant}'
})
export class CorrespondantResource extends SPSResource<any, any, any, any> {
}
