import { Injectable } from "@angular/core";
import {
	LocalStorageService,
	LS_AUTHENTIFICATION_SESSION_DATE,
	LS_AUTHENTIFICATION_TOKEN,
	LS_UTILISATEUR_CONNECTE
} from "./LocalStorage.service";
import { AuthentificationModel } from "../models/authentification.model";
import { UtilisateurConnecte } from "../models/utilisateur/utilisateur.model";
import { JwtHelperService } from "@auth0/angular-jwt";
import { PortefeuilleUtilisateur } from "../models/utilisateur/portefeuille.utilisateur.model";
import { RessourceModel } from "../models/utilisateur/ressource.model";
import { TranslateService } from "@ngx-translate/core";
import { Observable, Subject } from "rxjs";
import { environment } from "../../environments/environment";
import { IdLibelleModel } from "../models/referentiels/id.libelle.model";
import * as moment from "moment";
import { OutilSupport } from "../models/outil-support.model";
import { Utils } from '../utils/Utils';

@Injectable({
  providedIn: 'root'
})
export class UtilisateurService {


	private utilisateurConnecte: Subject<boolean> = new Subject<boolean>();
	public utilisateurConnecteObs: Observable<boolean> = this.utilisateurConnecte.asObservable();

	private changementPortefeuilleSub: Subject<boolean> = new Subject<boolean>();
	private changementPortefeuilleObs: Observable<boolean> = this.changementPortefeuilleSub.asObservable();

	private refreshTokenSub: Subject<Boolean> = new Subject();
	private refreshTokenObs: Observable<Boolean> = this.refreshTokenSub.asObservable();
	private refreshRunning: boolean = false;

	constructor(private storage: LocalStorageService,
				private translateService: TranslateService) {
	}

	getUtilisateurConnecte(): UtilisateurConnecte {
		return this.storage.load(LS_UTILISATEUR_CONNECTE);
	}

	mettreAJourUtilisateur(tokenResponse: any): UtilisateurConnecte {
		if (!tokenResponse) {
			return null;
		}
		// Construction du model d'authentification
		let auth = new AuthentificationModel();
		auth.access_token = tokenResponse.access_token;
		auth.token_type = tokenResponse.token_type;
		auth.expires_in = tokenResponse.expires_in;
		auth.refresh_token = tokenResponse.refresh_token;
		auth.startDate = moment();
		auth.jti = tokenResponse.jti;
		this.storage.persist(auth, LS_AUTHENTIFICATION_TOKEN);

		let utilisateur: UtilisateurConnecte = new UtilisateurConnecte();
		let decodeToken = new JwtHelperService().decodeToken(auth.access_token);
		utilisateur.login = decodeToken.email;
		utilisateur.identifiantExterieur = decodeToken.username;
		utilisateur.id = decodeToken.id;
		utilisateur.nom = decodeToken.nom;
		utilisateur.prenom = decodeToken.prenom;
		utilisateur.roles = decodeToken.authorities;
		utilisateur.portefeuille = <PortefeuilleUtilisateur>decodeToken.portefeuille || new PortefeuilleUtilisateur();
		utilisateur.pole = <IdLibelleModel>decodeToken.pole || new IdLibelleModel();
		utilisateur.entiteJuridique = decodeToken.entiteJuridique;
		utilisateur.portefeuille.entiteJuridique = utilisateur.entiteJuridique;
		utilisateur.codePrimaire = decodeToken.codePrimaire;
		utilisateur.cguAcceptees = decodeToken.cguAcceptees;
		utilisateur.affectationPossible = decodeToken.affectationPossible;
		utilisateur.outilSupport = decodeToken.outilSupport || new OutilSupport();
		utilisateur.featureFlippingKeys = decodeToken.featureFlippingKeys;

		this.storage.persist(utilisateur, LS_UTILISATEUR_CONNECTE);
		this.storage.persist(new Date(), LS_AUTHENTIFICATION_SESSION_DATE);
		console.debug("Notification à l'application que l'utilisateur est connecté", decodeToken);
		this.utilisateurConnecte.next(true);
		return utilisateur;
	}

	/**
	 * Création du libellé de ressource selon les information utilisateur + portefeuille et pôle au moment de l'action
	 * @param ressource - ressource qui sert à construire le libellé
	 * @param masquerPortefeuillePole - masquer ou non le portefeuille (et n'afficher que le libelle principal)
	 * @returns le libellé
	 */
	getRessourceLibelle(ressource: RessourceModel, masquerPortefeuillePole: boolean = false): string {
		// console.log("", ressource);
		if (!ressource) {
			return this.translateService.instant("UTILISATEUR.ATTRIBUTION.VIDE");
		}

		let ressourceLibelle: string = "";
		if (!masquerPortefeuillePole) {
			if (ressource.entiteJuridique && ressource.entiteJuridique.libelle) {
				ressourceLibelle += ressource.entiteJuridique.libelle + " - ";
			} else {
				ressourceLibelle += "Entité juridique inconnue - ";
			}
			if (ressource.portefeuille && ressource.portefeuille.libelle) {
				ressourceLibelle += ressource.portefeuille.libelle + " - ";
			} else {
				ressourceLibelle += "Portefeuille inconnu - ";
			}
			if (ressource.pole && ressource.pole.libelle) {
				ressourceLibelle += ressource.pole.libelle + " - ";
			} else {
				ressourceLibelle += "Pôle inconnu - ";
			}
		}
		ressourceLibelle += ressource.libelle;
		return ressourceLibelle;
	}

	/**
	 * Roles possibles:
	 *
	 * ROLE_COMMERCIAL
	 * ROLE_ADMINISTRATION_UTILISATEURS
	 * ROLE_RESPONSABLE
	 * ROLE_MANAGEMENT_RESEAU
	 * ROLE_COMMISSIONNEMENT
	 * ROLE_GESTION
	 * ROLE_PRESTATIONS_SINISTRES
	 */
	hasRole(rolesDemandes: string | Array<string>, operateur: string = 'OR'): boolean {
		if (!Array.isArray(rolesDemandes)) {
			rolesDemandes = [rolesDemandes];
		}
		let rolesUtilisateur: Array<string> = this.getUtilisateurConnecte().roles;
		let intersectionDroits = rolesDemandes.filter((right) => {
			return rolesUtilisateur.indexOf(right) !== -1;
		});
		let droitOK = false;
		if (operateur == 'OR') {
			droitOK = !!intersectionDroits && intersectionDroits.length > 0;
		}
		else {
			droitOK = Utils.arrayAreEqual(intersectionDroits, rolesDemandes);
		}
		return droitOK;
	}


	/**
	 * Rafraichit le token.
	 * @param idPortefeuille
	 * @param redirect_uri l'url de redirection (par défaut: /contacts)
	 * @param force flag permettant de forcer le refresh
	 * @returns {Observable<Boolean>}
	 */
	private refreshTokenPrivate(idPortefeuille: number, redirect_uri: string = 'contacts', force: boolean): Observable<Boolean> {
		if (!this.refreshRunning) {
			let authentification: AuthentificationModel = this.storage.load(LS_AUTHENTIFICATION_TOKEN);
			let utilisateurConnecte: UtilisateurConnecte = this.storage.load(LS_UTILISATEUR_CONNECTE);

			if (!force && authentification
				&& utilisateurConnecte
				&& utilisateurConnecte.portefeuille
				&& utilisateurConnecte.portefeuille.id == idPortefeuille
				&& (authentification.expires_in && authentification.startDate && moment(authentification.startDate).add(authentification.expires_in, "seconds").isAfter(moment().add(2, "minute")))) {

				this.refreshTokenSub.next(true);
				this.refreshRunning = false;
			} else {
				this.refreshRunning = true;
				let formData = new FormData();
				formData.append("grant_type", "refresh_token");
				formData.append("refresh_token", authentification.refresh_token);
				formData.append("redirect_uri", window.location.origin + "/" + redirect_uri);

				let request = new XMLHttpRequest();
				// Récupération de l'id du portefeuille pour récupérer un token contextuel :
				if (idPortefeuille != null) {
					request.open("POST", environment.AUTH_URL + "/oauth/token?idPortefeuille=" + idPortefeuille);
				} else {
					request.open("POST", environment.AUTH_URL + "/oauth/token");
				}

				// La requête doit contenir les information d'authentification de l'application
				request.setRequestHeader("Authorization", "Basic " + btoa(environment.AUTH_APPNAME + ":" + environment.AUTH_SECRET));
				request.send(formData);
				request.onload = (event: Event) => {
					let tokenResponse: any = JSON.parse(request.response);
					this.mettreAJourUtilisateur(tokenResponse);
					this.refreshTokenSub.next(true);
					this.refreshRunning = false;
				};
				request.onerror = (error: Event) => {
					this.refreshTokenSub.error(false);
					this.refreshRunning = false;
				};
			}
		}
		return this.refreshTokenObs;
	}

	refreshToken(redirect_uri: string = 'contacts', force: boolean = false): Observable<Boolean> {
		let utilisateur = this.getUtilisateurConnecte();
		if (utilisateur) {
			return this.refreshTokenPrivate(utilisateur.portefeuille && utilisateur.portefeuille.id, redirect_uri, force);
		}
		return this.refreshTokenPrivate(null, redirect_uri, force);
	}

	refreshTokenPortefeuille(idPortefeuille: number): Observable<Boolean> {
		return this.refreshTokenPrivate(idPortefeuille, "contacts", true);
	}

	changementDePortefeuille() {
		this.changementPortefeuilleSub.next(true);
	}

	auChangementDePortefeuille(): Observable<boolean> {
		return this.changementPortefeuilleObs;
	}

	creeRessourceUtilisateurConnecte(): RessourceModel {
		let utilisateurConnecte: UtilisateurConnecte = this.getUtilisateurConnecte();
		let ressource: RessourceModel = new RessourceModel;

		ressource.entiteJuridique = utilisateurConnecte.entiteJuridique;
		ressource.pole = utilisateurConnecte.pole;
		ressource.portefeuille = {
			id: utilisateurConnecte.portefeuille.id,
			libelle: utilisateurConnecte.portefeuille.libelle
		};
		ressource.id = utilisateurConnecte.id;
		ressource.libelle = utilisateurConnecte.nom + ' ' + utilisateurConnecte.prenom;
		ressource.actif = utilisateurConnecte.actif;

		return ressource;
	}

}
