import { Injectable } from "@angular/core";
import { Subject } from "rxjs";

@Injectable({
	providedIn: 'root'
})
export class FullScreenModalService {

	openedModal: string = null;

	private subject = new Subject<ModalServiceSubscribeModel>();
	fullModalObserver = this.subject.asObservable();

	private subjectEscape = new Subject<string>();
	fullModalEscapeObserver = this.subjectEscape.asObservable();

	constructor() {
		$(document).keydown((e) => {
			// ESCAPE key pressed
			if (e.keyCode == 27 && this.openedModal) {
				this.subjectEscape.next(this.openedModal);
			}
		});
	}

	openFullModal(modal: string, params: any = {}) {
		jQuery(modal + " .full-modal").removeClass("off").addClass("on");
		this.openedModal = modal;
		this.subject.next({open: true, modal: modal, params: params});
		jQuery("body").removeClass("nav-bar-off").addClass("nav-bar-on");
	}

	closeFullModal(modal: string, params: any = {}) {
		let fullModal = jQuery(modal + " .full-modal");
		if (fullModal && fullModal.hasClass('on')) {
			fullModal.removeClass("on").addClass("off");
			setTimeout(() => {
				fullModal.removeClass("off");
				this.openedModal = null;
				this.subject.next({open: false, modal: modal, params: params});
			}, 500);
		}
	}

	openModal(modal: string, params: any = {}) {
		jQuery(modal + " .modal").modal('show');
		this.openedModal = modal;
		this.subject.next({open: true, modal: modal, params: params});
		jQuery("body").removeClass("nav-bar-off").addClass("nav-bar-on");
	}

	closeModal(modal: string, params: any = {}) {
		let fullModal = jQuery(modal + " .modal");
		if (fullModal) {
			fullModal.modal('hide');
			setTimeout(() => {
				fullModal.modal('hide');
				this.openedModal = null;
				this.subject.next({open: false, modal: modal, params: params});
			}, 500);
		}
	}
}

export class ModalServiceSubscribeModel {
	open: boolean;
	modal: string;
	params: any;
}
