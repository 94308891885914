<div class="modal fade" id="confirm-recuperation-pasword" tabindex="-1" role="dialog" data-backdrop="static">
	<div class="modal-dialog" role="document">
		<div class="modal-content">
			<div class="modal-header">
				<div class="close-btn">
					<span class="icon-stack" data-dismiss="modal" aria-label="Close" (click)="close()">
						<i class="icon-circle icon-stack-2x"></i>
						<i class="icon-delete icon-stack-1x"></i>
					</span>
				</div>
				<h4 class="modal-title"><i class="icon-lock"></i> Changement du mot de passe</h4>
			</div>
			<div class="modal-body">
				<div class="modal-intro">
					Bonjour <span>{{utilisateur?.nom}} {{utilisateur?.prenom}}</span>, votre mot de passe doit contenir
					minimum
					8 caractères dont au moins une majuscule et un chiffre.
				</div>

				<form action="" [formGroup]="formGroup" [class.ng-submited]="submited">
					<div class="form-group">
						<label for="ancien">Mot de passe actuel</label>
						<input type="password" id="ancien" class="form-control" formControlName="actuel">
						<p class="error">
							Le mot de passe actuel doit être renseigné.
						</p>
					</div>

					<div class="form-group">
						<label for="nouveau">Mot de passe</label>
						<input [attr.type]="typeChampsPassword" id="nouveau" class="form-control"
							   formControlName="nouveau">
						<p class="error">
							Le nouveau mot de passe doit être renseigné.
						</p>
					</div>

					<div class="form-group">
						<label for="confirm">Confirmation du mot de passe</label>
						<input [attr.type]="typeChampsPassword" id="confirm" class="form-control"
							   [class.ng-invalid]="mdpDifferent || passwordFaible"
							   formControlName="confirmation">
						<p class="error">
							La confirmation du mot de passe doit être renseignée.
						</p>
					</div>
					<p class="error-box" *ngIf="mdpDifferent">
						Les mots de passe renseignés ne sont pas identiques.
					</p>
					<p class="error-box" *ngIf="passwordFaible">
						Le format du nouveau mot de passe est incorrect.
					</p>

					<div class="link voir-mdp" (mousedown)="voirPassword()" (mouseup)="masquerPassword()">
						<span *ngIf="typeChampsPassword == 'password' ">Voir le mot de passe</span>
						<span *ngIf="typeChampsPassword == 'text' ">Masquer le mot de passe</span>
					</div>

					<p class="error-box" *ngIf="formGroup.errors?.passwordValidation == 'actuelEgalNouveau'">
						Le nouveau mot de passe doit être différent de l'ancien mot de passe.
					</p>

					<div class="card">
						<label for="">Niveau de sécurité</label>
						<div class="force-bar"
							 [class.high]="passwordFort"
							 [class.medium]="passwordMoyen"
							 [class.low]="passwordFaible">
							<div class="part-1">
								<span>Faible</span>
							</div>
							<div class="part-2">
								<span>Moyen</span>
							</div>
							<div class="part-3">
								<span>Fort</span>
							</div>
						</div>

						<div class="info">
							Eviter les mots de passe que vous utilisez sur d’autres sites et qui pourraient être
							aisément devinés.
						</div>
					</div>
				</form>

			</div>
			<sps-serveur-erreur [afficher-exception]="true"
								[erreur-serveur]="[serverErrors]">
			</sps-serveur-erreur>
			<div class="modal-footer">
				<sps-bouton class="btn btn-success" [working]="working" (action)="valider()">
					<span><i class="icon-check"></i></span>
					<span>Valider</span>
				</sps-bouton>
			</div>
		</div>
	</div>
</div>