/**
 * Created by cguimezanes on 01/03/17.
 */
import { IdLibelleModel } from "../referentiels/id.libelle.model";
import { IdLibelleActifModel } from "./id.libelle.actif.model";

/**
 * Une ressource peut être affectée, être l'auteur ou le modificateur d'activités, conventions .....
 */
export class RessourceModel extends IdLibelleActifModel {
	pole: IdLibelleModel;
	portefeuille: IdLibelleModel;
	entiteJuridique: IdLibelleModel;
}

export class RessourceCentreDocumentaireModel extends RessourceModel {
	emailUtilisateur: string;
}

export class ContratRessource {

	/** Organisation. */
	organisation: string;

	/** Agence. */
	agence: string;

	/** Nom. */
	nom: string;

	/** Prenom. */
	prenom: string;
}
