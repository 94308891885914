import { Injectable } from "@angular/core";
import { Subject } from "rxjs";

@Injectable({
	providedIn: 'root'
})
export class RecapErreurService {
	public recapErreur = new Subject<string>();
	public resetRecapErreur = new Subject<string>();
	public recapErreurObs = this.recapErreur.asObservable();
	public resetRecapErreurObs = this.resetRecapErreur.asObservable();
}
