/* formatter:off */
/* Official Angular modules */
import {CommonModule, registerLocaleData} from "@angular/common";
import {HttpClient, HttpClientModule} from '@angular/common/http';
import locale_fr from "@angular/common/locales/fr";
import {ErrorHandler, NgModule} from "@angular/core";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
/* Other external modules */
import {MissingTranslationHandler, TranslateLoader, TranslateModule, TranslateService} from "@ngx-translate/core";
import {TranslateHttpLoader} from "@ngx-translate/http-loader";

import {ClipboardModule} from "ngx-clipboard";
import {InfiniteScrollModule} from "ngx-infinite-scroll";
import {NgxPaginationModule} from "ngx-pagination";
import {ChartsModule} from "ng2-charts";
import {DragulaModule} from "ng2-dragula";
import {FileUploadModule} from "ng2-file-upload";
/* Application modules & handlers*/
import {AppComponent} from "./app.component";
import {EmptyComponent} from "./empty.component";
/* Guards and Validators */
import {EnumValidator} from "./validators/enum.validator";
import {RequiredIfOtherValuedValidator} from "./validators/required.if.another.value.validator";
/* Services */
/* Modules */
/* - Widgets */
import {RechercheContactModule} from "./components/contacts/commun/recherche/recherche.contact.module";
import {LabRisqueModule} from "./components/contacts/particuliers/informations/lab/bandeau-risque/lab.risque.module";
import {NavigationModule} from "./components/navigation/navigation.module";
import {UiModule} from "./components/ui/ui.module";
/* Components */
/* - Qualification */
/* - CGU */
/* - Contact */
import {RouterForward} from "./components/contacts/router.forward.component";
import {RouterChangePortefeuille} from "./components/contacts/router.change.portefeuille.component";
/* - Directives */
/* - Errors */
/* - Evenements */
/* - Integration */
import {ContactIntegrationComponent} from "./components/integration/contact.integration.component";
/* - Pipe */
/* - Qualification */
/* - Referentiels */
/* - UI */
/* - Utilisateurs */
import {defineLocale, frLocale} from "ngx-bootstrap/chronos";
import {CarouselModule} from "ngx-bootstrap/carousel";
import {BsDatepickerModule} from "ngx-bootstrap/datepicker";
import {ModalModule} from "ngx-bootstrap/modal";
import {TimepickerModule} from "ngx-bootstrap/timepicker";
import {TooltipModule} from "ngx-bootstrap/tooltip";
import {TypeaheadModule} from "ngx-bootstrap/typeahead";
import {ResourceModule} from '@ngx-resource/handler-ngx-http';
import {ResourceGlobalConfig, ResourceHandler} from '@ngx-resource/core';
import {environment} from '../environments/environment';
import {SPSResourceHandler} from './resources/sps-resource';
import {AppRouterModule} from './app.router.module';
import {RedirectionLienExterieurComponent} from './components/redirection.lien.exterieur.component';
import {Error403Component} from './components/errors/403.component';
import {ErrorsComponent} from './components/errors/errors.component';
import {ReferentielAccordSanteEntrepriseComponent} from './components/referentiels/accordsante';
import {CguComponent} from './components/cgu/cgu.component';
import {
	ModificationPasswordComponent
} from './components/utilisateurs/modificationpassword/modificationpassword.component';
import {AppMissingTranslationHandler} from './app.missing.translation.handler';
import {AppErrorHandler} from './app.error.handler';
import {ReferentielComponent} from './components/referentiels/referentiel.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {RechercheCotisationComponent} from "./components/contacts/particuliers/contrat/cotisation";

/**
 * Changement de la locale de l'application, par défaut locale_en
 */
registerLocaleData(locale_fr);

export function createTranslateLoader(http: HttpClient) {
	return new TranslateHttpLoader(http, './assets/i18n/', '.json?cb=' + new Date().getTime());
}

defineLocale('fr', frLocale);

/**
 * Custom handler pour @ngx-resource/handler-ngx-http
 * @param {HttpClient} http
 * @returns {SPSResourceHandler}
 */
export function spsResourceHandlerFactory(http: HttpClient) {
	/**
	 * Configuration du module @ngx-resource/core
	 *   github.com/troyanskiy/ngx-resource-core
	 */
	ResourceGlobalConfig.url = environment.API_URL;

	return new SPSResourceHandler(http);
}

@NgModule({
	imports: [
		/* Angular modules */
		CommonModule,
		FormsModule,// Forms
		ReactiveFormsModule,
		BrowserAnimationsModule,
		HttpClientModule,
		TranslateModule.forRoot({
			loader: {
				provide: TranslateLoader,
				useFactory: createTranslateLoader,
				deps: [HttpClient]
			}
		}),
		// Router
		AppRouterModule,
		UiModule,
		/* NPM modules */
		ChartsModule, // ng2-charts
		DragulaModule.forRoot(), // ng2-dragula
		FileUploadModule, // ng2-file-upload
		ClipboardModule, // ngx-clipboard
		InfiniteScrollModule, // ngx-infinite-scroll
		NgxPaginationModule, // ngx-paginate
		/* ngx-bootstrap */
		CarouselModule.forRoot(),
		BsDatepickerModule.forRoot(),
		ModalModule.forRoot(),
		TimepickerModule.forRoot(),
		TooltipModule.forRoot(),
		TypeaheadModule.forRoot(),
		ResourceModule.forRoot({
			handler: {
				provide: ResourceHandler,
				useFactory: (spsResourceHandlerFactory),
				deps: [HttpClient]
			}
		}),
		/* Widget modules */
		RechercheContactModule,
		LabRisqueModule,
		NavigationModule,
	],
	declarations: [
		RouterForward,//Permet de force le rechargement des components enfants
		RouterChangePortefeuille,
		AppComponent,
		EmptyComponent,

		//VALIDATORS
		EnumValidator,
		RequiredIfOtherValuedValidator,

		//CONTACTS
		ContactIntegrationComponent,
		RechercheCotisationComponent,
		//Utilisateurs:
		ModificationPasswordComponent,

		//CGU
		CguComponent,

		//affichage de référenciel
		ReferentielComponent,
		ReferentielAccordSanteEntrepriseComponent,

		//ERRORS
		ErrorsComponent,
		Error403Component,

		//REDIRECTS
		RedirectionLienExterieurComponent
	],

	providers: [
		{provide: MissingTranslationHandler, useClass: AppMissingTranslationHandler},
		{provide: ErrorHandler, useClass: AppErrorHandler}
	],
	bootstrap: [AppComponent],
})
export class AppModule {

	constructor(translate: TranslateService) {

		// this language will be used as a fallback when a translation isn't found in the current language
		translate.setDefaultLang('fr-FR');

		// the lang to use, if the lang isn't available, it will use the current loader to get them
		translate.use('fr-FR');
	}

}
