import { Component, Input } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { LocalStorageService } from "../../../services/LocalStorage.service";
import { UtilisateurConnecte } from "../../../models/utilisateur/utilisateur.model";
import { RecapErreurService } from "../../ui/recapErreur/recapErreur.service";
import { MdpUtilisateurResource } from "../../../resources/utilisateur/mdp.utilisateur.resource";
import { GlobalMessageService } from "../../../services/global.message.service";

@Component({
	selector: 'modification-password',
	templateUrl: './modificationpassword.template.html',
	styleUrls: ['./modificationpassword.style.less']

})
export class ModificationPasswordComponent  {

	formGroup: FormGroup;
	passwordFort: boolean = false;
	passwordMoyen: boolean = false;
	passwordFaible: boolean = false;
	typeChampsPassword: string = 'password';
	@Input() utilisateur: UtilisateurConnecte;
	submited: boolean = false;
	mdpDifferent: boolean = false;
	idRecapErreur: string = "MODIFICATION_MDP";
	serverErrors: any;
	working: boolean = false;

	constructor(private fb: FormBuilder,
				private storage: LocalStorageService,
				private recapErreurService: RecapErreurService,
				private mdpUtilisateurResource: MdpUtilisateurResource,
				private messageService: GlobalMessageService,) {

		this.formGroup = this.fb.group({
			actuel: ['', Validators.required],
			nouveau: ['', Validators.required],
			confirmation: ['', Validators.required]
		}, {validator : this.formPasswordValid});

		this.formGroup.get('nouveau').valueChanges.subscribe((password: string) => {
			if (password) {
				this.verifierPassword(password);
			}
			this.compareEgalite();
		});

		this.formGroup.get('confirmation').valueChanges.subscribe((changed) => {
			this.compareEgalite();
		});

	}

	formPasswordValid = (formPassword: FormGroup): { [key: string]: any } => {
		let formValue: any = formPassword.getRawValue();
		if (formValue.actuel && formValue.actuel == formValue.nouveau) {
			return {"passwordValidation": "actuelEgalNouveau"};
		}
		return null;
	};

	compareEgalite() {
		this.mdpDifferent = !(this.formGroup.get("nouveau").value == this.formGroup.get("confirmation").value);
	}

	calculerScorePassword(password: string) {
		let alphabetMajuscule = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
		let alphabetMinuscule = "abcdefghijklmnopqrstuvwxyz";
		let alphabetNombre = "0123456789";
		let alphabetCharacters = "!@#$%^&*?_~";


		// Checks a string for a list of characters
		let compterOccurances = (strPassword, strCheck) => {
			// Declare variables
			let nCount = 0;

			for (let i = 0; i < strPassword.length; i++) {
				if (strCheck.indexOf(strPassword.charAt(i)) > -1) {
					nCount++;
				}
			}

			return nCount;
		};


		// Reset combination count
		let score = 0;


		// -- 8 or more (obligatoire)
		if (password.length > 7) {
			score += 20;
		}

		// Letters
		let nombreDeMajuscule = compterOccurances(password, alphabetMajuscule);
		let nombreDeMinuscule = compterOccurances(password, alphabetMinuscule);
		let totalMajusculeEtMinuscule = nombreDeMajuscule + nombreDeMinuscule;

		// -- Letters are upper case and lower case
		if (nombreDeMajuscule != 0 && nombreDeMinuscule != 0) {
			score += 10;
		}

		// Numbers
		let nombreDeChiffre = compterOccurances(password, alphabetNombre);
		// -- 1 number
		if (nombreDeChiffre >= 1) {
			score += 10;
		}

		// Characters
		let nCharacterCount = compterOccurances(password, alphabetCharacters);
		// -- 1 character
		if (nCharacterCount == 1) {
			score += 10;
		}


		// Bonus

		//le score sera de 40 si le mdp fait au moins 8 char, une majuscule et un chiffre, aprés c'est bonus
		if (score >= 40) {
			// -- 3 or more numbers
			if (nombreDeChiffre >= 3) {
				score += 20;
			}


			// -- Letters and numbers
			if (nombreDeChiffre != 0 && totalMajusculeEtMinuscule != 0) {
				score += 10;
			}
			// -- Letters, numbers, and characters
			if (nombreDeChiffre != 0 && totalMajusculeEtMinuscule != 0 && nCharacterCount != 0) {
				score += 20;
			}
			// -- Mixed case letters, numbers, and characters
			if (nombreDeChiffre != 0 && nombreDeMajuscule != 0 && nombreDeMinuscule != 0 && nCharacterCount != 0) {
				score += 30;
			}
		}


		return score;
	}

	voirPassword() {
		this.typeChampsPassword = 'text';
	}

	masquerPassword() {
		this.typeChampsPassword = 'password';
	}

	// Runs password through check and then updates GUI
	verifierPassword(password: string) {
		// Check password
		let nScore = this.calculerScorePassword(password);
		console.log("score", nScore);
		//passwordFort
		if (nScore >= 70) {
			this.passwordFort = true;
			this.passwordMoyen = false;
			this.passwordFaible = false;
		}
		// -- Average
		else if (nScore >= 40) {
			this.passwordFort = false;
			this.passwordMoyen = true;
			this.passwordFaible = false;
		}
		// -- Weak
		else {
			this.passwordFort = false;
			this.passwordMoyen = false;
			this.passwordFaible = true;
		}

	}

	ngOnInit() {
		jQuery('#confirm-recuperation-pasword').on('hidden.bs.modal', (e) => {
			this.close();
		});
	}

	close() {
		this.formGroup.reset({emitEvent: false});
		this.passwordFort = false;
		this.passwordMoyen = false;
		this.passwordFaible = false;
		this.typeChampsPassword = 'password';
		this.submited = false;
		this.mdpDifferent = false;
		this.serverErrors = null;
	}

	valider() {
		this.submited = true;
		this.serverErrors = null;
		if (this.formGroup.valid && !this.mdpDifferent && !this.passwordFaible) {
			this.working = true;
			let donnees: UtilisateurMotPasse = this.mapFormToModel();

			this.mdpUtilisateurResource.update(donnees).subscribe(reponse => {
				this.messageService.showSuccess("Votre mot de passe a bien été modifié.");
				jQuery("#confirm-recuperation-pasword").modal('hide');
				this.working = false;
			}, err => {
				this.messageService.showError("Veuillez vérifier les informations saisies.");
				this.serverErrors = err;
				console.error(this.serverErrors);
				this.formGroup.reset();
				this.working = false;
				this.submited = false;
			});
		} else {
			this.recapErreurService.recapErreur.next(this.idRecapErreur);
		}
	}

	mapFormToModel(): UtilisateurMotPasse {
		let utilisateurMotPasse: UtilisateurMotPasse = new UtilisateurMotPasse();
		let form = this.formGroup.getRawValue();

		utilisateurMotPasse.oldPassword = form.actuel;
		utilisateurMotPasse.password = form.nouveau;
		utilisateurMotPasse.matchingPassword = form.confirmation;

		return utilisateurMotPasse;
	}
}

class UtilisateurMotPasse {
	oldPassword: string;
	password: string;
	matchingPassword: string;
}
