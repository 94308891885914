import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { UtilisateurService } from "../../services/utilisateur.service";

@Component({
	template: ''
})
export class RouterChangePortefeuille implements OnInit {
	constructor(private router: Router,
				private utilisateurService: UtilisateurService) {
	}

	ngOnInit(): void {
		this.router.navigate(['contacts']).then(() => {
			this.utilisateurService.changementDePortefeuille();
		});
	}
}
