import { NgModule } from "@angular/core";
import { MobileBurgerComponent } from "./mobile-burger/mobile.burger.component";
import { MobileNavbarComponent } from "./mobile-navbar/mobile.navbar.component";
import { ConnectedUserComponent } from "./connected-user/connected.user.component";
import { SPSCommonChildModule } from '../../app.common.child.module';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { UiModule } from '../ui/ui.module';
import { BoutonFermerContactComponent } from '../contacts/commun/bouton-fermer-contact/bouton.fermer.contact.component';
import { BoutonListeContactsComponent } from '../contacts/commun/bouton-liste-contacts/bouton.liste.contacts.component';
import { ContactsOuvertComponent } from '../contacts/commun/contacts-ouvert/contacts.ouvert.component';
import { MenuContactComponent } from '../contacts/commun/menu-contact/menu.contact.component';
import { BlocNoteContactComponent } from '../contacts/commun/bloc-note-contact/bloc.note.component';
import { CKEditorModule } from 'ng2-ckeditor';

@NgModule({
	imports: [
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		TranslateModule,
		RouterModule,
		HttpClientModule,
		UiModule
	],
	declarations: [
		MobileNavbarComponent,
		MobileBurgerComponent,
		ConnectedUserComponent,

		//commun contact mais situé au root de l'appli => Bof bof...
		BoutonFermerContactComponent,
		BoutonListeContactsComponent,
		ContactsOuvertComponent,
		MenuContactComponent,
		BlocNoteContactComponent
	],
	exports: [
		MobileNavbarComponent,
		MobileBurgerComponent,
		ConnectedUserComponent,

		//commun contact mais situé au root de l'appli => Bof bof...
		BoutonFermerContactComponent,
		BoutonListeContactsComponent,
		ContactsOuvertComponent,
		MenuContactComponent,
		BlocNoteContactComponent
	],
	providers: [
	]
})
/* Widget Module */
export class NavigationModule {
}
