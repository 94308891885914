<div class="contacts-opened" [class.open]="bandeauContactOuvert">
	<div class="contacts-container row-stretch col-stretch">
		<div class="left col-stretch row-multiline" *ngIf="contactsOpened">
			<div class="contact row-centered" *ngFor="let contact of contactsOpened">
				<span class="name row-centered"
					  (click)="ouvrirContact(contact);">
					<span class="btn-icon-circle btn-circle-sm btn-circle-light"
						  *ngIf="contact.type == 'PERSONNE_MORALE'">
					  <i class="icon-company"></i>
					</span>
					<span class="btn-icon-circle btn-circle-sm btn-circle-light"
						  *ngIf="contact.type == 'PERSONNE_PHYSIQUE'">
					  <i class="icon-user"></i>
					</span>
					<span class="btn-icon-circle btn-circle-sm btn-circle-light" *ngIf="contact.type == 'COURTIER'">
					  <i class="icon-broker"></i>
					</span>
					<span class="intitule" *ngIf="contact.type == 'PERSONNE_MORALE' || contact.type == 'COURTIER'">
						<span *ngIf="contact.id == contactOpened?.id">{{ contactOpened?.raisonSociale }}</span>
						<span *ngIf="contact.id != contactOpened?.id">{{contact?.raisonSociale}}</span>
					</span>
					<span class="intitule nomCapitale" *ngIf="contact.type == 'PERSONNE_PHYSIQUE'">
						<span *ngIf="contact.id != contactOpened?.id">{{contact?.prenom}} {{contact?.nom}}</span>
						<span *ngIf="contact.id == contactOpened?.id">{{contactOpened?.prenom}} {{contactOpened?.nom}}</span>
					</span>
				</span>
				<span class="btn-icon-circle btn-circle-xxs btn-circle-light cursor-pointer"
					  (click)="fermerContact(contact)">
						<i class="icon-delete"></i>
				</span>
			</div>
		</div>

		<div class="right hidden-xs">
			<div class="btn btn-success creer-contact-bouton" (click)=" toggleOpen(); creerContact();">
				<div>
					<span class="icon-stack icon-stack-plus">
					  <i class="icon-user icon-stack-2x"></i>
					  <i class="icon-plus icon-stack-1x"></i>
					</span>
				</div>
				<div class="label">Créer <br>un nouveau <br>contact</div>
			</div>
			<span></span>
		</div>

		<div class="reduce-btn" (click)="toggleOpen(false)">
			Refermer
			<i class="icon-chevron-up"></i>
		</div>

	</div>
</div>
