import {Component} from "@angular/core";
import {ContactsComponentsService} from "../../../../services/contacts/contacts.service";
import {ContactModel} from "../../../../models/commun/contacts/contact.model";
import {EvenementPersonnePaginateResource} from "../../../../resources/evenement/evenement.personne.paginate.resource";
import {PaginateResponseModel} from "../../../../models/paginate.response.model";
import {EvenementModel} from "../../../../models/commun/evenement/evenement.model";
import {ReclamationResource} from "../../../../resources/reclamation/reclamation.resource";
import {ReclamationModelBase} from "../../../../models/commun/reclamation/reclamation.model";
import {Subscription} from "rxjs";
import {ContratResource} from "../../../../resources/contrats/contrat.resource";
import {ProjetResource} from "../../../../resources/projet/projet.resource";
import {first} from 'rxjs/operators';
import {UtilisateurService} from '../../../../services/utilisateur.service';
import {FeatureFlippingService} from "../../../../services/feature.flipping.service";

@Component({
	selector: 'menu-contact',
	templateUrl: './menu.contact.template.html',
	styleUrls: ['./menu.contact.style.less']
})
export class MenuContactComponent {

	contactOpened: ContactModel;
	typeContact: string;
	typeContactFront: string;
	nbReclamationEnCours: number;
	nbEvenementEnCours: number;
	nbContratEnCours;
	nbProjetEnCours: number;
	estManagerReseau: boolean = false;
	featureSaisiesMasquerMenu: boolean = this.featureFlippingService.hasFeatureRight("SAISIES_MASQUER_MENU");
	featureProjetsMasquerMenu: boolean = this.featureFlippingService.hasFeatureRight("PROJETS_MASQUER_MENU");
	featureRGPDMasquerMenu: boolean = this.featureFlippingService.hasFeatureRight("RGPD_MASQUER_MENU");

	private subscriptions: Array<Subscription> = [];

	constructor(private contactsComponentService: ContactsComponentsService,
				public reclamationResource: ReclamationResource,
				public contratResource: ContratResource,
				public projetResource: ProjetResource,
				public evenementResource: EvenementPersonnePaginateResource,
				public utilisateurService: UtilisateurService,
				private featureFlippingService: FeatureFlippingService) {

	}

	ngOnInit() {
		this.contactOpened = this.contactsComponentService.getContactHeaderSource().getValue();
		this.subscriptions.push(this.contactsComponentService.getContactHeaderSource().pipe(first()).subscribe((contactOpened: ContactModel) => {
			this.contactOpened = contactOpened;
			this.completerMenu();
		}));

		this.subscriptions.push(this.contactsComponentService.updateLeftMenuObserver.subscribe(
			update => {
				this.completerMenu();
			}));

		this.estManagerReseau = this.utilisateurService.hasRole("ROLE_MANAGEMENT_RESEAU");
	}

	private completerMenu() {
		if (this.contactOpened != null) {
			this.typeContact = ContactModel.getContactType(this.contactOpened);
			this.typeContactFront = ContactModel.getContactTypeFront(this.contactOpened);
			let criterionEnCours = {
				idPersonne: this.contactOpened.id,
				etats: ['EN_COURS', 'A_VENIR'],//utilisé pour contrats
				contratRelations: ["ADHERENT"],//utilisé pour contrats
				statut: 'EN_COURS',//utilisé pour projets/reclamations/evts
				typeContact: this.typeContact
			};
			this.evenementResource.query(criterionEnCours).subscribe((evenements: PaginateResponseModel<EvenementModel>) => {
				this.nbEvenementEnCours = evenements ? evenements.count : null;
			});

			this.reclamationResource.query(criterionEnCours).subscribe((reclamations: Array<ReclamationModelBase>) => {
				this.nbReclamationEnCours = reclamations.length;
			});
			this.utilisateurService.hasRole("ROLE_GESTION") && this.contratResource.query(criterionEnCours).subscribe(contrats => {
				this.nbContratEnCours = contrats ? contrats.length : null;
			});
			this.projetResource.query(criterionEnCours).subscribe((projets) => {
				this.nbProjetEnCours = projets ? projets.length : null;
			});
		}
	}

	ngOnDestroy() {
		this.subscriptions.forEach(sub => sub.unsubscribe());
	}

	public peutAccederAuRGPDDuMenuContact(): boolean {
		return (this.typeContactFront !== 'entreprises' && this.typeContactFront !== 'courtiers') && !this.featureRGPDMasquerMenu;
	}


}
