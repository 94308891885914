import { IdLibelleModel } from "../../referentiels/id.libelle.model";
import { Departement } from "./departement.model";
import { PaysBase, ReferentielPays } from "../../referentiels/referenciel.pays.model";
import { Utils } from "../../../utils/Utils";

/**
 * Adresse Postale.
 */
export class AdressePostaleBase {

	/** Identifiant. */
	id: number;
	/** Numéro de voie de l'adresse (^[0-9]{0,4}[a-zA-Z]?$). */
	numeroVoie: string;
	/** Complément de nom de l'adresse. */
	complementNom: string;
	/** Type de la voie. */
	typeVoie: IdLibelleModel; // TypeVoie
	/** Libellé de la voie. */
	nomVoie: string;
	/** Nom de résidence/Batiment de l'adresse. */
	residenceBatiment: string;
	/** Boite postale de l'adresse. */
	boitePostal: string;
	/** Code postal / commune. */
	codePostalCommune: IdLibelleModel; // CodePostalCommune
	/** Identifiant du pays. */
	pays: PaysBase;
	/** Indique que l'adresse est invalide. */
	invalide: boolean = false;
	/** Date de début d'utilisation de l'adresse. */
	dateDebut: string;
	/** Date de fin d'utilisation. */
	dateFin: string;
	/** Code postal étranger. */
	codePostal: string;
	/** Commune / Ville étrangère. */
	ville: string;
	/** Département. */
	departement: Departement;
	/** Porteur ou non de l'adresse. */
	porteur: boolean;
	/** Longitude de l'adresse. */
	longitude: number;
	/** Latitude de l'adresse. */
	latitude: number;
	/** URL de l'image de géolocalisation.. */
	urlMiniatureGeolocalisation: string;
}

export class AdresseModel extends AdressePostaleBase {

	/** Override propriétés et valeur par défaut. */
	boitePostal: string = '';
	complementNom: string = '';
	// Actuellement ces deux attribut ne sont pas exploité par l'application (relicat de Square
	dateDebut: string = '';
	dateFin: string = '';
	//----------------------------------------------------------------------------------------
	codePostalCommune: IdLibelleModel = {id: null, libelle: ""};
	pays: ReferentielPays = new ReferentielPays();
	invalide: boolean = false;
	nomVoie: string = '';
	numeroVoie: string = '';
	residenceBatiment: string = '';
	typeVoie: IdLibelleModel = {id: null, libelle: ""};
	codePostal: string = '';
	ville: string = '';
	porteur: boolean = false;

	// FIXME: propriétés de criteres de recherche, ne devrait pas être dans le model
	personneId: number;
	typeContact: string;

	adresseFormatee: string;
	suggestAdresse: string;

	static getAdresseFormatee(adresse: AdresseModel, sep: string = ', '): string {
		if (!adresse) return '';

		return (adresse.complementNom ? adresse.complementNom + ' ' : '') +
			(adresse.numeroVoie ? adresse.numeroVoie + ' ' : '') +
			(adresse.typeVoie && adresse.typeVoie.id && adresse.typeVoie.libelle ? adresse.typeVoie.libelle + ' ' : '') +
			(adresse.nomVoie ? adresse.nomVoie + ' ' : '') +
			(adresse.residenceBatiment ? ' ' + adresse.residenceBatiment : '') +
			(adresse.boitePostal ? ' ' + adresse.boitePostal : '') + sep +
			AdresseModel.formatCodePostalVille(adresse);
	}

	static formatCodePostalVille(adresse: AdresseModel): string {
		if (adresse.codePostalCommune && adresse.codePostalCommune.libelle)
			return adresse.codePostalCommune.libelle;
		else {
			return (adresse.codePostal || '')
				+ (adresse.codePostal && adresse.ville && ' - ' || '')
				+ (adresse.ville || '');
		}
	}

	static getAdresseFormateeAvecPays(adresse: AdresseModel, sep: string = ', '): string {
		if (!adresse) return '';

		return AdresseModel.getAdresseFormatee(adresse, sep) + sep +
			(adresse.pays ? (adresse.pays.libelleAffiche ? adresse.pays.libelleAffiche : adresse.pays.libelle) : '');
	}

	static estRenseignee(adresse: AdresseModel): boolean {
		let nouvelle = new AdresseModel();

		return !(nouvelle.boitePostal == adresse.boitePostal
			&& nouvelle.complementNom == adresse.complementNom
			&& Utils.equals(nouvelle.codePostalCommune, adresse.codePostalCommune)
			&& nouvelle.nomVoie == adresse.nomVoie
			&& Utils.equals(nouvelle.pays, adresse.pays)
			&& nouvelle.numeroVoie == adresse.numeroVoie
			&& nouvelle.residenceBatiment == adresse.residenceBatiment
			&& Utils.equals(nouvelle.typeVoie, adresse.typeVoie)
			&& nouvelle.codePostal == adresse.codePostal
			&& nouvelle.ville == adresse.ville
		);
	}

	static isFrance(adresse: AdresseModel): boolean {
		return !!(adresse && adresse.pays && adresse.pays.id && adresse.pays.id === 70);
	}

	static isSaisieLibre(adresse: AdresseModel, saisieLibreDesactive: boolean): boolean {
		return ((this.isFrance(adresse) && adresse.codePostalCommune == null &&
			(adresse.codePostal != null || adresse.ville != null) && !saisieLibreDesactive) || !this.isFrance(adresse));
	}
}
