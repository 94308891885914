export enum AffectationModeEnum {

	/**
	 * Mode utilisateur.
	 */
	UTILISATEUR,

		/**
		 * Mode Pole/Agence.
		 */
	POLE,

		/**
		 * Mode Pole/Agence + utilisateur
		 */
	POLE_UTILISATEUR
}