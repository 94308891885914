/* External modules */

import { TranslateModule } from "@ngx-translate/core";

import { NgxPaginationModule } from "ngx-pagination";
import { FileUploadModule } from "ng2-file-upload";
import { PdfViewerModule } from "ng2-pdf-viewer";
/* Modules */
import { UiModule } from "./components/ui/ui.module";
import { ClipboardModule } from "ngx-clipboard";
import { InfiniteScrollModule } from "ngx-infinite-scroll";
import { TooltipModule } from "ngx-bootstrap/tooltip";
import { TypeaheadModule } from "ngx-bootstrap/typeahead";
import { CarouselModule } from "ngx-bootstrap/carousel";
import { BsDatepickerModule } from "ngx-bootstrap/datepicker";
import { ModalModule } from "ngx-bootstrap/modal";
import { TimepickerModule } from "ngx-bootstrap/timepicker";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { NgModule } from "@angular/core";
import { DragulaModule } from 'ng2-dragula';
import { ChartsModule } from 'ng2-charts';
import { CKEditorModule } from 'ng2-ckeditor';

@NgModule({
	imports:[
		/* External modules */
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		TranslateModule.forChild(),
		DragulaModule, // ng2-dragula,
		TypeaheadModule,
		FileUploadModule,
		PdfViewerModule,
		ClipboardModule, // ngx-clipboard
		InfiniteScrollModule, // ngx-infinite-scroll
		NgxPaginationModule, // ngx-paginate
		/* ngx-bootstrap */
		CarouselModule,
		BsDatepickerModule,
		ModalModule.forChild(),
		TimepickerModule,
		ChartsModule,
		TooltipModule,
		UiModule
	],
	exports: [
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		TranslateModule,
		TypeaheadModule,
		NgxPaginationModule,
		FileUploadModule,
		PdfViewerModule,
		ClipboardModule, // ngx-clipboard
		InfiniteScrollModule, // ngx-infinite-scroll
		NgxPaginationModule, // ngx-paginate
		CarouselModule,
		BsDatepickerModule,
		ModalModule,
		TimepickerModule,
		DragulaModule,
		CKEditorModule,
		ChartsModule,
		TooltipModule,
		UiModule
	]
})
export class SPSCommonChildModule {
}



