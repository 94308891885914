import { UtilisateurConnecte } from "./utilisateur/utilisateur.model";
import * as moment from "moment";

export class AuthentificationModel {
	username: string;
	token_type: string;
	access_token: string;
	organisation: string;
	expires_in: number;
	jti: string;
	startDate: moment.Moment;
	utilisateur: UtilisateurConnecte;
	refresh_token: string;
}
