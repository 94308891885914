/**
 * Composant de loader pour SPS.
 */
import { Component, Input, ViewEncapsulation } from "@angular/core";

@Component({
	selector: 'sps-loader',
	templateUrl: './loader.template.html',
	styleUrls: ['./loader.style.less'],
	encapsulation: ViewEncapsulation.None
})
export class LoaderComponent {
	@Input('message') message: string;

	constructor() {
	}
}
