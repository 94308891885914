import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";

@Component({
	template: ''
})
export class RouterForward implements OnInit {
	constructor(private router: Router, private route: ActivatedRoute) {
	}

	ngOnInit(): void {
		this.router.navigateByUrl(
			JSON.parse(decodeURIComponent(this.route.snapshot.params['data'])),
			{skipLocationChange: false});
	}
}
