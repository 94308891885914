/* External modules */
/* Modules */
/* Services */
/* Components */
import { RechercheComponent } from "./recherche.component";
import { RechercheContactComponent } from "./recherche.contact.component";
import { FusionCommunComponent } from "../fusion/fusion.commun.component";
import { SPSCommonChildModule } from "../../../../app.common.child.module";
import { NgModule } from "@angular/core";

@NgModule({
	imports: [
		SPSCommonChildModule
	],
	declarations: [
		RechercheComponent,
		RechercheContactComponent,
		FusionCommunComponent,
	],
	exports: [
		RechercheComponent, // Routed component
		RechercheContactComponent, // Widget component
	]
})
export class RechercheContactModule {
}
