import { Routes } from "@angular/router";
import { referentielsRoutes } from './components/referentiels/referentiels.routes';
import { errorsRoutes } from './components/errors/errors.routes';
import { cguRoutes } from './components/cgu/cgu.routes';
import { RedirectionLienExterieurComponent } from './components/redirection.lien.exterieur.component';
import { RouterForward } from './components/contacts/router.forward.component';
import { RouterChangePortefeuille } from './components/contacts/router.change.portefeuille.component';
/* Components */
/* Routes */

export const APP_ROUTES: Routes = [
	{
		path: '',
		redirectTo: 'contacts',
		pathMatch: 'full',
	},
	/* Lazy-load routes */
	{
		path: 'contacts',
		loadChildren: () => import('app/components/contacts/contacts.module').then(m => m.ContactsModule)
	},
	{
		path: 'evenements',
		loadChildren: () => import('app/components/evenements/evenements.module').then(m => m.EvenementsModule)
	},
	{
		path: 'utilisateurs',
		loadChildren: () => import('app/components/utilisateurs/utilisateurs.module').then(m => m.UtilisateursModule)
	},
	{
		path: 'saisies',
		loadChildren: () => import('app/components/saisies/saisies.module').then(m => m.SaisiesModule)
	},
	{
		path: 'campagnes',
		loadChildren: () => import('app/components/campagnes/campagnes.module').then(m => m.CampagnesModule)
	},
	{
		path: 'qualification',
		loadChildren: () => import('app/components/qualification/qualification.module').then(m => m.QualificationModule)
	},
	{
		path: 'centre-documentaire',
		loadChildren: () => import('app/components/centre-documentaire/centre.documentaire.module').then(m => m.CentreDocumentaireModule)
	},
	{
		path: 'administration',
		loadChildren: () => import('app/components/administration/administration.module').then(m => m.AdministrationModule)
	},

	...referentielsRoutes,
	...errorsRoutes,
	...cguRoutes,
	{path: 'redirect', component: RedirectionLienExterieurComponent, pathMatch: 'full'},
	{path: 'forward', component: RouterForward},
	{path: 'switch', component: RouterChangePortefeuille},
	{path: '**', redirectTo: ''}
];
