/**
 * Created by lremongin on 29/09/16.
 */
import { Pipe, PipeTransform } from "@angular/core";
import * as moment from "moment";

/**
 * String to date (affichage des heures).
 */
@Pipe({name: 'stringToDate'})
export class StringToDatePipe implements PipeTransform {
	transform(value: string): string {
		return moment(value, "DD/MM/YYYY HH:mm").format("DD/MM/YYYY à HH:mm");
	}
}

/**
 * String to date (sans affichage des heures)
 */
@Pipe({name: 'stringToDateWithoutTime'})
export class StringToDateWithoutTimePipe implements PipeTransform {
	transform(value: string): string {
		return moment(value, "DD/MM/YYYY HH:mm").format("DD/MM/YYYY");
	}
}
