import { Component, EventEmitter, Input, Output } from "@angular/core";
import { ContactsComponentsService } from "../../../../services/contacts/contacts.service";
import { ContactModel } from "../../../../models/commun/contacts/contact.model";

@Component({
	selector: 'bouton-liste-contacts',
	templateUrl: './bouton.liste.contacts.template.html',
	styleUrls: ['./bouton.liste.contacts.style.less']
})
export class BoutonListeContactsComponent  {
	@Output() onClick: EventEmitter<any> = new EventEmitter();
	@Input() state: boolean;
	contactsLength : number = 0;
	constructor(public contactsComponentService: ContactsComponentsService) {

	}

	ngOnInit() {
		this.contactsComponentService.getContactsOpenedSubject().subscribe((contactList: Array<ContactModel>)=>{
			this.contactsLength = contactList.length;
		});
	}
}
